import { FC, useMemo } from 'react';
import { Button, CmpProps, CmpReturn, Direction, Menu, MenuOption } from '@diamanticom/picasa-core';

export type SalesButtonProps = CmpProps;

export const SalesButton: FC<SalesButtonProps> = ({ className }) => {
  const options = useMemo<MenuOption[]>(
    () => [
      {
        key: 'phone',
        text: '+1 (408) 539-3283',
        href: 'tel:+1-408-539-3283',
      },
    ],
    []
  );

  return (
    <Menu options={options}>
      {({ getProps, isOpen }): CmpReturn => (
        <Button
          className={className}
          text="Contact Sales"
          type="alternate"
          rightIcon="chevron"
          direction={isOpen ? Direction.Up : Direction.Down}
          {...getProps()}
        />
      )}
    </Menu>
  );
};
