import { FC, useMemo } from 'react';
import { Button, CmpProps, CmpReturn, Direction, Menu, MenuOption } from '@diamanticom/picasa-core';

export type SupportButtonProps = CmpProps;

export const SupportButton: FC<SupportButtonProps> = ({ className }) => {
  const options = useMemo<MenuOption[]>(
    () => [
      {
        key: 'email',
        text: 'support@fd.diamanti.com',
        href: 'mailto:support@fd.diamanti.com',
      },
    ],
    []
  );

  return (
    <Menu options={options}>
      {({ getProps, isOpen }): CmpReturn => (
        <Button
          className={className}
          text="Contact Support"
          type="alternate"
          rightIcon="chevron"
          direction={isOpen ? Direction.Up : Direction.Down}
          {...getProps()}
        />
      )}
    </Menu>
  );
};
