export type UltimaStorageConfigurations = { label: string; value: string };

export const PLATFORM_TYPES: UltimaStorageConfigurations[] = [
  {
    label: 'AWS',
    value: 'AWS',
  },
];

export const PLATFORM_VERSIONS: UltimaStorageConfigurations[] = [
  {
    label: '1.0.2',
    value: '1.0.2',
  },
];

export const DISTRIBUTIONS: UltimaStorageConfigurations[] = [
  {
    label: 'Elastic Kubernetes Services',
    value: 'EKS',
  },
];

export const K8S_VERSIONS: UltimaStorageConfigurations[] = [
  {
    label: '1.30',
    value: '1.30',
  },
];

export const STORAGE_TYPES: UltimaStorageConfigurations[] = [
  {
    label: 'NVMe',
    value: 'NVMe',
  },
  {
    label: 'EBS',
    value: 'EBS',
  },
];
