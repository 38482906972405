export type MinimumVMRequirements = {
  resource: string;
  minimum: string;
};
export type MinimumBMRequirements = {
  resource: string;
  minimum: string;
};
export type MinimumEKSRequirements = {
  resource: string;
  minimum: string;
};
export type MinimumGKERequirements = {
  resource: string;
  minimum: string;
};
export type NodeLicenseDetails = {
  machineID: string;
  createdAt: string;
  trialExpirationDate: string;
  timeRemaining: string;
  action?: any;
};
export const DEFAULT_NODE_LICENSES: NodeLicenseDetails[] = [
  {
    machineID: '1234567890',
    createdAt: new Date().toString(),
    trialExpirationDate: `${new Date().setDate(new Date().getDate() + 30)}`,
    timeRemaining: '',
    action: () => undefined,
  },
  {
    machineID: '1234567890',
    createdAt: new Date().toString(),
    trialExpirationDate: `${new Date().setDate(new Date().getDate() + 30)}`,
    timeRemaining: '',
    action: () => undefined,
  },
];
// Memory32GB CPU16 vCPUsBoot Drive128 GBApplication Drive4x200 GB NVMeNetworking2 VNICs
export const MINIMUM_VM_VSPHERE: MinimumVMRequirements[] = [
  { resource: 'Memory', minimum: '32GB' },
  { resource: 'CPU', minimum: '16 vCPUs' },
  { resource: 'Boot Drive', minimum: '128 GB' },
  { resource: 'Application Drive', minimum: '4x200 GB NVMe' },
  { resource: 'Networking', minimum: '2 VNICs' },
];

export const CONFIG_CHECKLIST_VSPHERE: string[] = [
  '1 x IP address for the cluster (cluster-VIP)',
  '1 x IP address per VM for SSH',
  'Name for the cluster',
  'The DNS resolvable short-name (hostname) of nodes',
  'The DNS sub-domain for the cluster',
];

export const MINIMUM_BM_BAREMETAL: MinimumBMRequirements[] = [
  { resource: 'Memory', minimum: '64GB' },
  { resource: 'CPU', minimum: '16 cores' },
  { resource: 'Boot Drive', minimum: '480 GB' },
  { resource: 'Application Drive', minimum: '4 x NVMe SSDs (minimum 200GB per SSD)' },
  { resource: 'Networking', minimum: '1 NIC' },
];

export const CONFIG_CHECKLIST_BAREMETAL: string[] = [
  '1 x IP address for the cluster (cluster-VIP)',
  '1 x IP address per VM for SSH',
  'Name for the cluster',
  'The DNS resolvable short-name (hostname) of nodes',
  'The DNS sub-domain for the cluster',
];

export const MINIMUM_EKS_EKS: MinimumEKSRequirements[] = [
  { resource: 'MemoryEKS', minimum: '64GB' },
  { resource: 'CPU', minimum: '16 cores' },
  { resource: 'Boot Drive', minimum: '480 GB' },
  { resource: 'Application Drive', minimum: '4 x NVMe SSDs (minimum 200GB per SSD)' },
  { resource: 'Networking', minimum: '1 NIC' },
];

export const CONFIG_CHECKLIST_EKS: string[] = [
  'EKS Cluster should be running k8s 1.23 version',
  'Worker nodes must be atleast t3.large [2 vcpu and 8 GB Memory]',
  'Valid public SSL/TLS/HTTPS certs are required for OIDC on EKS.',
];

export const MINIMUM_GKE_GKE: MinimumGKERequirements[] = [
  { resource: 'MemoryGKE', minimum: '64GB' },
  { resource: 'CPU', minimum: '16 cores' },
  { resource: 'Boot Drive', minimum: '480 GB' },
  { resource: 'Application Drive', minimum: '4 x NVMe SSDs (minimum 200GB per SSD)' },
  { resource: 'Networking', minimum: '1 NIC' },
];

export const CONFIG_CHECKLIST_GKE: string[] = [
  'GKE cluster should be running k8s 1.23 version',
  'Worker nodes should be atleast e2-standard-4 [4 vcpu and 16 GB Memory]',
  'Identity service should be enabled.',
  'A CA cert for Spektra’s FQDN Issuer URL well be needed to configure GKE OIDC',
];
