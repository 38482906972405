import { FC, useCallback } from 'react';
import styled from 'styled-components';
import {
  Button,
  CardModal,
  CmpProps,
  CmpReturn,
  Form,
  Input,
  Label,
  SubmitButton,
  Textarea,
  Typography,
} from '@diamanticom/picasa-core';

import {
  useUninstallUltimaEnterpriseMutation,
  useUninstallSpektraMutation,
  ProductType,
  useUninstallGroundworkMutation,
  Provider,
  InstallationFragment,
  useDeleteUltimaEnterpriseInstallationMutation,
  useDeleteSpektraInstallationMutation,
} from '$gql';
import { useNavigate } from 'react-router-dom';
import { ROOT } from '$utils/paths';

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CenteredBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
type GCPCredentialForm = {
  serviceAccount: string;
};

type AWSCredentialForm = {
  accessKeyId: string;
  secretAccessKey: string;
};
const isGCPCredentialForm = (
  o: GCPCredentialForm | AWSCredentialForm | undefined
): o is GCPCredentialForm => {
  return !!(o as GCPCredentialForm).serviceAccount;
};
export type UnInstallationModalProps = CmpProps<{
  closeUninstallModal: () => void;
  installation: InstallationFragment;
  isRemove: boolean;
}>;
export const UnInstallationModal: FC<UnInstallationModalProps> = ({
  closeUninstallModal,
  installation,
  isRemove,
}) => {
  const [uninstallUltimaEnterprise] = useUninstallUltimaEnterpriseMutation();
  const [deleteUltimaEnterprise] = useDeleteUltimaEnterpriseInstallationMutation();
  const [deleteSpektra] = useDeleteSpektraInstallationMutation();
  const [uninstallSpektra] = useUninstallSpektraMutation();
  const [uninstallGroundwork] = useUninstallGroundworkMutation();
  const header =
    // eslint-disable-next-line no-nested-ternary
    installation.config.cloudType === Provider.Vsphere ||
    installation.config.cloudType === Provider.Baremetal ||
    installation.config.cloudType === Provider.Gke ||
    installation.config.cloudType === Provider.Eks
      ? 'Delete Download link'
      : isRemove
      ? 'Remove'
      : 'Uninstall';
  const headerMessage =
    // eslint-disable-next-line no-nested-ternary
    installation.config.cloudType === Provider.Vsphere ||
    installation.config.cloudType === Provider.Baremetal ||
    installation.config.cloudType === Provider.Gke ||
    installation.config.cloudType === Provider.Eks
      ? `Clicking 'Delete Download Link' will remove the access to download the installation ${installation.id}`
      : isRemove
      ? `Clicking remove will remove the installation`
      : `Clicking uninstall will permananently remove all resources created during the installation
  process.`;
  const navigate = useNavigate();
  const onSubmitCredentials = useCallback(
    async (form: GCPCredentialForm | AWSCredentialForm | undefined): Promise<void> => {
      if (installation.product === ProductType.Spektra) {
        if (
          installation.config.cloudType === Provider.Gke ||
          installation.config.cloudType === Provider.Eks
        ) {
          await deleteSpektra({
            variables: {
              id: installation.id,
            },
          });
          navigate(ROOT);
        } else {
          // eslint-disable-next-line no-nested-ternary
          let input = form ? (isGCPCredentialForm(form) ? form : { credentials: form }) : {};
          if (isRemove) {
            input = { credentials: { secretAccessKey: 'fakeAccount', accessKeyId: 'fakeAccount' } };
          }
          await uninstallSpektra({
            variables: {
              onlyRemoveFromDatabase: isRemove,
              id: installation.id,
              input,
            },
          });
          if (isRemove) {
            navigate(ROOT);
          }
        }
      }

      if (installation.product === ProductType.UltimaEnterprise) {
        if (
          installation.config.cloudType === Provider.Vsphere ||
          installation.config.cloudType === Provider.Baremetal
        ) {
          await deleteUltimaEnterprise({
            variables: {
              id: installation.id,
            },
          });
          navigate(ROOT);
        } else {
          // eslint-disable-next-line no-nested-ternary
          let input = form ? (isGCPCredentialForm(form) ? form : { credentials: form }) : {};
          if (isRemove) {
            input = { credentials: { secretAccessKey: 'fakeAccount', accessKeyId: 'fakeAccount' } };
          }
          await uninstallUltimaEnterprise({
            variables: {
              onlyRemoveFromDatabase: isRemove,
              id: installation.id,
              input,
            },
          });
          if (isRemove) {
            navigate(ROOT);
          }
        }
      }
      if (installation.product === ProductType.Groundwork) {
        // eslint-disable-next-line no-nested-ternary
        if (form && isGCPCredentialForm(form)) {
          await uninstallGroundwork({ variables: { id: installation.id, input: form } });
        } else {
          await uninstallGroundwork({
            variables: { id: installation.id, input: { credentials: form } },
          });
        }
      }
      closeUninstallModal();
    },
    [
      installation.product,
      installation.id,
      closeUninstallModal,
      uninstallSpektra,
      uninstallUltimaEnterprise,
      uninstallGroundwork,
    ]
  );
  return (
    <CardModal header={header} onClose={closeUninstallModal}>
      <Typography type="body">{headerMessage}</Typography>
      {installation.config.cloudType === Provider.Gcp && (
        <Form<GCPCredentialForm> onSubmit={onSubmitCredentials}>
          <FormBody>
            <Label text="GCP Service Account JSON" required>
              {(id: string): CmpReturn => (
                <Textarea
                  id={id}
                  name="serviceAccount"
                  placeholder="Enter service account JSON"
                  options={{
                    required: 'Service account is required',
                    validate: (val): string | boolean => {
                      try {
                        JSON.parse(val);
                        return true;
                      } catch (e) {
                        return `Must be valid JSON: ${(e as any)?.message}`;
                      }
                    },
                  }}
                />
              )}
            </Label>
            <Actions>
              <SubmitButton type="danger" text="Uninstall" />
            </Actions>
          </FormBody>
        </Form>
      )}
      {installation.config.cloudType === Provider.Aws && (
        <Form<AWSCredentialForm> onSubmit={onSubmitCredentials}>
          <FormBody>
            {!isRemove && (
              <Label text="AWS Access Key ID" required>
                {(id: string): CmpReturn => {
                  return (
                    <Input
                      id={id}
                      name="accessKeyId"
                      placeholder="Enter AWS Access Key ID"
                      options={{ required: 'Access Key ID is required' }}
                    />
                  );
                }}
              </Label>
            )}
            {!isRemove && (
              <Label text="AWS Secret Access Key" required>
                {(id: string): CmpReturn => {
                  return (
                    <Input
                      id={id}
                      name="secretAccessKey"
                      placeholder="Enter AWS Secret Access Key"
                      options={{ required: 'Secret Access Key is required' }}
                      type="password"
                    />
                  );
                }}
              </Label>
            )}
            <Actions>
              <SubmitButton type="danger" text={isRemove ? 'Remove' : 'Uninstall'} />
            </Actions>
          </FormBody>
        </Form>
      )}
      {(installation.config.cloudType === Provider.Vsphere ||
        installation.config.cloudType === Provider.Baremetal ||
        installation.config.cloudType === Provider.Gke ||
        installation.config.cloudType === Provider.Eks) && (
        <CenteredBody>
          <Button
            type="danger"
            text="Delete Download Link"
            onClick={(): Promise<void> => onSubmitCredentials(undefined)}
          />
        </CenteredBody>
      )}
    </CardModal>
  );
};
