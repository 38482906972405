import { FC } from 'react';
import { Button, CmpProps } from '@diamanticom/picasa-core';

import { ProductCard } from '$cmp/product-card/ProductCard';
import { generatePath, INSTALL } from '$utils/paths';
import { ProductType, useCanInstallUltimaEnterpriseQuery } from '$gql';
import { SalesButton } from '$cmp/contact/sales-button/SalesButton';
import { productImageMap, productNameMap, slugProductMap } from '$utils/installation';

export type UltimaEnterpriseCardProps = CmpProps;

export const UltimaEnterpriseCard: FC<UltimaEnterpriseCardProps> = ({ className }) => {
  const { data, loading } = useCanInstallUltimaEnterpriseQuery();
  const canInstallUltimaEnterprise = data?.canInstallUltimaEnterprise;

  return (
    <ProductCard
      className={className}
      title={productNameMap[ProductType.UltimaEnterprise]}
      image={productImageMap[ProductType.UltimaEnterprise]}
      trial="30 Day Free Trial"
      rightAction={
        <Button
          text={
            loading || canInstallUltimaEnterprise ? 'Start 30 Day Trial' : 'Trial limit exceeded'
          }
          to={generatePath(INSTALL, { product: slugProductMap[ProductType.UltimaEnterprise] })}
          disabled={!canInstallUltimaEnterprise}
        />
      }
      leftAction={<SalesButton />}
    >
      Diamanti’s turnkey Kubernetes platform integrated with Diamanti’s feature-rich, high
      performant, storage and network plugins to simplify deployment and management of clusters and
      applications in hybrid cloud environments.
    </ProductCard>
  );
};
