import React from 'react';
import { Button, Modal, Typography } from '@diamanticom/picasa-core';
import styled from 'styled-components';

const ScrollableModal = styled(Modal)<{ isStatic: boolean }>`
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  margin: auto;
  padding: 20px;
  font-family: Mukta, sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;

const ImportantText = styled.span`
  color: maroon;
  font-weight: bold;
`;

const CodeBlock = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
`;

const ClipboardIcon = styled.i`
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #000;
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const CommandWrapper = styled.div`
  margin-bottom: 20px;
`;

interface DownloadSpecModalProps {
  open: boolean;
  onClose: () => void;
}

export const DownloadSpecModal: React.FC<DownloadSpecModalProps> = ({ open, onClose }) => {
  const kubectlCommand = 'kubectl apply -f diamanti-ultima-storage-specs.yaml';

  const handleCopy = (): void => {
    navigator.clipboard.writeText(kubectlCommand).then((): void => {
      alert('Command copied to clipboard!');
    });
  };

  const handleDownloadSpec = (): void => {
    const directDownloadPath = '/files/dcx-overlay.yaml';
    const link = document.createElement('a');
    link.href = directDownloadPath;
    link.download = 'diamanti-ultima-storage-specs.yaml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ScrollableModal
      isStatic
      open={open}
      onClose={onClose}
      title={<Typography type="h3">Download Ultima Storage</Typography>}
      body={
        <ContentWrapper>
          <Typography type="body">
            <ImportantText>Important</ImportantText>
            <br />
            You can download the spec file using the button below. Ensure it is downloaded in the
            environment where it is to be used before proceeding.
            <br />
          </Typography>
          <CommandWrapper>
            <Typography type="body" style={{ marginTop: '20px' }}>
              <br />
              Once downloaded, you can apply the spec file using the following command (example
              usage):
            </Typography>
            <CodeBlock>
              <span>{kubectlCommand}</span>
              <ClipboardIcon onClick={handleCopy} className="clipboard-icon">
                📋
              </ClipboardIcon>
            </CodeBlock>
          </CommandWrapper>
          <div style={{ textAlign: 'center' }}>
            <Button onClick={handleDownloadSpec} text="Download Spec File" />
          </div>
        </ContentWrapper>
      }
    />
  );
};
