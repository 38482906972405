import { FC } from 'react';
import styled from 'styled-components';
import { Card, CmpProps, ImageIcon, Typography } from '@diamanticom/picasa-core';

import { InstallationFragment } from '$gql';
import { providerImageMap, providerNameMap } from '$utils/installation';

const Body = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
`;

const Logo = styled(ImageIcon)`
  height: 96px;
  width: 96px;
`;

export type InstallationPlatformCardProps = CmpProps<{
  installation: InstallationFragment;
}>;

export const InstallationPlatformCard: FC<InstallationPlatformCardProps> = ({
  className,
  installation,
}) => {
  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header title="Deployed on" />
      <Card.Body>
        <Body>
          <Logo image={providerImageMap[installation.config.cloudType]} />
          <Typography type="h3">{providerNameMap[installation.config.cloudType]}</Typography>
        </Body>
      </Card.Body>
    </Card>
  );
};
