import { FC, useState } from 'react';
import styled from 'styled-components';
import {
  CmpReturn,
  Input,
  Label,
  RadioButtonGroup,
  Textarea,
  Typography,
} from '@diamanticom/picasa-core';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { FormGroup } from '$cmp/install-modal/common';
import { InstallType } from '$utils/enums';

const HelpTextLink = styled.a`
  color: ${(props): string => props.theme.palette.link};
  margin-left: 2px;
`;

const CenteredDescription = styled(Typography)`
  height: 348px;
  padding-top: 16px;
  text-align: center;
`;

export type GcpCredentialForm = {
  serviceAccount: string;
  projectId: string;
};

export const gcpCredentialFormDefault: GcpCredentialForm = {
  serviceAccount: '',
  projectId: '',
};

export type GcpCredentialStepProps = Omit<
  StepProps<GcpCredentialForm>,
  'title' | 'children' | 'submitDisabled'
>;

export const GcpCredentialStep: FC<GcpCredentialStepProps> = (props) => {
  const [installationType, setInstallationType] = useState<string>(InstallType.Automated);
  return (
    <Step {...props} title="Install Type" submitDisabled={installationType === InstallType.Manual}>
      <FormGroup>
        <RadioButtonGroup
          defaultValue={installationType}
          id="installType"
          options={[InstallType.Automated, InstallType.Manual]}
          onChange={setInstallationType}
        />
        {installationType === InstallType.Automated && (
          <>
            <Label
              text="GCP Project ID"
              required
              helperText={
                <>
                  This is your unique project ID from GCP. For more help see:
                  <HelpTextLink
                    target="_blank"
                    href="https://support.google.com/googleapi/answer/7014113?hl=en"
                  >
                    Identifying Projects
                  </HelpTextLink>
                </>
              }
            >
              {(id: string): CmpReturn => (
                <Input
                  id={id}
                  name="projectId"
                  placeholder="Enter GCP project id"
                  options={{ required: 'Project ID is required' }}
                />
              )}
            </Label>
            <Label
              text="GCP Service Account JSON"
              required
              helperText={
                <>
                  For information see:
                  <HelpTextLink
                    target="_blank"
                    href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys"
                  >
                    Get a Service Account key
                  </HelpTextLink>
                </>
              }
            >
              {(id: string): CmpReturn => (
                <Textarea
                  id={id}
                  name="serviceAccount"
                  placeholder="Enter service account JSON"
                  options={{
                    required: 'Service account is required',
                    validate: (val): string | boolean => {
                      try {
                        JSON.parse(val);
                        return true;
                      } catch (e) {
                        return `Must be valid JSON: ${(e as Error)?.message}`;
                      }
                    },
                  }}
                />
              )}
            </Label>
          </>
        )}
        {installationType === InstallType.Manual && (
          <CenteredDescription type="body" as="p">
            In the future we will provide a manual install utility for installing locally. We will
            send out an email when it&apos;s available.
          </CenteredDescription>
        )}
      </FormGroup>
    </Step>
  );
};
