import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  CmpProps,
  Icon,
  Pill,
  Progress,
  Table,
  Typography,
} from '@diamanticom/picasa-core';
import {
  InstallationOperation,
  InstallationStatus,
  InstallationFragment,
  ProductType,
  Provider,
} from '$gql';
import { getProgressMessage, getStatusText, getStatusType } from '$utils/installation';
import { useFlagState } from '$utils/hooks';
import { UnInstallationModal } from '../uninstallation-modal/UninstallationModal';
import { createColumnHelper } from '@tanstack/react-table';
import { NodeLicenseDetails } from '$utils/lists/installationRequirements';
import { downloadObjectAsText, getTrialCounts } from '$utils/functions';
import { AddLicenseModal } from '../add-license-modal/AddLicenseModal';

const Body = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
`;

const StyledProgress = styled(Progress)`
  width: 100%;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  button {
    width: 100% !important;
  }
`;
const productNameMap = {
  [ProductType.UltimaEnterprise]: 'Periscope',
  [ProductType.Spektra]: 'Spektra',
  [ProductType.Groundwork]: 'Groundwork',
};

export type InstallationStatusCardProps = CmpProps<{
  installation: InstallationFragment;
}>;
const columnHelper = createColumnHelper<NodeLicenseDetails>();
const columns = [
  columnHelper.accessor('machineID', {
    header: () => <Typography type="pBold">Machine ID</Typography>,
    cell: (info) => (
      <Typography type="p" style={{ wordBreak: 'break-all' }}>
        {info.getValue()}
      </Typography>
    ),
    size: 80,
  }),
  columnHelper.accessor('timeRemaining', {
    header: () => <Typography type="pBold">Time Remaining</Typography>,
    cell: (info) => (
      <Typography type="p">{`${
        getTrialCounts(info.row.original.createdAt, info.row.original.trialExpirationDate).remaining
      } days`}</Typography>
    ),
    size: 80,
  }),
  columnHelper.accessor('action', {
    header: () => <Typography type="pBold">License</Typography>,
    cell: (info) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div onClick={info.row.original.action} style={{ cursor: 'pointer' }}>
        <Pill text="Download" type="alternate" size="md" />
      </div>
    ),
    size: 80,
  }),
];
export const InstallationStatusCard: FC<InstallationStatusCardProps> = ({
  className,
  installation,
}) => {
  const [licenseDetails, setLicenseDetails] = useState<NodeLicenseDetails[]>([]);
  const [installationList, setInstallationList] = useState<InstallationFragment[]>([]);
  const [isUninstallModalOpen, openUninstallModal, closeUninstallModal] = useFlagState();
  const [isAddLicenseModalOpen, openAddLicenseModal, closeAddLicenseModal] = useFlagState();
  const [isRemove, setIsRemove] = useState(false);
  const isUninstall = installation.operation === InstallationOperation.Uninstall;
  const isInProgress =
    installation.status === InstallationStatus.InProgress ||
    installation.status === InstallationStatus.FailedInProgress;

  const isUninstalling = isUninstall && isInProgress;

  useEffect(() => {
    setInstallationList((i) => i.concat(installation));
    if (installation.result?.nodes && installation.result?.nodes?.length > 0) {
      const temp: NodeLicenseDetails[] = [];
      installation.result?.nodes?.forEach((item) => {
        temp.push({
          createdAt: installation.createdAt,
          trialExpirationDate: `${item.licenseExpirationDate}`,
          machineID: `${item.machineID}`,
          action: () => removeLicense(item),
          timeRemaining: '',
        });
      });
      setLicenseDetails([...temp]);
    }
    if (getProgressMessage(installation).includes('already exists')) {
      setIsRemove(true);
    } else {
      setIsRemove(false);
    }
  }, [installation]);
  const addLicense = (): void => {
    openAddLicenseModal();
  };
  const removeLicense = (info: any): void => {
    downloadObjectAsText(info.licenseFile.content, info.licenseFile.filename);
  };
  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header title="App Status">
        <Pill text={getStatusText(installation)} type={getStatusType(installation)} size="md" />
      </Card.Header>
      <Card.Body>
        {installation.status === InstallationStatus.InProgress && (
          <Body>
            <Typography type="h4">{getProgressMessage(installation)}</Typography>
            <StyledProgress
              type="secondary"
              label={`${installation.operation} Progress`}
              progress={installation.progress}
            />
            <div style={{ height: '100%', overflowY: 'auto', width: '100%' }}>
              {installationList.map(
                (item, key) =>
                  getProgressMessage(item) !== '' && (
                    <FlexBox key={key}>
                      <Icon icon="check" />
                      <Typography>{getProgressMessage(item)}</Typography>
                    </FlexBox>
                  )
              )}
            </div>
          </Body>
        )}
        {installation.status === InstallationStatus.FailedInProgress && (
          <Body>
            <Typography type="h4">{getProgressMessage(installation)}</Typography>
            <StyledProgress
              type="secondary"
              label={`${installation.operation} Failure Cleanup'`}
              progress={installation.progress}
            />
            <ButtonContainer>
              <Button
                type="danger"
                text={
                  // eslint-disable-next-line no-nested-ternary
                  isUninstalling
                    ? isRemove
                      ? 'Removing'
                      : 'Uninstalling'
                    : isRemove
                    ? 'Remove'
                    : 'Uninstall'
                }
                onClick={openUninstallModal}
                disabled={isUninstalling}
              />
            </ButtonContainer>
          </Body>
        )}

        {installation.status === InstallationStatus.Done &&
          installation.operation === InstallationOperation.Install && (
            <Body>
              {installation.result?.publicAppIp && (
                <Typography type="h4">{`${
                  installation.product === ProductType.Groundwork ? 'VIP' : 'Cluster VIP'
                } ${
                  installation.product === ProductType.Groundwork &&
                  installation.config.cloudType === Provider.Aws
                    ? `${installation.result.nodes[0].externalIP}`
                    : `${installation.result.publicAppIp}`
                }`}</Typography>
              )}
              {installation.result?.publicAppIp && (
                <Button
                  text={`Launch ${productNameMap[installation.product]}`}
                  href={
                    // eslint-disable-next-line no-nested-ternary
                    installation.product === ProductType.Spektra
                      ? `https://${installation.result.publicAppIp}:5443`
                      : installation.product === ProductType.Groundwork &&
                        installation.config.cloudType === Provider.Aws
                      ? `https://${installation.result.nodes[0].externalIP}`
                      : `https://${installation.result.publicAppIp}`
                  }
                  rightIcon="link"
                  size="lg"
                  target="_blank"
                  rel="noreferrer"
                />
              )}
              {(installation.config.cloudType === Provider.Vsphere ||
                installation.config.cloudType === Provider.Baremetal) &&
                licenseDetails.length > 0 && <Table columns={columns} data={licenseDetails} />}
              {(installation.config.cloudType === Provider.Vsphere ||
                installation.config.cloudType === Provider.Baremetal) && (
                <Button
                  style={{ width: '100%', marginTop: 'auto' }}
                  text="Add License"
                  disabled={installation.result?.nodes && installation.result?.nodes.length >= 5}
                  onClick={addLicense}
                />
              )}
            </Body>
          )}

        {installation.status === InstallationStatus.Done &&
          installation.operation === InstallationOperation.Uninstall && (
            <Body>
              <Typography type="h4">Your app has been uninstalled</Typography>
            </Body>
          )}

        {installation.status === InstallationStatus.Failed && (
          <Body>
            <Typography type="h4">
              {(installation.operation === InstallationOperation.Install
                ? installation.installFailureMessage
                : installation.uninstallFailureMessage) || ''}
            </Typography>
          </Body>
        )}
      </Card.Body>
      {isUninstallModalOpen && (
        <UnInstallationModal
          closeUninstallModal={closeUninstallModal}
          installation={installation}
          isRemove={isRemove}
        />
      )}
      {isAddLicenseModalOpen && (
        <AddLicenseModal
          provider={installation.config.cloudType}
          closeAddLicenseModal={closeAddLicenseModal}
          installation={installation}
        />
      )}
    </Card>
  );
};
