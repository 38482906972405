import { FC, useCallback } from 'react';
import styled from 'styled-components';
import {
  Card,
  CmpProps,
  CmpReturn,
  Form,
  ImageIcon,
  Input,
  Label,
  Select,
  SubmitButton,
  Typography,
  Checkbox,
} from '@diamanticom/picasa-core';

import { useUpdateProfile } from '$utils/user';
import { COUNTRIES, CountryOption } from '$utils/lists/countries';
import { SalesVerticalOption, SALES_VERTICALS } from '$utils/lists/salesVerticals';
import { SalesPersonaOption, SALES_PERSONAS } from '$utils/lists/salesPeronas';

import { UserProfileInput } from '$gql';

const Container = styled.div`
  padding: 64px;

  display: flex;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 880px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled.div`
  width: 100%;
  max-width: 500px;

  padding: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 48px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
`;

const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Title = styled(Typography)`
  text-align: center;
`;

const StyledSubmitButton = styled(SubmitButton)`
  align-self: flex-end;
`;

const HelpTextLink = styled.a`
  color: ${(props): string => props.theme.palette.link};
  margin-left: 2px;
`;

const TOS = styled(Label)`
  display: flex;
  flex-direction: row-reverse;
`;

const PaddedCheckBox = styled(Checkbox)`
  padding-right: 10px;
`;

type RegisterForm = {
  firstName: string;
  lastName: string;
  jobTitle: SalesPersonaOption;
  phone: string;
  company: string;
  industry: SalesVerticalOption;
  country: CountryOption;
  state: string;
  city: string;
  postalCode: string;
};

export type RegisterPageProps = CmpProps;

export const RegisterPage: FC<RegisterPageProps> = ({ className }) => {
  const updateProfile = useUpdateProfile();

  const onSubmit = useCallback(
    async (form: RegisterForm) => {
      const input: UserProfileInput = {
        ...form,
        jobTitle: form.jobTitle.value,
        industry: form.industry.value,
        country: form.country.value,
      };

      return updateProfile(input);
    },
    [updateProfile]
  );

  const onSubmitError = useCallback((e: unknown) => {
    // eslint-disable-next-line no-console
    console.log('onSubmitError', e);
  }, []);

  return (
    <Container className={className}>
      <StyledCard>
        <Body>
          <Header>
            <ImageIcon image="DiamantiLogoFull" />
            <HeaderBody>
              <Title type="h3" as="h1">
                Create your Diamanti Profile
              </Title>
            </HeaderBody>
          </Header>
          <Form<RegisterForm> onSubmit={onSubmit} onSubmitError={onSubmitError}>
            <FormBody>
              <FormGroup>
                <Typography type="h4" as="h2">
                  Personal Information
                </Typography>
                <Label text="First Name" required>
                  {(id): CmpReturn => (
                    <Input
                      id={id}
                      name="firstName"
                      placeholder="Enter your first name"
                      options={{ required: 'First name is required' }}
                      autoFocus
                    />
                  )}
                </Label>
                <Label text="Last Name" required>
                  {(id): CmpReturn => (
                    <Input
                      id={id}
                      name="lastName"
                      placeholder="Enter your last name"
                      options={{ required: 'Last name is required' }}
                    />
                  )}
                </Label>
                <Label text="Job Title" required>
                  {(id): CmpReturn => (
                    <Select
                      id={id}
                      name="jobTitle"
                      placeholder="Select job title"
                      rules={{ required: 'Job title is required' }}
                      options={SALES_PERSONAS}
                    />
                  )}
                </Label>
                <Label text="Phone Number" required>
                  {(id): CmpReturn => (
                    <Input
                      id={id}
                      name="phone"
                      placeholder="Enter your phone number"
                      options={{ required: 'Phone number is required' }}
                    />
                  )}
                </Label>
              </FormGroup>
              <FormGroup>
                <Typography type="h4" as="h2">
                  Company Information
                </Typography>
                <Label text="Company Name" required>
                  {(id): CmpReturn => (
                    <Input
                      id={id}
                      name="company"
                      placeholder="Enter your company's name"
                      options={{ required: 'Company name is required' }}
                    />
                  )}
                </Label>
                <Label text="Industry" required>
                  {(id): CmpReturn => (
                    <Select
                      id={id}
                      name="industry"
                      placeholder="Select Industry"
                      rules={{ required: 'Industry is required' }}
                      options={SALES_VERTICALS}
                    />
                  )}
                </Label>
                <Label text="Country / Region" required>
                  {(id): CmpReturn => (
                    <Select
                      id={id}
                      name="country"
                      placeholder="Select country / region"
                      rules={{ required: 'Country / region is required' }}
                      options={COUNTRIES}
                    />
                  )}
                </Label>
                <Label text="State / Province" required>
                  {(id): CmpReturn => (
                    <Input
                      id={id}
                      name="state"
                      placeholder="Enter state / province"
                      options={{ required: 'State / province is required' }}
                    />
                  )}
                </Label>
                <Label text="City" required>
                  {(id): CmpReturn => (
                    <Input
                      id={id}
                      name="city"
                      placeholder="Enter city"
                      options={{ required: 'City is required' }}
                    />
                  )}
                </Label>
                <Label text="Postal Code" required>
                  {(id): CmpReturn => (
                    <Input
                      id={id}
                      name="postalCode"
                      placeholder="Enter postal code"
                      options={{ required: 'Postal code is required' }}
                    />
                  )}
                </Label>
              </FormGroup>
              <TOS
                text="Terms of Service"
                helperText={
                  <span>
                    By continuing you confirm you agree to the processing of information as
                    described in our{' '}
                    <HelpTextLink
                      target="_blank"
                      href="https://diamanti.com/privacy-policy-terms-of-use/"
                    >
                      privacy policy
                    </HelpTextLink>
                    . If you prefer not to receive emails from us you can opt-out by emailing{' '}
                    <HelpTextLink href="mailto:privacy@diamanti.com" target="_blank">
                      privacy@diamanti.com
                    </HelpTextLink>{' '}
                    or clicking the unsubscribe link in our marketing emails.
                  </span>
                }
                required
              >
                {(id): CmpReturn => (
                  <PaddedCheckBox
                    id={id}
                    name="termsAndConditions"
                    rules={{
                      required: {
                        value: true,
                        message: 'Please accept terms of service to continue.',
                      },
                    }}
                  />
                )}
              </TOS>
              <StyledSubmitButton text="Finish Sign Up" waitingText="Saving..." />
            </FormBody>
          </Form>
        </Body>
      </StyledCard>
    </Container>
  );
};
