import { FC } from 'react';
import { CmpReturn, SideNav } from '@diamanticom/picasa-core';

import { AuthUser } from '$cmp/auth-user/AuthUser';

import { ADMIN_DASHBOARD, PRODUCTS } from '$utils/paths';
import { useIsAdminUserQuery } from '$gql';

export const AppSideNav: FC = () => {
  const isAdminUser = useIsAdminUserQuery({ fetchPolicy: 'network-only' });
  return (
    <SideNav
      bottom={
        <SideNav.Item>{(isExpanded): CmpReturn => <AuthUser showName={isExpanded} />}</SideNav.Item>
      }
    >
      <SideNav.Link icon="products" text="Products" to={PRODUCTS} />
      {isAdminUser.data?.isAdminUser && (
        <SideNav.Link icon="dashboard" text="admin" to={ADMIN_DASHBOARD} />
      )}
    </SideNav>
  );
};
