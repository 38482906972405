import { FC } from 'react';
import { Button, CmpProps } from '@diamanticom/picasa-core';

import { ProductCard } from '$cmp/product-card/ProductCard';
import { generatePath, INSTALL } from '$utils/paths';
import { ProductType, useCanInstallGroundworkQuery } from '$gql';
import { SalesButton } from '$cmp/contact/sales-button/SalesButton';
import { productImageMap, productNameMap, slugProductMap } from '$utils/installation';

export type GroundworkCardProps = CmpProps;

export const GroundworkCard: FC<GroundworkCardProps> = ({ className }) => {
  const { data, loading } = useCanInstallGroundworkQuery();
  const canInstallGroundwork = data?.canInstallGroundwork;

  return (
    <ProductCard
      className={className}
      title={productNameMap[ProductType.Groundwork]}
      image={productImageMap[ProductType.Groundwork]}
      trial="30 Day Free Trial"
      rightAction={
        <Button
          text={loading || canInstallGroundwork ? 'Start 30 Day Trial' : 'Trial limit exceeded'}
          to={generatePath(INSTALL, { product: slugProductMap[ProductType.Groundwork] })}
          disabled={!canInstallGroundwork}
        />
      }
      leftAction={<SalesButton />}
    >
      A monitoring platform that integrates availability, performance and event data together with
      one click access to related systems, providing unique multi-context views of infrastructure
      availability and performance. GroundWork Monitor handles the real and the virtual, covering
      servers, networks, and storage resources.
    </ProductCard>
  );
};
