import { FC } from 'react';
import { CmpPropsWithChildren } from '@diamanticom/picasa-core';
import { useAuth0 } from '@auth0/auth0-react';

import { RegisterPage } from '$pages/register/RegisterPage';
import { getCompletedRegistration } from '$utils/AuthProvider';

export const RegisterGuard: FC<CmpPropsWithChildren> = ({ children }) => {
  const { user } = useAuth0();

  const completedRegistration = getCompletedRegistration(user);

  if (!completedRegistration) return <RegisterPage />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
