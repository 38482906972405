import { FC } from 'react';
import styled from 'styled-components';
import { CmpProps, Typography, Table, Pill, Progress } from '@diamanticom/picasa-core';
import { createColumnHelper } from '@tanstack/react-table';

import { InstallationStatus, InstallationFragment, ProductType } from '$gql';
import { getStatusText, getStatusType, productNameMap } from '$utils/installation';
import { getTrialCounts } from '$utils/functions';
import { Link } from 'react-router-dom';
import { INSTALLATION, generatePath } from '$utils/paths';
import { format } from 'date-fns';

const LinkText = styled(Typography)`
  color: ${(props): string => props.theme.palette.link};
`;
const Group = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const StyledProgress = styled(Progress)`
  height: 16px;
  flex: 1;
  max-width: 150px;
`;

const columnHelper = createColumnHelper<InstallationFragment>();

const columns = [
  columnHelper.accessor('product', {
    header: () => <Typography type="pBold">Product</Typography>,
    cell: (info) => {
      return (
        <Link to={generatePath(INSTALLATION, { id: info.row.original.id })}>
          <LinkText type="p">{productNameMap[info.getValue()]}</LinkText>
        </Link>
      );
    },
    size: 200,
    minSize: 200,
    maxSize: 200,
  }),
  columnHelper.display({
    id: 'platform',
    header: () => <Typography type="pBold">Platform</Typography>,
    cell: (info) => {
      return <Typography type="p">{info.row.original.config.cloudType}</Typography>;
    },
    size: 200,
    minSize: 200,
    maxSize: 200,
  }),
  columnHelper.display({
    id: 'createdAt',
    header: () => <Typography type="pBold">Created At</Typography>,
    cell: (info) => {
      return (
        <Typography type="p">
          {format(new Date(info.row.original.createdAt), 'dd LLL yyyy')}
        </Typography>
      );
    },
    size: 200,
    minSize: 200,
    maxSize: 200,
  }),
  columnHelper.display({
    id: 'status',
    header: () => <Typography type="pBold">Status</Typography>,
    cell: (info) => (
      <Group>
        <Pill
          text={getStatusText(info.row.original)}
          type={getStatusType(info.row.original)}
          size="sm"
        />
        {(info.row.original.status === InstallationStatus.InProgress ||
          info.row.original.status === InstallationStatus.FailedInProgress) && (
          <StyledProgress
            type="secondary"
            label={`${info.row.original.operation} Progress`}
            progress={info.row.original.progress}
          />
        )}
      </Group>
    ),
    size: 250,
    minSize: 250,
    maxSize: 250,
  }),
  columnHelper.display({
    id: 'trial',
    header: () => <Typography type="pBold">License Type</Typography>,
    cell: (info) => (
      <Typography type="p">
        {info.row.original.product === ProductType.Spektra ? 'Perpetual' : 'Trial'}
      </Typography>
    ),
    size: 180,
    minSize: 180,
    maxSize: 180,
  }),
  columnHelper.display({
    id: 'trial-remaining',
    header: () => <Typography type="pBold">Time Remaining</Typography>,
    cell: (info) => (
      <Typography type="p">
        {info.row.original.product === ProductType.Spektra
          ? '∞'
          : `${
              getTrialCounts(info.row.original.createdAt, info.row.original.trialExpirationDate)
                .remaining
            } days`}
      </Typography>
    ),
    size: 180,
    minSize: 180,
    maxSize: 180,
  }),
  columnHelper.accessor('id', {
    header: () => <Typography type="pBold">Installation ID</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 100,
  }),
];

export type InstallationTableProps = CmpProps<{ data: InstallationFragment[] }>;

export const BareInstallationTable: FC<InstallationTableProps> = ({ className, data }) => {
  return <Table className={className} columns={columns} data={data} />;
};
