import { FC, useState } from 'react';
import { ProductType, Provider } from '$gql';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { Platform, PlatformSelect } from '$cmp/platform-select/PlatformSelect';

export type PlatformForm = {
  cloudType: Provider;
};

export const platformFormDefault: PlatformForm = {
  cloudType: Provider.Gcp,
};

export type PlatformStepProps = Omit<StepProps<PlatformForm>, 'title' | 'children'> & {
  platforms: Array<Platform>;
  product: string;
  onTempChange: (provider: Provider) => void;
};

export const PlatformStep: FC<PlatformStepProps> = ({
  platforms,
  product,
  onTempChange,
  ...props
}) => {
  const [refrence, setRefrence] = useState<boolean>(false);
  const refrenceHandle = (data: string): void => {
    if (data === Provider.Vsphere || data === Provider.Baremetal) {
      if (product === ProductType.UltimaEnterprise) {
        setRefrence(false);
      } else {
        setRefrence(true);
      }
    } else {
      setRefrence(false);
    }
    if (data === Provider.Eks || data === Provider.Gke) {
      if (product === ProductType.Spektra) {
        setRefrence(false);
      } else {
        setRefrence(true);
      }
    } else {
      setRefrence(false);
    }
    onTempChange(data as Provider);
  };
  return (
    <Step {...props} title="Platform" submitDisabled={refrence}>
      <PlatformSelect
        name="cloudType"
        platforms={platforms}
        onPlatformChange={(data: string): void => refrenceHandle(data)}
        product={product}
      />
    </Step>
  );
};
