import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { CmpProps, ImageIcon, Typography, Wizard, Modal, StepType } from '@diamanticom/picasa-core';
import styled from 'styled-components';
import {
  InstallGroundworkInput,
  InstallSpektraInput,
  InstallUltimaEnterpriseInput,
  ProductType,
  Provider,
  useInstallGroundworkMutation,
  useInstallSpektraMutation,
  useInstallUltimaEnterpriseMutation,
  useS3DownloadUrlLazyQuery,
} from '$gql';
import { ROOT, INSTALLATION } from '$utils/paths';
import {
  platformFormDefault,
  PlatformForm,
  PlatformStep,
} from '$cmp/install-modal/platform-step/PlatformStep';
import {
  GcpCredentialForm,
  gcpCredentialFormDefault,
  GcpCredentialStep,
} from '$cmp/install-modal/gcp-credential-step/GcpCredentialStep';
import {
  GcpUltimaEnterpriseConfigForm,
  gcpUltimaEnterpriseConfigFormDefault,
  GcpUltimaEnterpriseConfigStep,
} from '$cmp/install-modal/gcp-ultima-enterprise-config-step/GcpUltimaEnterpriseConfigStep';
import { ProviderName } from '$utils/enums';
import { productImageMap, productNameMap, productVersionMap } from '$utils/installation';
import {
  GcpSpektraConfigForm,
  gcpSpektraConfigFormDefault,
  GcpSpektraConfigStep,
} from '$cmp/install-modal/gcp-spektra-config-step/GcpSpektraConfigStep';
import {
  GcpGroundworkConfigForm,
  gcpGroundworkConfigFormDefault,
  GcpGroundworkConfigStep,
} from '$cmp/install-modal/gcp-groundwork-config-step/GcpGroundworkConfigStep';
import { Platform } from '$cmp/platform-select/PlatformSelect';
import {
  AwsCredentialForm,
  awsCredentialFormDefault,
  AwsCredentialStep,
} from '$cmp/install-modal/aws-credential-step/AwsCredentialStep';
import {
  AwsUltimaEnterpriseConfigForm,
  awsUltimaEnterpriseConfigFormDefault,
  AwsUltimaEnterpriseConfigStep,
} from '$cmp/install-modal/aws-ultima-enterprise-config-step/AwsUltimaEnterpriseConfigStep';
import {
  AwsSpektraConfigForm,
  awsSpektraConfigFormDefault,
  AwsSpektraConfigStep,
} from '$cmp/install-modal/aws-spektra-config-step/AwsSpektraConfigStep';
import {
  VsphereUltimaEnterpriseConfigForm,
  vsphereUltimaEnterpriseConfigFormDefault,
  VsphereUltimaEnterpriseConfigStep,
} from './vsphere-ultima-enterprise-config-step/VsphereUltimaEnterpriseConfigStep';
import {
  BaremetalUltimaEnterpriseConfigForm,
  baremetalUltimaEnterpriseConfigFormDefault,
  BaremetalUltimaEnterpriseConfigStep,
} from './baremetal-ultima-enterprise-config-step/BaremetalUltimaEnterpriseConfigStep';
import {
  EksSpektraConfigForm,
  eksSpektraConfigFormDefault,
  EksSpektraConfigStep,
} from './eks-spektra-config-step/EksSpektraConfigStep';
import {
  GkeSpektraConfigForm,
  gkeSpektraConfigFormDefault,
  GkeSpektraConfigStep,
} from './gke-spektra-config-step/GkeSpektraConfigStep';
import {
  AwsGroundworkConfigForm,
  AwsGroundworkConfigStep,
  awsGroundworkConfigFormDefault,
} from './aws-groundwork-config-step/AwsGroundworkConfigStep';

const ScrollableModal = styled(Modal)<{ isStatic: boolean }>`
  overflow: auto;
`;

const AsideHeader = styled.span`
  display: flex;
  column-gap: 16px;
  box-shadow: 0px 4px 4px rgba(194, 236, 251, 0.05), 0px 0px 20px rgba(112, 144, 155, 0.15);
  border: ${(props): string => `1px solid ${props.theme.palette.gray.gray1}`};
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 32px;
  width: 300px;
`;

const StepTitle = styled(Typography)`
  display: flex;
  align-items: center;
`;

const Main = styled.main`
  display: flex;
`;

const ModalWizard = styled(Wizard)`
  height: 100vh;
`;

export type InstallModalProps = CmpProps<{ product: ProductType }>;

export const InstallModal: FC<InstallModalProps> = ({ product }) => {
  const [activeStep, setActiveStep] = useState(1);
  const highestStep = useRef(1);
  const [platform, setPlatform] = useState<PlatformForm>(platformFormDefault);
  const [gcpCredential, setGcpCredential] = useState<GcpCredentialForm>(gcpCredentialFormDefault);
  const [awsCredential, setAwsCredential] = useState<AwsCredentialForm>(awsCredentialFormDefault);
  const [gcpUltimaEnterpriseConfig, setGcpUltimaEnterpriseConfig] =
    useState<GcpUltimaEnterpriseConfigForm>(gcpUltimaEnterpriseConfigFormDefault);
  const [awsUltimaEnterpriseConfig, setAwsUltimaEnterpriseConfig] =
    useState<AwsUltimaEnterpriseConfigForm>(awsUltimaEnterpriseConfigFormDefault);
  const [gcpSpektraConfig, setGcpSpektraConfig] = useState<GcpSpektraConfigForm>(
    gcpSpektraConfigFormDefault
  );
  const [awsSpektraConfig, setAwsSpektraConfig] = useState<AwsSpektraConfigForm>(
    awsSpektraConfigFormDefault
  );
  const [gcpGroundworkConfig, setGcpGroundworkConfig] = useState<GcpGroundworkConfigForm>(
    gcpGroundworkConfigFormDefault
  );
  const [awsGroundworkConfig, setAwsGroundworkConfig] = useState<AwsGroundworkConfigForm>(
    awsGroundworkConfigFormDefault
  );
  const [wizardSteps, setWizardSteps] = useState<StepType>([
    {
      label: 'Deployment Environment',
      description: ProviderName[Provider.Gcp],
    },
    {
      label: 'Install Type',
      description: '',
    },
    { label: 'Configuration', description: '' },
  ]);
  const [execQuery] = useS3DownloadUrlLazyQuery();

  const navigate = useNavigate();
  const [installUltimaEnterpriseMutation] = useInstallUltimaEnterpriseMutation();
  const [installSpektraMutation] = useInstallSpektraMutation();
  const [installGroundworkMutation] = useInstallGroundworkMutation();

  const platforms = useMemo<Array<Platform>>(() => {
    if (product === ProductType.Spektra) {
      return [
        {
          key: Provider.Gcp,
          image: 'GCP',
          text: 'Google Cloud Platform',
          enabled: true,
        },
        {
          key: Provider.Aws,
          image: 'AWS',
          text: 'Amazon Web Services',
          enabled: true,
        },
        {
          key: Provider.Eks,
          image: 'EKSLogo',
          text: 'Elastic Kubernetes Service',
          enabled: true,
        },
        {
          key: Provider.Gke,
          image: 'GKELogo',
          text: 'Google Kubernetes Engine',
          enabled: true,
        },
        {
          key: Provider.Vsphere,
          image: 'Vsphere',
          text: 'vSphere',
          enabled: true,
        },
        {
          key: Provider.Baremetal,
          image: 'Baremetal',
          text: 'Baremetal',
          enabled: true,
        },
      ];
    }
    return [
      {
        key: Provider.Gcp,
        image: 'GCP',
        text: 'Google Cloud Platform',
        enabled: true,
      },
      {
        key: Provider.Aws,
        image: 'AWS',
        text: 'Amazon Web Services',
        enabled: true,
      },
      {
        key: Provider.Vsphere,
        image: 'Vsphere',
        text: 'vSphere',
        enabled: true,
      },
      {
        key: Provider.Baremetal,
        image: 'Baremetal',
        text: 'Baremetal',
        enabled: true,
      },
    ];
  }, [product]);

  const totalSteps = 3;

  const handleStep = useCallback((i: number | undefined) => {
    if (i === undefined) return;

    setActiveStep(i);
    if (highestStep.current < i) {
      highestStep.current = i;
    }
  }, []);

  const submitGcpUltimaEnterprise = useCallback(
    async (config: GcpUltimaEnterpriseConfigForm) => {
      const input: InstallUltimaEnterpriseInput = {
        cloudType: Provider.Gcp,
        [Provider.Gcp]: {
          clusterName: config.clusterName,
          numNodes: config.numNodes.value,
          machineType: config.machineType.value,
          numDrives: 4,
          driveType: 'NVME',
          zone: config.zone.value,
          instanceUsername: 'diamanti',
          imageName: productVersionMap[ProductType.UltimaEnterprise][Provider.Gcp],
          projectId: gcpCredential.projectId,
          ultimaEnterprisePassword: config.ultimaEnterprisePassword,
          preemptible: false,
          serviceAccount: gcpCredential.serviceAccount,
        },
      };

      const result = await installUltimaEnterpriseMutation({
        variables: {
          input,
        },
        refetchQueries: ['CanInstallUltimaEnterprise'],
      });
      const installation = result.data?.installUltimaEnterprise;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [gcpCredential, installUltimaEnterpriseMutation, navigate]
  );

  const submitAwsUltimaEnterprise = useCallback(
    async (config: AwsUltimaEnterpriseConfigForm) => {
      const region = config.zone.value.slice(0, config.zone.value.length - 1);
      const input: InstallUltimaEnterpriseInput = {
        cloudType: Provider.Aws,
        [Provider.Aws]: {
          clusterName: config.clusterName,
          numNodes: config.numNodes.value,
          machineType: config.machineType.value,
          zone: config.zone.value,
          imageName: productVersionMap[ProductType.UltimaEnterprise][Provider.Aws][region],
          ultimaEnterprisePassword: config.ultimaEnterprisePassword,
          credentials: awsCredential,
        },
      };

      const result = await installUltimaEnterpriseMutation({
        variables: {
          input,
        },
        refetchQueries: ['CanInstallUltimaEnterprise'],
      });
      const installation = result.data?.installUltimaEnterprise;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [awsCredential, installUltimaEnterpriseMutation, navigate]
  );

  const submitVsphereUltimaEnterprise = useCallback(
    async (config: VsphereUltimaEnterpriseConfigForm) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const query = await execQuery({
        variables: { provider: config.provider, productType: config.product },
      });
      const input: InstallUltimaEnterpriseInput = {
        cloudType: Provider.Vsphere,
        [Provider.Vsphere]: {
          s3DownloadLink: query.data?.s3DownloadUrl.downloadUrl as string,
        },
      };

      // window.open(query.data?.s3DownloadUrl.downloadUrl as string, '_blank');
      const result = await installUltimaEnterpriseMutation({
        variables: {
          input,
        },
        refetchQueries: ['CanInstallUltimaEnterprise'],
      });
      const installation = result.data?.installUltimaEnterprise;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [installUltimaEnterpriseMutation, navigate]
  );

  const submitBaremetalUltimaEnterprise = useCallback(
    async (config: BaremetalUltimaEnterpriseConfigForm) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const query = await execQuery({
        variables: { provider: config.provider, productType: config.product },
      });
      const input: InstallUltimaEnterpriseInput = {
        cloudType: Provider.Baremetal,
        [Provider.Baremetal]: {
          s3DownloadLink: query.data?.s3DownloadUrl.downloadUrl as string,
        },
      };

      // window.open(query.data?.s3DownloadUrl.downloadUrl as string, '_blank');
      const result = await installUltimaEnterpriseMutation({
        variables: {
          input,
        },
        refetchQueries: ['CanInstallUltimaEnterprise'],
      });
      const installation = result.data?.installUltimaEnterprise;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [installUltimaEnterpriseMutation, navigate]
  );

  const submitGcpSpektra = useCallback(
    async (config: GcpSpektraConfigForm) => {
      const input: InstallSpektraInput = {
        cloudType: Provider.Gcp,
        [Provider.Gcp]: {
          name: config.name,
          zone: config.zone.value,
          imageName: productVersionMap[ProductType.Spektra][Provider.Gcp],
          projectId: gcpCredential.projectId,
          spektraPassword: config.spektraPassword,
          serviceAccount: gcpCredential.serviceAccount,
        },
      };

      const result = await installSpektraMutation({
        variables: {
          input,
        },
      });
      const installation = result.data?.installSpektra;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [gcpCredential, installSpektraMutation, navigate]
  );

  const submitAwsSpektra = useCallback(
    async (config: GcpSpektraConfigForm) => {
      const input: InstallSpektraInput = {
        cloudType: Provider.Aws,
        [Provider.Aws]: {
          name: config.name,
          zone: config.zone.value,
          imageName: productVersionMap[ProductType.Spektra][Provider.Aws],
          spektraPassword: config.spektraPassword,
          credentials: awsCredential,
        },
      };

      const result = await installSpektraMutation({
        variables: {
          input,
        },
      });
      const installation = result.data?.installSpektra;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [awsCredential, installSpektraMutation, navigate]
  );

  const submitGcpGroundwork = useCallback(
    async (config: GcpGroundworkConfigForm) => {
      const input: InstallGroundworkInput = {
        cloudType: Provider.Gcp,
        [Provider.Gcp]: {
          name: config.name,
          zone: config.zone.value,
          imageName: productVersionMap[ProductType.Groundwork][Provider.Gcp],
          projectId: gcpCredential.projectId,
          groundworkPassword: config.groundworkPassword,
          serviceAccount: gcpCredential.serviceAccount,
        },
      };

      const result = await installGroundworkMutation({
        variables: {
          input,
        },
      });
      const installation = result.data?.installGroundwork;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [gcpCredential, installGroundworkMutation, navigate]
  );

  const submitAwsGroundwork = useCallback(
    async (config: AwsGroundworkConfigForm) => {
      const input: InstallGroundworkInput = {
        cloudType: Provider.Aws,
        [Provider.Aws]: {
          name: config.name,
          zone: config.zone.value,
          imageName: productVersionMap[ProductType.Groundwork][Provider.Aws],
          credentials: awsCredential,
          groundworkPassword: config.groundworkPassword,
        },
      };

      const result = await installGroundworkMutation({
        variables: {
          input,
        },
      });
      const installation = result.data?.installGroundwork;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [awsCredential, installGroundworkMutation, navigate]
  );

  const submitEksSpektra = useCallback(
    async (config: EksSpektraConfigForm) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const query = await execQuery({
        variables: { provider: config.provider, productType: config.product },
      });
      const input: InstallSpektraInput = {
        cloudType: Provider.Eks,
        [Provider.Eks]: {
          s3DownloadLink: query.data?.s3DownloadUrl.downloadUrl as string,
        },
      };

      // window.open(query.data?.s3DownloadUrl.downloadUrl as string, '_blank');
      const result = await installSpektraMutation({
        variables: {
          input,
        },
        refetchQueries: ['CanInstallSpektra'],
      });
      const installation = result.data?.installSpektra;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [installSpektraMutation, navigate]
  );

  const submitGkeSpektra = useCallback(
    async (config: GkeSpektraConfigForm) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const query = await execQuery({
        variables: { provider: config.provider, productType: config.product },
      });
      const input: InstallSpektraInput = {
        cloudType: Provider.Gke,
        [Provider.Gke]: {
          s3DownloadLink: query.data?.s3DownloadUrl.downloadUrl as string,
        },
      };

      // window.open(query.data?.s3DownloadUrl.downloadUrl as string, '_blank');
      const result = await installSpektraMutation({
        variables: {
          input,
        },
        refetchQueries: ['CanInstallSpektra'],
      });
      const installation = result.data?.installSpektra;

      if (!installation) return; // Failed

      navigate(generatePath(INSTALLATION, { id: installation.id }));
    },
    [installSpektraMutation, navigate]
  );

  const handlePlatform = useCallback(
    (data: PlatformForm, i: number | undefined) => {
      setPlatform(data);
      handleTempPlatformChange(data.cloudType as Provider);
      handleStep(i);
    },
    [handleStep]
  );

  const handleTempPlatformChange = (provider: Provider): void => {
    if (
      provider === Provider.Vsphere ||
      provider === Provider.Baremetal ||
      provider === Provider.Eks ||
      provider === Provider.Gke
    ) {
      setWizardSteps([
        {
          label: 'Deployment Environment',
          description: ProviderName[provider],
        },
        { label: 'Configuration', description: '' },
      ]);
    } else {
      setWizardSteps([
        {
          label: 'Deployment Environment',
          description: ProviderName[provider],
        },
        {
          label: 'Install Type',
          description: highestStep.current > 2 ? 'Automated' : '',
        },
        { label: 'Configuration', description: '' },
      ]);
    }
  };

  const handleGcpCredential = useCallback(
    (data: GcpCredentialForm, i: number | undefined) => {
      setGcpCredential(data);
      handleStep(i);
    },
    [handleStep]
  );

  const handleAwsCredential = useCallback(
    (data: AwsCredentialForm, i: number | undefined) => {
      setAwsCredential(data);
      handleStep(i);
    },
    [handleStep]
  );

  const handleGcpUltimaEnterpriseConfig = useCallback(
    (data: GcpUltimaEnterpriseConfigForm, i: number | undefined): void | Promise<void> => {
      setGcpUltimaEnterpriseConfig(data);

      handleStep(i);
      if (i === undefined) submitGcpUltimaEnterprise(data);
    },
    [handleStep, submitGcpUltimaEnterprise]
  );

  const handleAwsUltimaEnterpriseConfig = useCallback(
    (data: AwsUltimaEnterpriseConfigForm, i: number | undefined): void | Promise<void> => {
      setAwsUltimaEnterpriseConfig(data);

      handleStep(i);

      if (i === undefined) submitAwsUltimaEnterprise(data);
    },
    [handleStep, submitAwsUltimaEnterprise]
  );

  const handleVsphereUltimaEnterpriseConfig = useCallback(
    (data: VsphereUltimaEnterpriseConfigForm, i: number | undefined): void | Promise<void> => {
      handleStep(i);

      if (i === undefined) submitVsphereUltimaEnterprise(data);
    },
    [handleStep, submitVsphereUltimaEnterprise]
  );

  const handleBaremetalUltimaEnterpriseConfig = useCallback(
    (data: BaremetalUltimaEnterpriseConfigForm, i: number | undefined): void | Promise<void> => {
      handleStep(i);

      if (i === undefined) submitBaremetalUltimaEnterprise(data);
    },
    [handleStep, submitBaremetalUltimaEnterprise]
  );

  const handleEksSpektraConfig = useCallback(
    (data: EksSpektraConfigForm, i: number | undefined): void | Promise<void> => {
      handleStep(i);

      if (i === undefined) submitEksSpektra(data);
    },
    [handleStep, submitEksSpektra]
  );

  const handleGkeSpektraConfig = useCallback(
    (data: GkeSpektraConfigForm, i: number | undefined): void | Promise<void> => {
      handleStep(i);

      if (i === undefined) submitGkeSpektra(data);
    },
    [handleStep, submitGkeSpektra]
  );

  const handleGcpSpektraConfig = useCallback(
    (data: GcpSpektraConfigForm, i: number | undefined): void | Promise<void> => {
      setGcpSpektraConfig(data);

      handleStep(i);

      if (i === undefined) submitGcpSpektra(data);
    },
    [handleStep, submitGcpSpektra]
  );

  const handleAwsSpektraConfig = useCallback(
    (data: GcpSpektraConfigForm, i: number | undefined): void | Promise<void> => {
      setAwsSpektraConfig(data);

      handleStep(i);

      if (i === undefined) submitAwsSpektra(data);
    },
    [handleStep, submitAwsSpektra]
  );

  const handleGcpGroundworkConfig = useCallback(
    (data: GcpGroundworkConfigForm, i: number | undefined): void | Promise<void> => {
      setGcpGroundworkConfig(data);

      handleStep(i);

      if (i === undefined) submitGcpGroundwork(data);
    },
    [handleStep, submitGcpGroundwork]
  );

  const handleAwsGroundworkConfig = useCallback(
    (data: AwsGroundworkConfigForm, i: number | undefined): void | Promise<void> => {
      setAwsGroundworkConfig(data);

      handleStep(i);

      if (i === undefined) submitAwsGroundwork(data);
    },
    [handleStep, submitAwsGroundwork]
  );

  return (
    <ScrollableModal
      isStatic
      open
      onClose={(): void => navigate(ROOT)}
      title={<Typography type="h2">Install</Typography>}
      body={
        <ModalWizard activeStep={activeStep} totalSteps={3}>
          <div style={{ display: 'flex', marginLeft: '100px' }}>
            <Main>
              <div>
                <AsideHeader>
                  <ImageIcon image={productImageMap[product]} />
                  <StepTitle type="h4">{productNameMap[product]}</StepTitle>
                </AsideHeader>
                <Wizard.Stepper steps={wizardSteps} width="350px" />
              </div>
              <div>
                <PlatformStep
                  stepNumber={1}
                  totalSteps={totalSteps}
                  defaultValues={platform}
                  onChange={handlePlatform}
                  onTempChange={handleTempPlatformChange}
                  platforms={platforms}
                  product={product}
                />
                {platform.cloudType === Provider.Gcp && (
                  <GcpCredentialStep
                    stepNumber={2}
                    totalSteps={totalSteps}
                    defaultValues={gcpCredential}
                    onChange={handleGcpCredential}
                  />
                )}
                {platform.cloudType === Provider.Aws && (
                  <AwsCredentialStep
                    stepNumber={2}
                    totalSteps={totalSteps}
                    defaultValues={awsCredential}
                    onChange={handleAwsCredential}
                  />
                )}
                {platform.cloudType === Provider.Gcp &&
                  product === ProductType.UltimaEnterprise && (
                    <GcpUltimaEnterpriseConfigStep
                      stepNumber={3}
                      totalSteps={totalSteps}
                      defaultValues={gcpUltimaEnterpriseConfig}
                      onChange={handleGcpUltimaEnterpriseConfig}
                    />
                  )}
                {platform.cloudType === Provider.Aws &&
                  product === ProductType.UltimaEnterprise && (
                    <AwsUltimaEnterpriseConfigStep
                      stepNumber={3}
                      totalSteps={totalSteps}
                      defaultValues={awsUltimaEnterpriseConfig}
                      onChange={handleAwsUltimaEnterpriseConfig}
                    />
                  )}
                {platform.cloudType === Provider.Gcp && product === ProductType.Spektra && (
                  <GcpSpektraConfigStep
                    stepNumber={3}
                    totalSteps={totalSteps}
                    defaultValues={gcpSpektraConfig}
                    onChange={handleGcpSpektraConfig}
                  />
                )}
                {platform.cloudType === Provider.Aws && product === ProductType.Spektra && (
                  <AwsSpektraConfigStep
                    stepNumber={3}
                    totalSteps={totalSteps}
                    defaultValues={awsSpektraConfig}
                    onChange={handleAwsSpektraConfig}
                  />
                )}
                {product === ProductType.Groundwork && platform.cloudType === Provider.Gcp && (
                  <GcpGroundworkConfigStep
                    stepNumber={3}
                    totalSteps={totalSteps}
                    defaultValues={gcpGroundworkConfig}
                    onChange={handleGcpGroundworkConfig}
                  />
                )}
                {product === ProductType.Groundwork && platform.cloudType === Provider.Aws && (
                  <AwsGroundworkConfigStep
                    stepNumber={3}
                    totalSteps={totalSteps}
                    defaultValues={awsGroundworkConfig}
                    onChange={handleAwsGroundworkConfig}
                  />
                )}
                {platform.cloudType === Provider.Vsphere &&
                  product === ProductType.UltimaEnterprise && (
                    <VsphereUltimaEnterpriseConfigStep
                      stepNumber={2}
                      totalSteps={2}
                      defaultValues={vsphereUltimaEnterpriseConfigFormDefault}
                      onChange={handleVsphereUltimaEnterpriseConfig}
                    />
                  )}
                {platform.cloudType === Provider.Baremetal &&
                  product === ProductType.UltimaEnterprise && (
                    <BaremetalUltimaEnterpriseConfigStep
                      stepNumber={2}
                      totalSteps={2}
                      defaultValues={baremetalUltimaEnterpriseConfigFormDefault}
                      onChange={handleBaremetalUltimaEnterpriseConfig}
                    />
                  )}
                {platform.cloudType === Provider.Eks && product === ProductType.Spektra && (
                  <EksSpektraConfigStep
                    stepNumber={2}
                    totalSteps={2}
                    defaultValues={eksSpektraConfigFormDefault}
                    onChange={handleEksSpektraConfig}
                  />
                )}
                {platform.cloudType === Provider.Gke && product === ProductType.Spektra && (
                  <GkeSpektraConfigStep
                    stepNumber={2}
                    totalSteps={2}
                    defaultValues={gkeSpektraConfigFormDefault}
                    onChange={handleGkeSpektraConfig}
                  />
                )}
              </div>
            </Main>
          </div>
        </ModalWizard>
      }
    />
  );
};
