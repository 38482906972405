import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Layout, ThemeProvider } from '@diamanticom/picasa-core';

import {
  generatePath,
  PRODUCTS,
  ROOT,
  INSTALLATION,
  INSTALL,
  ADMIN_DASHBOARD,
  UltimaS,
} from '$utils/paths'; // Add YAML_GENERATOR import
import { AuthProvider } from '$utils/AuthProvider';
import { GQLProvider } from '$utils/GQLProvider';
import { GlobalStyle } from '$utils/GlobalStyles';

import { AppSideNav } from '$cmp/app-side-nav/AppSideNav';

import { ProductsPage } from '$pages/products/ProductsPage';
import { InstallationPage } from '$pages/installation/InstallationPage';
import { InstallationPage as InstallPage } from '$pages/install/InstallPage';
import { InstallationWatcher } from '$cmp/installation-watcher/InstallationWatcher';
import { RegisterGuard } from '$pages/register/RegisterGuard';
import { AdminDashboardPage } from '$pages/admin/AdminDashboardPage';
import { UltimaStorage } from './pages/UlimaStoragePages/UltimaStorageInstallation';

export const App: FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <GlobalStyle />
        <AuthProvider>
          <GQLProvider>
            <RegisterGuard>
              <InstallationWatcher />
              <Layout>
                <Layout.Nav>
                  <AppSideNav />
                </Layout.Nav>
                <Routes>
                  <Route path={PRODUCTS} element={<ProductsPage />} />
                  <Route path={INSTALLATION} element={<InstallationPage />} />
                  <Route path={INSTALL} element={<InstallPage />} />
                  <Route path={ADMIN_DASHBOARD} element={<AdminDashboardPage />} />
                  <Route path={UltimaS} element={<UltimaStorage />} />
                  <Route path="*" element={<Navigate to={generatePath(ROOT)} replace />} />
                </Routes>
              </Layout>
            </RegisterGuard>
          </GQLProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
