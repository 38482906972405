import { FC } from 'react';
import styled from 'styled-components';
import { Card, CmpProps, Table, Typography } from '@diamanticom/picasa-core';

import { InstallationFragment } from '$gql';
import { getConfigLabeledValues } from '$utils/installation';
import { MinimumVMRequirements, MINIMUM_VM_VSPHERE } from '$utils/lists/installationRequirements';
import { createColumnHelper } from '@tanstack/react-table';

const Body = styled.div`
  width: 100%;

  padding-top: 8px;

  display: grid;
  gap: 24px;
`;

export type InstallationMinVMCardProps = CmpProps<{
  installation: InstallationFragment;
}>;
const columnHelper = createColumnHelper<MinimumVMRequirements>();
const columns = [
  columnHelper.accessor('resource', {
    header: () => <Typography type="pBold">Resource</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 80,
  }),
  columnHelper.accessor('minimum', {
    header: () => <Typography type="pBold">Minimum</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 80,
  }),
];
export const InstallationMinVMCard: FC<InstallationMinVMCardProps> = ({
  className,
  installation,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const labeledValues = getConfigLabeledValues(installation);

  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header title="Minimum VM Requirements" />
      <Card.Body>
        <Body>
          <Table columns={columns} data={MINIMUM_VM_VSPHERE} />
        </Body>
      </Card.Body>
    </Card>
  );
};
