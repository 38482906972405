import { ImageIcon } from '@diamanticom/picasa-core';
import { FC } from 'react';
import styled from 'styled-components';

const HelpTextLink = styled.a`
  color: ${(props): string => props.theme.palette.link};
  margin-left: 2px;
`;
const HeadingText = styled.div`
  font-weight: bold;
`;
const ContextText = styled.div`
  padding-left: 10px;
`;
export const EulaTerms: FC = () => {
  return (
    <div style={{ overflow: 'auto', height: '100px' }}>
      <div className="signin-lbl" style={{ textAlign: 'center', display: 'flex' }}>
        <ImageIcon image="DiamantiLogo" width={24} height={18} />
        <p>
          <span>Diamanti End User License Agreement (EULA)</span>
        </p>
      </div>

      <div style={{ fontSize: '13px', fontFamily: 'sans-serif' }}>
        <p>
          <br />
          DIAMANTI, INC. (“Diamanti”) AGREES TO LICENSE CERTAIN SOFTWARE TO YOUR BUSINESS OR
          ORGANIZATION (“Customer”) ONLY IF (A) YOU REPRESENT AND WARRANT THAT YOU HAVE THE
          AUTHORITY TO LEGALLY BIND CUSTOMER AND (B) YOU ACCEPT AND AGREE ON BEHALF OF CUSTOMER TO
          BE BOUND BY ALL OF THE TERMS AND CONDITIONS IN THIS DIAMANTI END USER LICENSE AGREEMENT,
          INCLUDING ALL DOCUMENTS INCORPORATED HEREIN BY REFERENCE (COLLECTIVELY, THE “Agreement”),
          WHICH SHALL BE DEFINITIVELY EVIDENCED BY ANY ONE OF THE FOLLOWING MEANS: YOUR CLICKING THE
          “ACCEPT”, “CONTINUE” OR A SIMILAR BUTTON, AS APPLICABLE; YOUR SIGNATURE ON An AGREEMENT
          WITH A TANGIBLE COPY OF THIS EULA; YOUR INSTALLATION OR USE OF THE SOFTWARE, OR BY SUCH
          OTHER CONTRACT FORMATION MECHANISM AS MAY BE RECOGNIZED UNDER APPLICABLE LAW FROM TIME TO
          TIME. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS IN THIS AGREEMENT, DO NOT COPY,
          INSTALL OR USE THE SOFTWARE.
        </p>
        <br />
        <HeadingText>1. DEFINITIONS</HeadingText>
        <br />
        <br />
        <ContextText>
          1.1. “Documentation” means such manuals, documentation and any other supporting materials
          relating to the Software as currently maintained by Diamanti and generally provided to its
          Customers.
          <br />
          <br />
          1.2. “Equipment” means the hardware purchased by Customer, upon which the Software runs
          and operates. Equipment may be branded by Diamanti (“Diamanti Equipment”) or by a
          third-party OEM (“OEM Equipment”), and subject to a separate purchase or ordering
          document.
          <br />
          <br />
          1.3. “Licensed Materials” means the Software and Documentation.
          <br />
          <br />
          1.4. “Order” means a purchase order, invoice or other electronic or written order for the
          commercial transaction between Customer and Diamanti concerning the Software that may
          include the length of the subscription term and other provisions.
          <br />
          <br />
          1.5. “Software” means the object code version of the computer software provided to
          Customer under this Agreement, any extracts from such software, derivative works of such
          software or collective works constituting such software (such as subsequent releases) to
          the extent offered to Customer under this Agreement, and the related Documentation.
          Software may include third party software licensed to Diamanti. Software shall not mean
          software subject to open source, GPL or similar licensing terms which may be included with
          the Software. Applicable copyright notices and open source, GPL or similar licensing terms
          can be found at
          <HelpTextLink target="_blank" href="https://diamanti.com/legal/">
            legal policy
          </HelpTextLink>
        </ContextText>
        <br />
        <br />
        <HeadingText>2. RIGHTS AND RESTRICTIONS</HeadingText>
        <br />
        <br />
        <ContextText>
          2.1. License. Subject to the terms and conditions of this Agreement and any provisions in
          the Order, Diamanti grants to Customer a personal, revocable, nonexclusive,
          nontransferable, non-sublicensable, right to use the Licensed Materials, subject to the
          following restrictions:
          <br />
          <br />
          (a) Customer shall use the Licensed Materials for its internal purposes only. In no event
          shall the Licensed Materials be disclosed, made available to or used for the benefit of
          any third party; or sold, assigned, leased, used for timesharing or service bureau
          purposes, resold or distributed or otherwise disposed of.
          <br />
          <br />
          (b) The license granted hereunder is limited to use of the Licensed Materials on Equipment
          <br />
          <br />
          (c) Customer shall not copy the Licensed Materials, except for archival or backup purposes
          or as required by normal installation procedures specified by Diamanti.;
          <br />
          <br />
          (d) Except to the extent permitted by applicable law, Customer shall not copy, modify,
          translate, decompile, disassemble or otherwise reverse engineer, or otherwise determine or
          attempt to determine source code or protocols from, the executable code of the Software,
          or create any derivative works based upon the Software or Documentation, and Customer
          shall not permit or authorize anyone else to do so. Customer also agrees that any works
          created in violation of this subsection are derivative works and, as such, Customer
          assigns all right, title and interest therein to Diamanti.
          <br />
          <br />
          (e) Customer shall not disclose to any third party any benchmarking or other test results
          concerning the performance or capability of the Software or Equipment without Diamanti’s
          prior written consent.
          <br />
          <br />
          (f) Customer shall not use the Licensed Materials or Equipment in order to gain
          information that may assist Customer to compete against Diamanti.
          <br />
          <br />
          (g) Customer shall only use those Software features paid for, or otherwise authorized by
          Diamanti, and shall not enable any unpaid for or unauthorized features by using a key to
          unlock the Software or otherwise accessing such features.
          <br />
          <br />
          2.2. Documentation License. Diamanti will provide Customer with Documentation for the for
          its internal use. Customer will not allow any unauthorized access to, copying of, or the
          creation of derivative works from, the Documentation. Customer shall not remove any
          proprietary markings or legends placed upon or contained within the Documentation, nor add
          any proprietary markings or legends.
          <br />
          <br />
          2.3. Ownership. Licensed Materials is not sold but is licensed (perpetually or via
          subscription) solely for Customer’s use, only as installed on the Equipment and strictly
          in accordance with this Agreement. Diamanti retains all right, title and interest,
          including, without limitation, all patent rights, copyrights, trademarks and trade
          secrets, in and to the Licensed Materials and any portion thereof, including, without
          limitation, any copy or derivative work of the Licensed Materials (or any portion thereof)
          and any update thereto. Any rights to the Licensed Materials not granted herein are
          reserved by Diamanti and its licensors.
          <br />
          <br />
          2.4. Delivery and Equipment Transfer. The Licensed Materials may come preinstalled on
          Equipment or Diamanti may make the Licensed Materials available to Customer for download
          via a secure download site. This license shall automatically terminate in the event
          Customer sells, assigns, or otherwise transfers the Equipment to a third party. In such
          event, Diamanti shall offer such third party a license, on its then standard terms and
          conditions, to use the Licensed Materials in connection with the Equipment, provided
          however, that such third party is not, in Diamanti’s reasonable judgment, a competitor.
          <br />
          <br />
          2.5. Termination; Suspension. In the event of a material breach of this Agreement by
          Customer, Diamanti may in its discretion (i) suspend or revoke any or all of Customer’s
          rights hereunder, and/or (ii) terminate this Agreement, and/or (iii) suspend or terminate
          Customer’s right to receive support and maintenance services, notwithstanding the
          existence of a valid support contract, in each case by giving advance warning to Customer
          effective in seven (7) days unless Customer first cures such breach, or effective
          immediately if the breach is incapable of cure. Diamanti’s remedies for Customer’s breach
          of this Agreement are cumulative not exclusive.
          <br />
          <br />
          2.6. Evaluation License. Diamanti may provide Customer, on a discounted or no fee basis,
          Licensed Materials (which may include Diamanti Equipment) for evaluation purposes, for a
          limited time period (“Evaluation Term”). Notwithstanding anything to the contrary, the
          Evaluation Term is limited thirty (30) days, unless otherwise extended in writing by
          Diamanti. Customer’s right to evaluate the Licensed Materials (and Diamanti Equipment, if
          applicable) shall automatically expire at the end of the Evaluation Term, at which time
          Customer agrees to immediately discontinue use of, and return or destroy, all copies of
          any Licensed Materials, wherever residing. To the extent Diamanti Equipment is provided
          for such Evaluation, Customer agrees to return all Diamanti Equipment to Diamanti (using
          an RMA process) and to pay Diamanti for any damage or loss arising from Customer’s
          possession and return of the Diamanti Equipment. If Diamanti provides Customer with a
          pre-release version of the Licensed Materials for beta-testing purposes, Customer
          acknowledges and agrees that such pre-release version of the Licensed Materials is subject
          to these evaluation terms, and that such pre-release version of the Licensed Materials is
          provided on an “as-is” basis without warranty, and without any obligation of support,
          maintenance or other services from Diamanti.
          <br />
          <br />
          2.7. Collection and Use of Information. Customer acknowledges that Diamanti may collect
          and store information regarding use of the Software and about equipment on which the
          Software is installed or through which it otherwise is accessed and used. Customer agrees
          that the Diamanti may use such information for any purpose related to any use of the
          Software by Customer or Customer’s equipment, including but not limited to providing
          information to Customer about the use and performance of the Software; and verifying
          Customer&lsquo;s compliance with the terms of this Agreement.
        </ContextText>
        <br />
        <br />
        <HeadingText>3. CONFIDENTIALITY</HeadingText>
        <br />
        <br />
        <ContextText>
          Diamanti pricing; non-public information relating to the Licensed Materials (including
          without limitation benchmarking or comparative studies involving the Licensed
          Materials),and other Diamantimaterials which are marked‘confidential’,‘proprietary’,or
          with a similar legend, or which a reasonable person would understand from the
          circumstances of disclosure to be confidential in nature,shall be treated as
          confidential,and Customer shall not use or disclose same except as expressly authorized by
          this Agreement or by Diamanti in writing. Information will not be considered confidential
          if it (i) is already knownby Customer without obligation of confidentiality, (ii) is or
          becomes public other than through breach of this Agreement, or (iii) is received by
          Customer from a third party not known (in good faith) to be under an obligation of
          confidence to Diamanti.
        </ContextText>
        <br />
        <br />
        <HeadingText>4. LIMITED WARRANTY</HeadingText>
        <br />
        <br />
        <ContextText>
          Diamanti provides an Express Limited Warranty (“Warranty”), the current version of which
          is available at{' '}
          <HelpTextLink target="_blank" href="https://diamanti.com/legal/">
            legal policy
          </HelpTextLink>
          , which is incorporated herein by reference. After the warranty period specified therein,
          Customer is entitled to receive only the support services specified for the applicable
          support level and term that Customer has ordered and paid for pursuant to Diamanti’s
          Support Agreement found at{' '}
          <HelpTextLink target="_blank" href="https://diamanti.com/legal/">
            legal policy
          </HelpTextLink>
          , which is incorporated herein by reference.
        </ContextText>
        <br />
        <br />
        <HeadingText>5. SUPPORT AND MAINTENANCE</HeadingText>
        <br />
        <br />
        <ContextText>
          Diamanti Software support obligations are set forth at{' '}
          <HelpTextLink target="_blank" href="https://diamanti.com/legal/">
            legal policy
          </HelpTextLink>{' '}
          and apply only in the event that Licensee has purchased support and Diamanti has accepted
          Licensee’s purchase. No Software support, maintenance, updates or upgrades are offered
          pursuant to this Agreement.
        </ContextText>
        <br />
        <br />
        <HeadingText>6. TERM AND TERMINATION</HeadingText>
        <br />
        <br />
        <ContextText>
          This Agreement shall commence upon the date of shipment of the Licensed Materials to the
          Customer pursuant to an Order and shall continue in effect for the term stated in the
          Order or until terminated earlier pursuant to this Agreement. Either party may terminate
          this Agreement immediately upon written notice to the other party if the other party is in
          breach of this Agreement or fails to perform any of its duties or obligations hereunder (a
          “Default”) and fails to cure such Default within thirty (30) days after receipt of written
          notice from the non-defaulting party specifying the occurrence or existence of the
          Default. Upon termination of this Agreement: (a) all license rights to Licensed Materials
          under this Agreement will immediately cease; (b) Customer must cease use and return to
          Diamanti the Licensed Materials or certify destruction of Licensed Materials (including
          copies) in Customer’s possession or control; and (c) the provisions of Sections 2.3, 3, 4,
          6, 7, 8 and 9 shall survive.
        </ContextText>
        <br />
        <br />
        <HeadingText>7. LIMITATION OF LIABILITY</HeadingText>
        <br />
        <br />
        <ContextText>
          7.1. EXCLUSION OF CERTAIN LOSSES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
          EVENT SHALL EITHER PARTY BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE OR
          CONSEQUENTIAL LOSSES OR DAMAGES WHATSOEVER, OR FOR ANY LOSS OF GOODWILL, LOST PROFITS,
          LOSS OF BUSINESS OR LOST OPPORTUNITIES IN ANY WAY RELATING TO THIS AGREEMENT, EVEN IF A
          PARTY HAS BEEN NOTIFIED OF, OR REASONABLY COULD HAVE FORESEEN, THE POSSIBILITY OR
          LIKELIHOOD OF SUCH DAMAGES OCCURRING, AND REGARDLESS OF WHETHER SUCH LIABILITY IS BASED ON
          CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE.
          <br />
          <br />
          7.2. LIMITATION OF LIABILITY. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF DIAMANTI EXCEED
          THE LESSER OF ONE MILLION DOLLARS ($1,000,000) OR THE FEES PAID BY CUSTOMER FOR THE
          LICENSED MATERIALS IN THE TWELVE (12) MONTHS PRECEDING THE DATE OF THE CLAIM. FOR PURPOSES
          OF THIS SECTION, A “CLAIM” SHALL MEAN, COLLECTIVELY, ALL CAUSES OF ACTION, DAMAGES, CLAIMS
          OR DISPUTES OR SIMILAR REQUESTS FOR COMPENSATION THAT ARE RELATED TO OR ARISE FROM THIS
          AGREEMENT AND FROM A PARTICULAR EVENT, ACT, OMISSION, FAILURE OR ROOT CAUSE.
        </ContextText>
        <br />
        <br />
        <HeadingText>8. INTELLECTUAL PROPERTY INDEMNITY</HeadingText>
        <br />
        <br />
        <ContextText>
          8.1. Subject to the limitations set forth in this Section 8 Diamanti shall defend or, at
          its option, settle any claim or action against Customer and hold Customer harmless from
          any and all liabilities, damages, expenses, settlements and costs (including reasonable
          attorneys’ fees) finally awarded against Customer or reached in a settlement by Diamanti
          on Customer’s behalf, arising from or occurring as a result of any third party claim or
          action alleging that the Software on Equipment infringes any United States patent or
          copyright.
          <br />
          <br />
          8.2. Diamanti’s obligation to indemnify Customer under this Section 8 shall be subject to
          Customer: (i) promptly notifying Diamanti in writing of first learning of the claim or
          action giving rise to the indemnity; (ii) providing Diamanti with sole and exclusive
          control over the defense and/or settlement of such action or claim; and (iii) providing
          Diamanti with proper and full information and reasonable assistance to defend and/or
          settle any such claim or action. Diamanti shall not be responsible for indemnifying
          Customer with respect to costs incurred, or amounts paid in any settlement, unless
          Diamanti approved such costs or settlements in advance.
          <br />
          <br />
          8.3. Diamanti will have no liability under this Section 8 for any claim or action where
          such claim or action results from (i) combination, operation or use of the Software with
          hardware or software other than the Equipment with which it was installed; (ii)
          modification of the Software or Equipment unless such modification was made or authorized
          by Diamanti; or (iii) compliance with Customer’s designs, specifications or instructions.
          Notwithstanding anything to the contrary, Diamanti shall not be liable for any claim based
          on Customer’s use of the Software after Diamanti has informed Customer of modifications of
          the Software or Equipment required to avoid such claims and offered to implement those
          modifications, if such claim would have been avoided by implementation of Diamanti’s
          suggestions.
          <br />
          <br />
          8.4. If the Software becomes or is likely to become the subject of an infringement claim
          or action, Diamanti may at its sole option: (i) procure, at no cost to Customer, the right
          to continue using the Software; (ii) replace or modify the Software to render it/them
          non-infringing; or (iii) if, in Diamanti’s reasonable opinion, neither (i) nor (ii) above
          are commercially feasible, immediately terminate Diamanti’s obligations (and Customer’s
          rights) under this Agreement with regard to such Software, and, if Customer returns or
          destroys such Software, refund to Customer the price originally paid by Customer to
          Diamanti for such Software as depreciated or amortized by an equal annual amount over
          three (3) years.
          <br />
          <br />
          8.5. THE FOREGOING STATES THE ENTIRE LIABILITY AND OBLIGATIONS OF DIAMANTI AND THE
          EXCLUSIVE REMEDY OF CUSTOMER WITH RESPECT TO ANY ALLEGED OR ACTUAL INFRINGEMENT OF PATENTS
          OR COPYRIGHTS, BY THE SOFTWARE.
        </ContextText>
        <br />
        <br />
        <HeadingText>9. GENERAL</HeadingText>
        <br />
        <br />
        <ContextText>
          9.1. Assignment. Customer may not assign this Agreement or any of its rights or
          obligations under this Agreement, by operation of law or otherwise, without the prior
          written consent of Diamanti. This Agreement shall bind each party and its permitted
          successors and assignees. Any assignment in contravention of this Section 9.1 shall be
          null and void and of no force or effect.
          <br />
          <br />
          9.2. Choice of Law. This Agreement shall be governed by and construed in accordance with
          the laws of California. The UN Convention on Contracts for the International Sale of Goods
          shall not apply.
          <br />
          <br />
          9.3. Entire Agreement. This Agreement, including accepted Orders and any amendments
          hereto, is the entire agreement of the parties, and supersedes all prior or
          contemporaneous agreements, communications or representations, written or oral, between
          the parties with respect to the subject matter of this Agreement and represents the
          complete integration of the parties’ agreement. This Agreement may be modified only by a
          written agreement executed by authorized officers of each party. Any different or
          additional terms of Customer’s purchase order, confirmation, quote or similar ordering
          document will have no force or effect and will not be considered agreed to by Diamanti. If
          the terms and conditions in this Agreement are considered an offer, acceptance is
          expressly limited to such terms and conditions.
          <br />
          <br />
          9.4. Severability. If any provision of this Agreement shall be held to be invalid, illegal
          or unenforceable, the remaining provisions shall not be affected or impaired. No delay or
          omission to exercise any right or remedy accruing to a party upon any breach or default of
          the other party shall impair that right or remedy, or be construed to be a waiver of any
          breach or default.
          <br />
          <br />
          9.5. Hazardous and Excluded Use Restriction. The Licensed Materials are not designed for
          use, and Customer agrees not to use, the Licensed Materials for: (i) the operation of
          nuclear facilities, aircraft navigation or communication systems, air traffic control, and
          life support or weapons systems, or any other system whose failure could lead to injury,
          death, environmental damage, or mass destruction; and (ii) in applications in which
          failure of such Licensed Materials could reasonably be expected to result in personal
          injury, loss of life or catastrophic property damage (“Excluded Uses”). Customer has the
          sole responsibility for protecting its data – by periodically creating redundant copies or
          otherwise – and Diamanti is not responsible for lost or corrupted data, work stoppage,
          inaccurate output or computer failure or malfunction associated with (or occurring during)
          any Excluded Use.
          <br />
          <br />
          9.6. Force Majeure. Except for the obligation to pay monies due and owing, neither party
          shall be liable for any delay or failure in performance due to events outside the
          defaulting party’s reasonable control, including without limitation acts of God, labor
          disputes, shortages of supplies, fire, war, disruption related to terrorism, epidemics or
          pandemics, delays of common carriers or any other cause which is beyond the reasonable
          control of such party. The obligations and rights of the excused party shall be extended
          on a day to day basis for the time period equal to the period of the excusable delay.
          <br />
          <br />
          9.7. Compliance with Laws. Customer hereby acknowledges that the Licensed Materials are
          subject to export controls under the laws and regulations of the United States and other
          countries. Customer shall comply with such laws and regulations and agrees not to export,
          re-export or transfer the Licensed Materials in violation of all required U.S. Government
          and other relevant government authorizations or licenses.
          <br />
          <br />
          9.8. Survival. All terms of this Agreement which must survive in order to fulfil their
          essential purpose shall survive termination or expiration of this Agreement. For avoidance
          of doubt, no rights granted Customer hereunder shall survive termination.
        </ContextText>
        <br />
        &nbsp;
      </div>
    </div>
  );
};
