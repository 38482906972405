import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { CmpProps } from '@diamanticom/picasa-core';

import { InstallParams, ROOT } from '$utils/paths';
import { InstallModal } from '$cmp/install-modal/InstallModal';
import { productSlugMap } from '$utils/installation';

export type InstallationPageProps = CmpProps;

export const InstallationPage: FC<InstallationPageProps> = () => {
  const { product: productSlug } = useParams<InstallParams>();
  const product = productSlugMap[productSlug || ''];

  if (!product) return <Navigate to={ROOT} replace />;

  return <InstallModal product={product} />;
};
