import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Label,
  Input,
  CmpReturn,
  Select,
  SubmitButton,
  Modal,
} from '@diamanticom/picasa-core';
import { FormGroup } from '$cmp/install-modal/common';
import './us-custom.css';
import styled from 'styled-components';
import { DownloadSpecModal } from './DownloadSpecModal';
import { ROOT } from '$utils/paths';

import {
  PLATFORM_TYPES,
  PLATFORM_VERSIONS,
  DISTRIBUTIONS,
  K8S_VERSIONS,
  STORAGE_TYPES,
} from '$utils/lists/ultimaStorageConfigs';

// Styled components
const Container = styled.div`
  margin: 1% 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  font-size: 18px;
  font-family: Mukta, sans-serif;
  font-weight: 600;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 5%;
  margin-bottom: 5%;
`;
const ScrollableModal = styled(Modal)<{ isStatic: boolean }>`
  overflow: auto;
  background-color: #f8f7f7;
`;
const FormWrapper = styled.div`
  width: 60%;
  max-width: 800px;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 600;
`;

const MainTitle = styled.div`
  font-size: 35px;
  font-weight: 600;
  margin-left: 1%;
  margin-bottom: 0;
`;

export const UltimaStorage: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const handleFormSubmit = (): void => {
    setModalOpen(true);
  };

  const handleCloseModal = (): void => setModalOpen(false);

  return (
    <>
      <ScrollableModal
        isStatic
        open
        onClose={
          !modalOpen
            ? (): void => navigate(ROOT)
            : (): void => {
                console.log('Model Open');
              }
        }
        title={<MainTitle>Install</MainTitle>}
        body={
          <Container>
            <Title>Configurations</Title>
            <hr className="full-width-hr" />
            <Form onSubmit={handleFormSubmit}>
              {/* Platform Configuration Group */}
              <div className="titles">Select Platform</div>
              <hr className="half-width-hr" />
              <FormWrapper>
                <FormGroup className="title-groups">
                  <Label text="Product Version:" required>
                    {(id): CmpReturn => (
                      <Select
                        id={id}
                        name="productVersion"
                        placeholder="3.8.0"
                        defaultValue={PLATFORM_VERSIONS[0]}
                        options={PLATFORM_VERSIONS}
                      />
                    )}
                  </Label>
                  <Label text="Platform:" required>
                    {(id): CmpReturn => (
                      <Select
                        id={id}
                        name="platform"
                        placeholder="AWS"
                        defaultValue={PLATFORM_TYPES[0]}
                        options={PLATFORM_TYPES}
                      />
                    )}
                  </Label>
                </FormGroup>
              </FormWrapper>

              {/* Kubernetes Configuration Group */}
              <div className="titles">Kubernetes Configuration</div>
              <hr className="half-width-hr" />
              <FormWrapper>
                <FormGroup className="title-groups">
                  <Label text="Distribution Name:" required>
                    {(id): CmpReturn => (
                      <Select
                        id={id}
                        name="distributionName"
                        placeholder="Elastic Kubernetes Services"
                        defaultValue={DISTRIBUTIONS[0]}
                        options={DISTRIBUTIONS}
                      />
                    )}
                  </Label>
                  <Label text="Kubernetes Version:" required>
                    {(id): CmpReturn => (
                      <Select
                        id={id}
                        name="kubernetesVersion"
                        placeholder="1.30"
                        defaultValue={K8S_VERSIONS[0]}
                        options={K8S_VERSIONS}
                      />
                    )}
                  </Label>
                  <Label text="Namespace">
                    {(id: string): CmpReturn => (
                      <Input
                        id={id}
                        name="namespace"
                        placeholder="diamanti"
                        defaultValue="diamanti"
                        disabled
                      />
                    )}
                  </Label>
                </FormGroup>
              </FormWrapper>

              {/* Cluster Configuration Group */}
              <div className="titles">Cluster Configuration</div>
              <hr className="half-width-hr" />
              <FormWrapper>
                <FormGroup className="title-groups">
                  <Label text="Cluster Name:" required>
                    {(id): CmpReturn => (
                      <Input
                        id={id}
                        name="clusterName"
                        placeholder="diamanti"
                        defaultValue="diamanti"
                        disabled
                      />
                    )}
                  </Label>
                  <Label text="Number of Nodes:" required>
                    {(id): CmpReturn => (
                      <Input
                        id={id}
                        type="number"
                        name="numberOfNodes"
                        placeholder="3"
                        defaultValue="3"
                        disabled
                      />
                    )}
                  </Label>
                  <Label text="Drives per node:" required>
                    {(id): CmpReturn => (
                      <Input
                        id={id}
                        type="number"
                        name="driverPerNode"
                        placeholder="4"
                        defaultValue="4"
                        disabled
                      />
                    )}
                  </Label>
                  <Label text="Storage Type:" required>
                    {(id): CmpReturn => (
                      <Select
                        id={id}
                        name="storageType"
                        placeholder="NVMe"
                        defaultValue={STORAGE_TYPES[0]}
                        options={STORAGE_TYPES}
                      />
                    )}
                  </Label>
                </FormGroup>
                <ButtonWrapper>
                  <StyledSubmitButton text="Generate Spec" waitingText="Generating..." />
                </ButtonWrapper>
              </FormWrapper>
            </Form>
          </Container>
        }
      />
      {/* Modal for Downloading Spec */}
      {modalOpen && <DownloadSpecModal open={modalOpen} onClose={handleCloseModal} />}
    </>
  );
};
