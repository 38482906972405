import { FC } from 'react';
import styled from 'styled-components';
import { Card, CmpProps, Page, Typography } from '@diamanticom/picasa-core';

import { useInstallationsQuery } from '$gql';
import { InstallationTable } from '$cmp/installation-table/InstallationTable';
import { GroundworkCard } from '$cmp/products/groundwork-card/GroundworkCard';
import { SpektraCard } from '$cmp/products/spektra-card/SpektraCard';
import { UltimaEnterpriseCard } from '$cmp/products/ultima-enterprise-card/UltimaEnterpriseCard';
// import { UltimaStorageCard } from '$cmp/products/ultima-storage-card/UltimaStorageCard';
import { SalesButton } from '$cmp/contact/sales-button/SalesButton';
import { SupportButton } from '$cmp/contact/support-button/SupportButton';

const CardGrid = styled.div`
  flex: 1;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(300px, auto) minmax(300px, 1fr);
`;

const ProductsNullState = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductsNullStateInner = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
`;

const NullTitle = styled(Typography)`
  text-align: center;
`;

const NullBody = styled(Typography)`
  text-align: center;
  color: ${(props): string => props.theme.palette.gray.gray3};
`;

export type ProductsPageProps = CmpProps;

export const ProductsPage: FC<ProductsPageProps> = ({ className }) => {
  const query = useInstallationsQuery({ fetchPolicy: 'network-only' });
  const installations = query.data?.installations;
  return (
    <Page className={className} title="Products" headerRight={<SupportButton />}>
      <CardGrid>
        <UltimaEnterpriseCard />
        <SpektraCard />
        <GroundworkCard />
        {/* <UltimaStorageCard /> */}
        <Card style={{ gridColumn: 'span 3' }}>
          <Card.Header title="Your Products" />
          <Card.Body>
            {installations && !!installations.length && <InstallationTable data={installations} />}
            {installations && !installations.length && (
              <ProductsNullState>
                <ProductsNullStateInner>
                  <NullTitle type="h4">You don’t have any products yet!</NullTitle>
                  <NullBody type="body">Select a product above to start an installation.</NullBody>
                  <SalesButton />
                </ProductsNullStateInner>
              </ProductsNullState>
            )}
          </Card.Body>
        </Card>
      </CardGrid>
    </Page>
  );
};
