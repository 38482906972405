import { FC } from 'react';
import styled from 'styled-components';
import { Button, Card, CmpProps, CmpReturn, Label } from '@diamanticom/picasa-core';
import {
  InstallationOperation,
  InstallationStatus,
  InstallationFragment,
  ProductType,
  Provider,
} from '$gql';
import { useFlagState } from '$utils/hooks';
import { UnInstallationModal } from '../uninstallation-modal/UninstallationModal';

const Body = styled.div`
  height: 100%;
  padding-top: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const docsMapGCP = {
  [ProductType.UltimaEnterprise]: 'https://docs.diamanti.com/docs-dvx/3.4.2-gcp/',
  [ProductType.Spektra]: 'https://docs.diamanti.com/docs-spektra/3.5.0-gcp/',
  [ProductType.Groundwork]: 'https://support8.gwos.com/gw/gw8/latest/documentation',
};
const docsMapAWS = {
  [ProductType.UltimaEnterprise]: 'https://docs.diamanti.com/docs-dvx/3.4.2-aws/',
  [ProductType.Spektra]: 'https://docs.diamanti.com/docs-spektra/3.5.1-aws/',
  [ProductType.Groundwork]: 'https://support8.gwos.com/gw/gw8/latest/documentation',
};
const docsMapVSPHERE = {
  [ProductType.UltimaEnterprise]: 'https://docs.diamanti.com/docs-dvx/3.4.2/installguide.html',
  [ProductType.Spektra]: 'undefined',
  [ProductType.Groundwork]: 'undefined',
};
const docsMapBAREMETAL = {
  [ProductType.UltimaEnterprise]: 'https://docs.diamanti.com/docs-dvx/3.4.2/installguide_bm.html',
  [ProductType.Spektra]: 'undefined',
  [ProductType.Groundwork]: 'undefined',
};
const docsMapEKS = {
  [ProductType.UltimaEnterprise]: 'undefined',
  [ProductType.Spektra]:
    'https://docs.diamanti.com/docs-spektra/3.6.0/InstallationGuide.html#setting-up-spektra-on-eks',
  [ProductType.Groundwork]: 'undefined',
};
const docsMapGKE = {
  [ProductType.UltimaEnterprise]: 'undefined',
  [ProductType.Spektra]:
    'https://docs.diamanti.com/docs-spektra/3.6.0/InstallationGuide.html#setting-up-spektra-on-gke',
  [ProductType.Groundwork]: 'undefined',
};

export type InstallationResourcesCardProps = CmpProps<{
  installation: InstallationFragment;
}>;

export const InstallationResourcesCard: FC<InstallationResourcesCardProps> = ({
  className,
  installation,
}) => {
  const [isUninstallModalOpen, openUninstallModal, closeUninstallModal] = useFlagState();
  const isInstall = installation.operation === InstallationOperation.Install;
  const isUninstall = installation.operation === InstallationOperation.Uninstall;
  const isInProgress =
    installation.status === InstallationStatus.InProgress ||
    installation.status === InstallationStatus.FailedInProgress;
  const isDone = installation.status === InstallationStatus.Done;
  const isFailed = installation.status === InstallationStatus.Failed;

  const showUninstall = (isInstall && isDone) || (isUninstall && isFailed);
  const isUninstalling = isUninstall && isInProgress;
  const uninstallingText =
    installation.config.cloudType === Provider.Vsphere ||
    installation.config.cloudType === Provider.Baremetal ||
    installation.config.cloudType === Provider.Gke ||
    installation.config.cloudType === Provider.Eks
      ? 'Deleting'
      : 'Uninstalling';
  const uninstalledText =
    installation.config.cloudType === Provider.Vsphere ||
    installation.config.cloudType === Provider.Baremetal ||
    installation.config.cloudType === Provider.Gke ||
    installation.config.cloudType === Provider.Eks
      ? 'Delete Download Link'
      : 'Uninstall';
  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header title="Resources" />
      <Card.Body>
        <Body>
          <Top>
            <Label text="Documentation">
              {(): CmpReturn => (
                <Button
                  text="Go to docs"
                  href={
                    // eslint-disable-next-line no-nested-ternary
                    installation.config.cloudType === Provider.Aws
                      ? docsMapAWS[installation.product]
                      : // eslint-disable-next-line no-nested-ternary
                      installation.config.cloudType === Provider.Gcp
                      ? docsMapGCP[installation.product]
                      : // eslint-disable-next-line no-nested-ternary
                      installation.config.cloudType === Provider.Vsphere
                      ? docsMapVSPHERE[installation.product]
                      : // eslint-disable-next-line no-nested-ternary
                      installation.config.cloudType === Provider.Eks
                      ? docsMapEKS[installation.product]
                      : // eslint-disable-next-line no-nested-ternary
                      installation.config.cloudType === Provider.Gke
                      ? docsMapGKE[installation.product]
                      : docsMapBAREMETAL[installation.product]
                  }
                  target="_blank"
                  rel="noreferrer"
                  rightIcon="link"
                  type="alternate"
                  spaced
                />
              )}
            </Label>
          </Top>
          {showUninstall && (
            <Button
              type="danger"
              text={isUninstalling ? uninstallingText : uninstalledText}
              onClick={openUninstallModal}
              disabled={isUninstalling}
            />
          )}
        </Body>
      </Card.Body>
      {isUninstallModalOpen && (
        <UnInstallationModal
          closeUninstallModal={closeUninstallModal}
          installation={installation}
          isRemove={false}
        />
      )}
    </Card>
  );
};
