import { FC, useState } from 'react';
import styled from 'styled-components';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { CmpReturn, Label, Typography, Checkbox } from '@diamanticom/picasa-core';
import { FormGroup } from '$cmp/install-modal/common';
import { EulaTerms } from '../common/EulaTerms';
import { ProductType, Provider } from '$gql';
import { CONFIG_CHECKLIST_GKE } from '$utils/lists/installationRequirements';

const TOS = styled(Label)`
  display: flex;
  flex-direction: row-reverse;
`;
const PaddedCheckBox = styled(Checkbox)`
  padding-right: 10px;
`;
const ListElems = styled.div`
  ul {
    list-style-type: disc;
  }
  li {
    margin-top: 1rem;
    margin-left: 1rem;
  }
`;

export type GkeSpektraConfigForm = {
  provider: Provider;
  product: ProductType;
};

export const gkeSpektraConfigFormDefault: GkeSpektraConfigForm = {
  provider: Provider.Gke,
  product: ProductType.Spektra,
};

export type GkeSpektraConfigStepProps = Omit<StepProps<GkeSpektraConfigForm>, 'title' | 'children'>;

export const GkeSpektraConfigStep: FC<GkeSpektraConfigStepProps> = (props) => {
  const [TOSaccepted, setTOSaccepted] = useState(false);
  const onTOSChange = (e: any): void => {
    setTOSaccepted(e.target.checked);
  };
  return (
    <Step
      {...props}
      title="Configuration"
      submitText="Generate Download Link for files"
      submitDisabled={!TOSaccepted}
    >
      <FormGroup style={{ paddingTop: '25px' }} onChange={onTOSChange}>
        <Typography type="h4" as="h2">
          Prerequisites
        </Typography>
        <ListElems>
          <ul>
            {CONFIG_CHECKLIST_GKE.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ul>
        </ListElems>
        <TOS
          text="Terms of Service"
          helperText={
            <span>
              <EulaTerms />
            </span>
          }
          required
        >
          {(id): CmpReturn => (
            <PaddedCheckBox
              id={id}
              name="termsAndConditions"
              rules={{
                required: {
                  value: true,
                  message: 'Please accept terms of service to continue.',
                },
              }}
            />
          )}
        </TOS>
      </FormGroup>
    </Step>
  );
};
