import { FC, useState } from 'react';
import styled from 'styled-components';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { ZoneOption } from '$utils/lists/zones';
import { AWS_NUM_NODES, SelectTypeOption } from '$utils/lists/machineTypes';
import { CmpReturn, Input, Label, Select, Typography, Checkbox } from '@diamanticom/picasa-core';
import { FormGroup } from '$cmp/install-modal/common';
import { EulaTerms } from '../common/EulaTerms';

const Group = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const TOS = styled(Label)`
  display: flex;
  flex-direction: row-reverse;
`;
const PaddedCheckBox = styled(Checkbox)`
  padding-right: 10px;
`;

const AWS_UE_ZONES: ZoneOption[] = [
  { label: 'us-east-1a', value: 'us-east-1a' },
  { label: 'us-east-1b', value: 'us-east-1b' },
  { label: 'us-east-1c', value: 'us-east-1c' },
];

export const AWS_UE_MACHINE_TYPES: SelectTypeOption[] = [
  { label: 'm5d.16xlarge', value: 'm5d.16xlarge' },
  { label: 'm5d.metal', value: 'm5d.metal' },
];

export type AwsUltimaEnterpriseConfigForm = {
  clusterName: string;
  ultimaEnterprisePassword: string;
  ultimaEnterpriseConfirmPassword: string;
  zone: ZoneOption;
  machineType: SelectTypeOption;
  numNodes: SelectTypeOption<number>;
};

export const awsUltimaEnterpriseConfigFormDefault: AwsUltimaEnterpriseConfigForm = {
  clusterName: '',
  ultimaEnterprisePassword: '',
  ultimaEnterpriseConfirmPassword: '',
  zone: AWS_UE_ZONES[0],
  machineType: AWS_UE_MACHINE_TYPES[0],
  numNodes: AWS_NUM_NODES[0],
};

export type AwsUltimaEnterpriseConfigStepProps = Omit<
  StepProps<AwsUltimaEnterpriseConfigForm>,
  'title' | 'children'
>;

export const AwsUltimaEnterpriseConfigStep: FC<AwsUltimaEnterpriseConfigStepProps> = (props) => {
  const [password, setPassword] = useState<string>('');
  return (
    <Step {...props} title="Configuration">
      <FormGroup>
        <Typography type="h4" as="h2">
          Kubernetes
        </Typography>
        <Label text="Cluster Name" required>
          {(id: string): CmpReturn => (
            <Input
              id={id}
              name="clusterName"
              placeholder="Enter cluster name"
              options={{
                required: 'Cluster Name is required',
                pattern: {
                  value: /^[a-z][a-z0-9-]{1,50}[a-z0-9]$/,
                  message:
                    'Cluster Name is invalid.  Cluster Name contains lowercase alphanumeric characters, 3-50 characters long, starting with alphabetic characers, ending with alphanumeric and can include the "-" character',
                },
              }}
            />
          )}
        </Label>
        <Label
          text={`Password for "admin" user of the cluster`}
          required
          helperText="This is the password for the initial admin account in Ultima Enterprise"
        >
          {(id: string): CmpReturn => (
            <Input
              id={id}
              type="password"
              name="ultimaEnterprisePassword"
              placeholder="Enter Password"
              options={{
                required: 'Password is required',
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
                  message:
                    'Password must be a minimum of eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character: @$!%*?&',
                },
                onChange: (event): void => {
                  setPassword(event.target.value);
                },
              }}
            />
          )}
        </Label>
        <Label text="Confirm Password" required>
          {(id: string): CmpReturn => (
            <Input
              id={id}
              type="password"
              name="ultimaEnterpriseConfirmPassword"
              placeholder="Enter Confirm Password"
              options={{
                required: 'Confirm password is required',
                validate: (val): string | boolean | undefined => {
                  try {
                    if (val === password) {
                      return true;
                    }
                    return 'Password do not match';
                  } catch (e) {
                    return 'Password do not match';
                  }
                },
              }}
            />
          )}
        </Label>
      </FormGroup>
      <FormGroup style={{ paddingTop: '25px' }}>
        <Typography type="h4" as="h2">
          Infrastructure
        </Typography>
        <Label text="AWS Zone" required>
          {(id: string): CmpReturn => (
            <Select
              id={id}
              name="zone"
              placeholder="Select zone"
              rules={{ required: 'Zone is required' }}
              options={AWS_UE_ZONES}
            />
          )}
        </Label>
        <Group>
          <Label text="Machine Type" required>
            {(id: string): CmpReturn => (
              <Select
                id={id}
                name="machineType"
                placeholder="Select machine type"
                rules={{ required: 'Machine type is required' }}
                options={AWS_UE_MACHINE_TYPES}
              />
            )}
          </Label>
          {/* <Checkbox name="preemptible" text="Use preemptible instances" /> */}
        </Group>
        <Label text="Number of Nodes">
          {(id: string): CmpReturn => (
            <Select
              id={id}
              name="numNodes"
              placeholder="Select number of nodes"
              options={AWS_NUM_NODES}
            />
          )}
        </Label>
        <TOS
          text="Terms of Service"
          helperText={
            <span>
              <EulaTerms />
            </span>
          }
          required
        >
          {(id): CmpReturn => (
            <PaddedCheckBox
              id={id}
              name="termsAndConditions"
              rules={{
                required: {
                  value: true,
                  message: 'Please accept terms of service to continue.',
                },
              }}
            />
          )}
        </TOS>
      </FormGroup>
    </Step>
  );
};
