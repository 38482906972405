import { FC, useState } from 'react';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { ZoneOption } from '$utils/lists/zones';
import { CmpReturn, Input, Label, Select, Typography } from '@diamanticom/picasa-core';
import { FormGroup } from '$cmp/install-modal/common';

const AWS_SPEKTRA_ZONES: ZoneOption[] = [
  { label: 'us-east-2a', value: 'us-east-2a' },
  { label: 'us-east-2b', value: 'us-east-2b' },
  { label: 'us-east-2c', value: 'us-east-2c' },
];

export type AwsSpektraConfigForm = {
  name: string;
  spektraPassword: string;
  spektraConfirmPassword: string;
  zone: ZoneOption;
};

export const awsSpektraConfigFormDefault: AwsSpektraConfigForm = {
  name: '',
  spektraPassword: '',
  spektraConfirmPassword: '',
  zone: AWS_SPEKTRA_ZONES[0],
};

export type AwsSpektraConfigStepProps = Omit<StepProps<AwsSpektraConfigForm>, 'title' | 'children'>;

export const AwsSpektraConfigStep: FC<AwsSpektraConfigStepProps> = (props) => {
  const [password, setPassword] = useState<string>('');
  return (
    <Step {...props} title="Configuration">
      <FormGroup>
        <Typography type="h4" as="h2">
          Kubernetes
        </Typography>
        <Label
          text="Name"
          required
          helperText="This name will be used as a prefix for all created resources"
        >
          {(id: string): CmpReturn => (
            <Input
              id={id}
              name="name"
              placeholder="Enter name"
              options={{
                required: 'Name is required',
                pattern: {
                  value: /^[a-z][a-z0-9-]{1,50}[a-z0-9]$/,
                  message:
                    'Name is invalid.  Name must contain lowercase alphanumeric characters, 3-50 characters long, starting with alphabetic characers, ending with alphanumeric and can include the "-" character',
                },
              }}
            />
          )}
        </Label>
        <Label
          text={`Password for "admin" user`}
          required
          helperText="This is the password for the initial admin account in Spektra"
        >
          {(id: string): CmpReturn => (
            <Input
              id={id}
              type="password"
              name="spektraPassword"
              placeholder="Enter password"
              options={{
                required: 'Password is required',
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
                  message:
                    'Password must be a minimum of eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character: @$!%*?&',
                },
                onChange: (event): void => {
                  setPassword(event.target.value);
                },
              }}
            />
          )}
        </Label>
        <Label text="Confirm Password" required>
          {(id: string): CmpReturn => (
            <Input
              id={id}
              type="password"
              name="spektraConfirmPassword"
              placeholder="Enter Confirm Password"
              options={{
                required: 'Confirm password is required',
                validate: (val): string | boolean | undefined => {
                  try {
                    if (val === password) {
                      return true;
                    }
                    return 'Password do not match';
                  } catch (e) {
                    return 'Password do not match';
                  }
                },
              }}
            />
          )}
        </Label>
      </FormGroup>
      <FormGroup style={{ paddingTop: '25px' }}>
        <Typography type="h4" as="h2">
          Infrastructure
        </Typography>
        <Label text="AWS Zone" required>
          {(id: string): CmpReturn => (
            <Select
              id={id}
              name="zone"
              placeholder="Select zone"
              rules={{ required: 'Zone is required' }}
              options={AWS_SPEKTRA_ZONES}
            />
          )}
        </Label>
      </FormGroup>
    </Step>
  );
};
