import { FC, useState } from 'react';
import styled from 'styled-components';
import { CmpReturn, Input, Label, RadioButtonGroup, Typography } from '@diamanticom/picasa-core';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { FormGroup } from '$cmp/install-modal/common';
import { InstallType } from '$utils/enums';

const HelpTextLink = styled.a`
  color: ${(props): string => props.theme.palette.link};
  margin-left: 2px;
`;

const CenteredDescription = styled(Typography)`
  height: 348px;
  padding-top: 16px;
  text-align: center;
`;

export type AwsCredentialForm = {
  accessKeyId: string;
  secretAccessKey: string;
};

export const awsCredentialFormDefault: AwsCredentialForm = {
  accessKeyId: '',
  secretAccessKey: '',
};

export type AwsCredentialStepProps = Omit<
  StepProps<AwsCredentialForm>,
  'title' | 'children' | 'submitDisabled'
>;

export const AwsCredentialStep: FC<AwsCredentialStepProps> = (props) => {
  const [installationType, setInstallationType] = useState<string>(InstallType.Automated);
  return (
    <Step {...props} title="Install Type" submitDisabled={installationType === InstallType.Manual}>
      <FormGroup>
        <RadioButtonGroup
          defaultValue={installationType}
          id="installType"
          options={[InstallType.Automated, InstallType.Manual]}
          onChange={setInstallationType}
        />
        {installationType === InstallType.Automated && (
          <>
            <Label
              text="AWS Access Key ID"
              required
              helperText={
                <>
                  . For more help see:
                  <HelpTextLink
                    target="_blank"
                    href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html"
                  >
                    Managing access keys
                  </HelpTextLink>
                </>
              }
            >
              {(id: string): CmpReturn => (
                <Input
                  id={id}
                  name="accessKeyId"
                  placeholder="Enter AWS Access Key ID"
                  options={{ required: 'Access Key ID is required' }}
                />
              )}
            </Label>
            <Label text="AWS Secret Access Key" required>
              {(id: string): CmpReturn => (
                <Input
                  id={id}
                  name="secretAccessKey"
                  placeholder="Enter AWS Secret Access Key"
                  options={{ required: 'Secret Access Key is required' }}
                  type="password"
                />
              )}
            </Label>
          </>
        )}
        {installationType === InstallType.Manual && (
          <CenteredDescription type="body" as="p">
            In the future we will provide a manual install utility for installing locally. We will
            send out an email when it&apos;s available.
          </CenteredDescription>
        )}
      </FormGroup>
    </Step>
  );
};
