export type SalesPersonaOption = { label: string; value: string };
export const SALES_PERSONAS: SalesPersonaOption[] = [
  {
    label: 'Architecture & Infrastructure Platforms',
    value: 'Architecture & Infrastructure Platforms',
  },
  {
    label: 'Data Centre Engineering Analytics',
    value: 'Data Centre Engineering Analytics',
  },
  {
    label: 'Cloud(Include Amazon,Azure,AWS,GCP)',
    value: 'Cloud(Include Amazon,Azure,AWS,GCP)',
  },
  {
    label: 'Data Centre Engineering Networking',
    value: 'Data Centre Engineering Networking',
  },
  {
    label: 'Data Centre Engineering Security',
    value: 'Data Centre Engineering Security',
  },
  {
    label: 'Data Centre Engineering Storage',
    value: 'Data Centre Engineering Storage',
  },
  {
    label: 'Data Centre IT Management',
    value: 'Data Centre IT Management',
  },
  {
    label: 'Data Centre -Kubernetes',
    value: 'Data Centre -Kubernetes',
  },
  {
    label: 'Dev Ops (Development Operations)',
    value: 'Dev Ops (Development Operations)',
  },
  {
    label: 'Digital Transformation',
    value: 'Digital Transformation',
  },
  {
    label: 'Executive Management (CEO,CIO,CTO,COO,President etc)',
    value: 'Executive Management (CEO,CIO,CTO,COO,President etc)',
  },
  {
    label: 'Operations/Program Management',
    value: 'Operations/Program Management',
  },
  {
    label: 'Partner',
    value: 'Partner',
  },
  {
    label: 'Platform/Management/Orchestration',
    value: 'Platform/Management/Orchestration',
  },
  {
    label: 'Principal/Technology Consultant',
    value: 'Principal/Technology Consultant',
  },
  {
    label: 'Procurement & Finance',
    value: 'Procurement & Finance',
  },
  {
    label: 'Site Reliablity Engineer',
    value: 'Site Reliablity Engineer',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];
