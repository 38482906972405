import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  CardModal,
  CmpProps,
  CmpReturn,
  Form,
  Input,
  Label,
  SubmitButton,
  Typography,
} from '@diamanticom/picasa-core';

import {
  Provider,
  InstallationFragment,
  useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation,
  InstallUltimaEnterpriseInput,
} from '$gql';

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export type AddLicenseModalProps = CmpProps<{
  closeAddLicenseModal: () => void;
  installation: InstallationFragment;
  provider: Provider;
}>;
export const AddLicenseModal: FC<AddLicenseModalProps> = ({
  closeAddLicenseModal,
  installation,
  provider,
}) => {
  const header = 'Add License for Node';
  const [updateUltimaEnterpriseInstallationVMBMLicenses] =
    useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation();
  const [buttonText, setButtonText] = useState<string>('Add License');
  const [errorText, setErrorText] = useState<string>('');
  const onSubmitCredentials = useCallback(
    async (form: any): Promise<void> => {
      const input: InstallUltimaEnterpriseInput = {
        cloudType: provider,
        [provider]: {
          s3DownloadLink: '',
        },
      };
      setButtonText('Adding license...');
      setErrorText('');
      try {
        await updateUltimaEnterpriseInstallationVMBMLicenses({
          variables: {
            id: installation.id,
            input,
            machineID: form.machineID,
          },
        });
        setButtonText('Add License');
        closeAddLicenseModal();
      } catch (e) {
        setButtonText('Add License');
        setErrorText(`${(e as Error).message}`);
      }
    },
    [closeAddLicenseModal]
  );
  return (
    <CardModal header={header} onClose={closeAddLicenseModal}>
      {/* <Typography type="body">{headerMessage}</Typography> */}
      <Form onSubmit={onSubmitCredentials}>
        <FormBody>
          <Label text="Machine ID" required>
            {(id: string): CmpReturn => (
              <Input
                id={id}
                name="machineID"
                placeholder="Enter Machine ID"
                options={{ required: 'Machine ID is required' }}
              />
            )}
          </Label>
          {errorText && (
            <Typography
              type="h4"
              style={{ color: 'red', fontSize: '1rem' }}
            >{`Error: ${errorText}`}</Typography>
          )}

          <Actions>
            <SubmitButton type="primary" text={buttonText} />
          </Actions>
        </FormBody>
      </Form>
    </CardModal>
  );
};
