import { FC, useState } from 'react';
import styled from 'styled-components';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { CmpReturn, Label, Typography, Checkbox, Table } from '@diamanticom/picasa-core';
import { FormGroup } from '$cmp/install-modal/common';
import { EulaTerms } from '../common/EulaTerms';
import { createColumnHelper } from '@tanstack/react-table';
import { ProductType, Provider } from '$gql';
import { MinimumVMRequirements, MINIMUM_VM_VSPHERE } from '$utils/lists/installationRequirements';

const TOS = styled(Label)`
  display: flex;
  flex-direction: row-reverse;
`;
const PaddedCheckBox = styled(Checkbox)`
  padding-right: 10px;
`;

export type VsphereUltimaEnterpriseConfigForm = {
  provider: Provider;
  product: ProductType;
};

export const vsphereUltimaEnterpriseConfigFormDefault: VsphereUltimaEnterpriseConfigForm = {
  provider: Provider.Vsphere,
  product: ProductType.UltimaEnterprise,
};

export type VsphereUltimaEnterpriseConfigStepProps = Omit<
  StepProps<VsphereUltimaEnterpriseConfigForm>,
  'title' | 'children'
>;

const columnHelper = createColumnHelper<MinimumVMRequirements>();

const columns = [
  columnHelper.accessor('resource', {
    header: () => <Typography type="pBold">Resource</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 80,
  }),
  columnHelper.accessor('minimum', {
    header: () => <Typography type="pBold">Minimum</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 80,
  }),
];

export const VsphereUltimaEnterpriseConfigStep: FC<VsphereUltimaEnterpriseConfigStepProps> = (
  props
) => {
  const [TOSaccepted, setTOSaccepted] = useState(false);
  const onTOSChange = (e: any): void => {
    setTOSaccepted(e.target.checked);
  };
  return (
    <Step
      {...props}
      title="Configuration"
      submitText="Generate dvx-3.4.2-108.ova Download Link"
      submitDisabled={!TOSaccepted}
    >
      <FormGroup style={{ paddingTop: '25px' }} onChange={onTOSChange}>
        <Typography type="h4" as="h2">
          Minimum VM Resource Requirements
        </Typography>
        <Table columns={columns} data={MINIMUM_VM_VSPHERE} />
        <TOS
          text="Terms of Service"
          helperText={
            <span>
              <EulaTerms />
            </span>
          }
          required
        >
          {(id): CmpReturn => (
            <PaddedCheckBox
              id={id}
              name="termsAndConditions"
              rules={{
                required: {
                  value: true,
                  message: 'Please accept terms of service to continue.',
                },
              }}
            />
          )}
        </TOS>
      </FormGroup>
    </Step>
  );
};
