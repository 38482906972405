import qs from 'qs';
import { differenceInCalendarDays } from 'date-fns';

export const parseQS = <T extends Record<string, unknown>>(search: string): T => {
  const filtered = decodeURIComponent(search[0] === '?' ? search.slice(1) : search);

  if (filtered === '') return {} as T;

  return qs.parse(filtered) as T;
};
export const stringifyQS = <T extends Record<string, unknown> | void>(obj: Partial<T>): string => {
  if (!obj) return '';

  return qs.stringify(obj);
};

export const getTrialCounts = (
  start: string,
  end: string
): { total: number; remaining: number } => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date();

  return {
    total: differenceInCalendarDays(endDate, startDate),
    remaining: Math.max(differenceInCalendarDays(endDate, now), 0),
  };
};

export const downloadObjectAsJson = (exportObj: any, exportName: string): void => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportObj))}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const downloadObjectAsText = (exportObj: string, exportName: string): void => {
  const dataStr = `data:text/plain;charset=utf-8,${encodeURIComponent(exportObj)}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${exportName}`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};
