import { Provider } from '$gql';

export enum GQLErrorType {
  Unauthenticated = 'UNAUTHENTICATED',
}

export enum InstallType {
  Automated = 'Automated',
  Manual = 'Manual',
}

export const ProviderName: Record<Provider, string> = {
  [Provider.Gcp]: 'Google Cloud Platform',
  [Provider.Aws]: 'Amazon Web Services',
  [Provider.Vsphere]: 'Vsphere',
  [Provider.Baremetal]: 'Baremetal',
  [Provider.Gke]: 'GKE',
  [Provider.Eks]: 'EKS',
};
