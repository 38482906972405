import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AwsCredentialsInput = {
  accessKeyId: Scalars['String'];
  secretAccessKey: Scalars['String'];
};

export type GroundworkAwsConfigInput = {
  credentials: AwsCredentialsInput;
  groundworkPassword: Scalars['String'];
  imageName: Scalars['String'];
  name: Scalars['String'];
  zone: Scalars['String'];
};

/** Groundwork Installation Config */
export type GroundworkAwsInstallationConfig = {
  __typename: 'GroundworkAWSInstallationConfig';
  id: Scalars['ID'];
  imageName: Scalars['String'];
  name: Scalars['String'];
  zone: Scalars['String'];
};

export type GroundworkGcpConfigInput = {
  groundworkPassword: Scalars['String'];
  imageName: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  serviceAccount: Scalars['String'];
  zone: Scalars['String'];
};

/** Groundwork Installation Config */
export type GroundworkGcpInstallationConfig = {
  __typename: 'GroundworkGCPInstallationConfig';
  id: Scalars['ID'];
  imageName: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  zone: Scalars['String'];
};

/** Groundwork Installation */
export type GroundworkInstallation = {
  __typename: 'GroundworkInstallation';
  config: GroundworkInstallationConfig;
  /** When the installation is created */
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Last reported progress message from install failure cleanup */
  installFailedProgressMessage?: Maybe<Scalars['String']>;
  /** Reason for install failure */
  installFailureMessage?: Maybe<Scalars['String']>;
  /** Last reported progress message from install operation */
  installProgressMessage?: Maybe<Scalars['String']>;
  /** When the install succeeded */
  installSuccessDate?: Maybe<Scalars['DateTime']>;
  /** Last reported operation */
  operation: InstallationOperation;
  product: ProductType;
  /** A value from 0 to 1 that represents the installation progress percentage */
  progress: Scalars['Float'];
  result?: Maybe<InstallationResult>;
  /** Last reported status */
  status: InstallationStatus;
  /** When the installation is set to expire */
  trialExpirationDate?: Maybe<Scalars['DateTime']>;
  /** Reason for uninstall failure */
  uninstallFailureMessage?: Maybe<Scalars['String']>;
  /** Last reported progress message from uninstall operation */
  uninstallProgressMessage?: Maybe<Scalars['String']>;
  /** When the uninstall succeeded */
  uninstallSuccessDate?: Maybe<Scalars['DateTime']>;
  /** When the installation is updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** User that started the installation */
  userId: Scalars['ID'];
};

/** Groundwork Installation Config */
export type GroundworkInstallationConfig = {
  __typename: 'GroundworkInstallationConfig';
  AWS?: Maybe<GroundworkAwsInstallationConfig>;
  GCP?: Maybe<GroundworkGcpInstallationConfig>;
  /** Cloud provider */
  cloudType: Provider;
  id: Scalars['ID'];
};

export type InstallGroundworkInput = {
  AWS?: InputMaybe<GroundworkAwsConfigInput>;
  GCP?: InputMaybe<GroundworkGcpConfigInput>;
  cloudType: Provider;
};

export type InstallSpektraInput = {
  AWS?: InputMaybe<SpektraAwsConfigInput>;
  EKS?: InputMaybe<SpektraEksConfigInput>;
  GCP?: InputMaybe<SpektraGcpConfigInput>;
  GKE?: InputMaybe<SpektraGkeConfigInput>;
  cloudType: Provider;
};

export type InstallUltimaEnterpriseInput = {
  AWS?: InputMaybe<UltimaEnterpriseAwsConfigInput>;
  BAREMETAL?: InputMaybe<UltimaEnterpriseBaremetalConfigInput>;
  GCP?: InputMaybe<UltimaEnterpriseGcpConfigInput>;
  VSPHERE?: InputMaybe<UltimaEnterpriseVsphereConfigInput>;
  cloudType: Provider;
};

export type Installation =
  | GroundworkInstallation
  | SpektraInstallation
  | UltimaEnterpriseInstallation;

/** The result of installation download url */
export type InstallationDownloadLinkResult = {
  __typename: 'InstallationDownloadLinkResult';
  id: Scalars['ID'];
  s3DownloadLink?: Maybe<Scalars['String']>;
};

/** The result of installation download url */
export type InstallationDownloadUrl = {
  __typename: 'InstallationDownloadUrl';
  downloadUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** The result of installing a node */
export type InstallationNodeResult = {
  __typename: 'InstallationNodeResult';
  externalIP?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalIP?: Maybe<Scalars['String']>;
  licenseExpirationDate?: Maybe<Scalars['DateTime']>;
  licenseFile?: Maybe<LicenseFile>;
  machineID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Represents the type of installation operations */
export enum InstallationOperation {
  FailureCleanup = 'FailureCleanup',
  Install = 'Install',
  Uninstall = 'Uninstall',
}

/** The result of installing an app */
export type InstallationResult = {
  __typename: 'InstallationResult';
  downloadLinkResult?: Maybe<InstallationDownloadLinkResult>;
  id: Scalars['ID'];
  nodes: Array<InstallationNodeResult>;
  publicAppIp?: Maybe<Scalars['String']>;
  publicAppUrl?: Maybe<Scalars['String']>;
};

/** Status that represents the state of an installation */
export enum InstallationStatus {
  Done = 'Done',
  Failed = 'Failed',
  FailedInProgress = 'FailedInProgress',
  InProgress = 'InProgress',
}

/** The result of installing a node */
export type LicenseFile = {
  __typename: 'LicenseFile';
  content?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename: 'Mutation';
  deleteSpektraInstallation: Scalars['String'];
  deleteUltimaEnterpriseInstallation: Scalars['String'];
  installGroundwork: GroundworkInstallation;
  installSpektra: SpektraInstallation;
  installUltimaEnterprise: UltimaEnterpriseInstallation;
  uninstallGroundwork: GroundworkInstallation;
  uninstallSpektra: SpektraInstallation;
  uninstallUltimaEnterprise: UltimaEnterpriseInstallation;
  updateSpektraInstallation: SpektraInstallation;
  updateUltimaEnterpriseInstallation: UltimaEnterpriseInstallation;
  updateUltimaEnterpriseInstallationVMBMLicenses: UltimaEnterpriseInstallation;
  updateUserProfile: UserProfile;
};

export type MutationDeleteSpektraInstallationArgs = {
  id: Scalars['String'];
};

export type MutationDeleteUltimaEnterpriseInstallationArgs = {
  id: Scalars['String'];
};

export type MutationInstallGroundworkArgs = {
  input: InstallGroundworkInput;
};

export type MutationInstallSpektraArgs = {
  input: InstallSpektraInput;
};

export type MutationInstallUltimaEnterpriseArgs = {
  input: InstallUltimaEnterpriseInput;
};

export type MutationUninstallGroundworkArgs = {
  id: Scalars['String'];
  input: UninstallGroundworkInput;
};

export type MutationUninstallSpektraArgs = {
  id: Scalars['String'];
  input: UninstallSpektraInput;
  onlyRemoveFromDatabase: Scalars['Boolean'];
};

export type MutationUninstallUltimaEnterpriseArgs = {
  id: Scalars['String'];
  input: UninstallUltimaEnterpriseInput;
  onlyRemoveFromDatabase: Scalars['Boolean'];
};

export type MutationUpdateSpektraInstallationArgs = {
  id: Scalars['String'];
  input: InstallSpektraInput;
};

export type MutationUpdateUltimaEnterpriseInstallationArgs = {
  id: Scalars['String'];
  input: InstallUltimaEnterpriseInput;
};

export type MutationUpdateUltimaEnterpriseInstallationVmbmLicensesArgs = {
  id: Scalars['String'];
  input: InstallUltimaEnterpriseInput;
  machineID: Scalars['String'];
};

export type MutationUpdateUserProfileArgs = {
  input: UserProfileInput;
};

/** A product that we offer */
export enum ProductType {
  Groundwork = 'Groundwork',
  Spektra = 'Spektra',
  UltimaEnterprise = 'UltimaEnterprise',
}

/** Cloud Providers */
export enum Provider {
  Aws = 'AWS',
  Baremetal = 'BAREMETAL',
  Eks = 'EKS',
  Gcp = 'GCP',
  Gke = 'GKE',
  Vsphere = 'VSPHERE',
}

export type Query = {
  __typename: 'Query';
  allInstallations: Array<Installation>;
  canInstallGroundwork: Scalars['Boolean'];
  canInstallGroundworkOnProvider: Scalars['Boolean'];
  canInstallSpektraOnProvider: Scalars['Boolean'];
  canInstallUltimaEnterprise: Scalars['Boolean'];
  canInstallUltimaEnterpriseOnProvider: Scalars['Boolean'];
  getUsers: Array<UserProfileResponse>;
  groundworkInstallation: GroundworkInstallation;
  groundworkInstallations: Array<GroundworkInstallation>;
  installation: Installation;
  installations: Array<Installation>;
  isAdminUser: Scalars['Boolean'];
  s3DownloadUrl: InstallationDownloadUrl;
  spektraInstallation: SpektraInstallation;
  spektraInstallations: Array<SpektraInstallation>;
  ultimaEnterpriseInstallation: UltimaEnterpriseInstallation;
  ultimaEnterpriseInstallations: Array<UltimaEnterpriseInstallation>;
  userProfile?: Maybe<UserProfile>;
};

export type QueryCanInstallGroundworkOnProviderArgs = {
  provider: Scalars['String'];
};

export type QueryCanInstallSpektraOnProviderArgs = {
  provider: Scalars['String'];
};

export type QueryCanInstallUltimaEnterpriseOnProviderArgs = {
  provider: Scalars['String'];
};

export type QueryGroundworkInstallationArgs = {
  id: Scalars['ID'];
};

export type QueryInstallationArgs = {
  id: Scalars['ID'];
};

export type QueryS3DownloadUrlArgs = {
  productType: ProductType;
  provider: Provider;
};

export type QuerySpektraInstallationArgs = {
  id: Scalars['ID'];
};

export type QueryUltimaEnterpriseInstallationArgs = {
  id: Scalars['ID'];
};

export type SpektraAwsConfigInput = {
  credentials: AwsCredentialsInput;
  imageName: Scalars['String'];
  name: Scalars['String'];
  spektraPassword: Scalars['String'];
  zone: Scalars['String'];
};

/** Spektra Installation Config */
export type SpektraAwsInstallationConfig = {
  __typename: 'SpektraAWSInstallationConfig';
  id: Scalars['ID'];
  imageName: Scalars['String'];
  name: Scalars['String'];
  zone: Scalars['String'];
};

export type SpektraEksConfigInput = {
  s3DownloadLink: Scalars['String'];
};

/** Spektra EKS Installation Config */
export type SpektraEksInstallationConfig = {
  __typename: 'SpektraEKSInstallationConfig';
  id: Scalars['ID'];
  s3DownloadLink: Scalars['String'];
};

export type SpektraGcpConfigInput = {
  imageName: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  serviceAccount: Scalars['String'];
  spektraPassword: Scalars['String'];
  zone: Scalars['String'];
};

/** Spektra Installation Config */
export type SpektraGcpInstallationConfig = {
  __typename: 'SpektraGCPInstallationConfig';
  id: Scalars['ID'];
  imageName: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  zone: Scalars['String'];
};

export type SpektraGkeConfigInput = {
  s3DownloadLink: Scalars['String'];
};

/** Spektra GKE Installation Config */
export type SpektraGkeInstallationConfig = {
  __typename: 'SpektraGKEInstallationConfig';
  id: Scalars['ID'];
  s3DownloadLink: Scalars['String'];
};

/** Spektra Installation */
export type SpektraInstallation = {
  __typename: 'SpektraInstallation';
  config: SpektraInstallationConfig;
  /** When the installation is created */
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Last reported progress message from install failure cleanup */
  installFailedProgressMessage?: Maybe<Scalars['String']>;
  /** Reason for install failure */
  installFailureMessage?: Maybe<Scalars['String']>;
  /** Last reported progress message from install operation */
  installProgressMessage?: Maybe<Scalars['String']>;
  /** When the install succeeded */
  installSuccessDate?: Maybe<Scalars['DateTime']>;
  /** Last reported operation */
  operation: InstallationOperation;
  product: ProductType;
  /** A value from 0 to 1 that represents the installation progress percentage */
  progress: Scalars['Float'];
  result?: Maybe<InstallationResult>;
  /** Last reported status */
  status: InstallationStatus;
  /** When the installation is set to expire */
  trialExpirationDate?: Maybe<Scalars['DateTime']>;
  /** Reason for uninstall failure */
  uninstallFailureMessage?: Maybe<Scalars['String']>;
  /** Last reported progress message from uninstall operation */
  uninstallProgressMessage?: Maybe<Scalars['String']>;
  /** When the uninstall succeeded */
  uninstallSuccessDate?: Maybe<Scalars['DateTime']>;
  /** When the installation is updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** User that started the installation */
  userId: Scalars['ID'];
};

/** Spektra Installation Config */
export type SpektraInstallationConfig = {
  __typename: 'SpektraInstallationConfig';
  AWS?: Maybe<SpektraAwsInstallationConfig>;
  EKS?: Maybe<SpektraEksInstallationConfig>;
  GCP?: Maybe<SpektraGcpInstallationConfig>;
  GKE?: Maybe<SpektraGkeInstallationConfig>;
  /** Cloud provider */
  cloudType: Provider;
  id: Scalars['ID'];
};

export type Subscription = {
  __typename: 'Subscription';
  installProgress: Installation;
};

export type UltimaEnterpriseAwsConfigInput = {
  clusterName: Scalars['String'];
  credentials: AwsCredentialsInput;
  imageName: Scalars['String'];
  machineType: Scalars['String'];
  numNodes: Scalars['Float'];
  ultimaEnterprisePassword: Scalars['String'];
  zone: Scalars['String'];
};

/** Ultima Enterprise AWS Installation Config */
export type UltimaEnterpriseAwsInstallationConfig = {
  __typename: 'UltimaEnterpriseAWSInstallationConfig';
  clusterName: Scalars['String'];
  id: Scalars['ID'];
  imageName: Scalars['String'];
  machineType: Scalars['String'];
  numNodes: Scalars['Float'];
  zone: Scalars['String'];
};

export type UltimaEnterpriseBaremetalConfigInput = {
  s3DownloadLink: Scalars['String'];
};

/** Ultima Enterprise Baremetal Installation Config */
export type UltimaEnterpriseBaremetalInstallationConfig = {
  __typename: 'UltimaEnterpriseBAREMETALInstallationConfig';
  id: Scalars['ID'];
  s3DownloadLink: Scalars['String'];
};

export type UltimaEnterpriseGcpConfigInput = {
  clusterName: Scalars['String'];
  driveType: Scalars['String'];
  imageName: Scalars['String'];
  instanceUsername: Scalars['String'];
  machineType: Scalars['String'];
  numDrives: Scalars['Float'];
  numNodes: Scalars['Float'];
  preemptible: Scalars['Boolean'];
  projectId: Scalars['String'];
  serviceAccount: Scalars['String'];
  ultimaEnterprisePassword: Scalars['String'];
  zone: Scalars['String'];
};

/** Ultima Enterprise GCP Installation Config */
export type UltimaEnterpriseGcpInstallationConfig = {
  __typename: 'UltimaEnterpriseGCPInstallationConfig';
  clusterName: Scalars['String'];
  driveType: Scalars['String'];
  id: Scalars['ID'];
  imageName: Scalars['String'];
  instanceUsername: Scalars['String'];
  machineType: Scalars['String'];
  numDrives: Scalars['Float'];
  numNodes: Scalars['Float'];
  preemptible?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['String'];
  zone: Scalars['String'];
};

/** Ultima Enterprise Installation */
export type UltimaEnterpriseInstallation = {
  __typename: 'UltimaEnterpriseInstallation';
  config: UltimaEnterpriseInstallationConfig;
  /** When the installation is created */
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Last reported progress message from install failure cleanup */
  installFailedProgressMessage?: Maybe<Scalars['String']>;
  /** Reason for install failure */
  installFailureMessage?: Maybe<Scalars['String']>;
  /** Last reported progress message from install operation */
  installProgressMessage?: Maybe<Scalars['String']>;
  /** When the install succeeded */
  installSuccessDate?: Maybe<Scalars['DateTime']>;
  /** Last reported operation */
  operation: InstallationOperation;
  product: ProductType;
  /** A value from 0 to 1 that represents the installation progress percentage */
  progress: Scalars['Float'];
  result?: Maybe<InstallationResult>;
  /** Last reported status */
  status: InstallationStatus;
  /** When the installation is set to expire */
  trialExpirationDate?: Maybe<Scalars['DateTime']>;
  /** Reason for uninstall failure */
  uninstallFailureMessage?: Maybe<Scalars['String']>;
  /** Last reported progress message from uninstall operation */
  uninstallProgressMessage?: Maybe<Scalars['String']>;
  /** When the uninstall succeeded */
  uninstallSuccessDate?: Maybe<Scalars['DateTime']>;
  /** When the installation is updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** User that started the installation */
  userId: Scalars['ID'];
};

/** Ultima Enterprise Installation Config */
export type UltimaEnterpriseInstallationConfig = {
  __typename: 'UltimaEnterpriseInstallationConfig';
  AWS?: Maybe<UltimaEnterpriseAwsInstallationConfig>;
  BAREMETAL?: Maybe<UltimaEnterpriseBaremetalInstallationConfig>;
  GCP?: Maybe<UltimaEnterpriseGcpInstallationConfig>;
  VSPHERE?: Maybe<UltimaEnterpriseVsphereInstallationConfig>;
  /** Cloud provider */
  cloudType: Provider;
  id: Scalars['ID'];
};

export type UltimaEnterpriseVsphereConfigInput = {
  s3DownloadLink: Scalars['String'];
};

/** Ultima Enterprise Vsphere Installation Config */
export type UltimaEnterpriseVsphereInstallationConfig = {
  __typename: 'UltimaEnterpriseVSPHEREInstallationConfig';
  id: Scalars['ID'];
  s3DownloadLink: Scalars['String'];
};

export type UninstallGroundworkInput = {
  credentials?: InputMaybe<AwsCredentialsInput>;
  serviceAccount?: InputMaybe<Scalars['String']>;
};

export type UninstallSpektraInput = {
  credentials?: InputMaybe<AwsCredentialsInput>;
  serviceAccount?: InputMaybe<Scalars['String']>;
};

export type UninstallUltimaEnterpriseInput = {
  credentials?: InputMaybe<AwsCredentialsInput>;
  serviceAccount?: InputMaybe<Scalars['String']>;
};

export type UserProfile = {
  __typename: 'UserProfile';
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  industry: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  termsAndConditions?: Maybe<Scalars['Boolean']>;
};

export type UserProfileInput = {
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  industry: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  termsAndConditions?: InputMaybe<Scalars['Boolean']>;
};

export type UserProfileResponse = {
  __typename: 'UserProfileResponse';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industry?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['Boolean']>;
};

export type UserProfileResponseFragment = {
  __typename: 'UserProfileResponse';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  jobTitle?: string | null;
  phone?: string | null;
  company?: string | null;
  industry?: string | null;
};

export type GroundworkInstallationConfigFragment = {
  __typename: 'GroundworkInstallationConfig';
  id: string;
  cloudType: Provider;
  GCP?: {
    __typename: 'GroundworkGCPInstallationConfig';
    name: string;
    imageName: string;
    projectId: string;
    zone: string;
  } | null;
  AWS?: {
    __typename: 'GroundworkAWSInstallationConfig';
    name: string;
    imageName: string;
    zone: string;
  } | null;
};

export type GroundworkInstallationFragment = {
  __typename: 'GroundworkInstallation';
  id: string;
  userId: string;
  operation: InstallationOperation;
  product: ProductType;
  progress: number;
  status: InstallationStatus;
  installProgressMessage?: string | null;
  uninstallProgressMessage?: string | null;
  installFailedProgressMessage?: string | null;
  installFailureMessage?: string | null;
  uninstallFailureMessage?: string | null;
  installSuccessDate?: any | null;
  uninstallSuccessDate?: any | null;
  createdAt?: any | null;
  trialExpirationDate?: any | null;
  config: {
    __typename: 'GroundworkInstallationConfig';
    id: string;
    cloudType: Provider;
    GCP?: {
      __typename: 'GroundworkGCPInstallationConfig';
      name: string;
      imageName: string;
      projectId: string;
      zone: string;
    } | null;
    AWS?: {
      __typename: 'GroundworkAWSInstallationConfig';
      name: string;
      imageName: string;
      zone: string;
    } | null;
  };
  result?: {
    __typename: 'InstallationResult';
    id: string;
    publicAppIp?: string | null;
    publicAppUrl?: string | null;
    nodes: Array<{
      __typename: 'InstallationNodeResult';
      id: string;
      name?: string | null;
      externalIP?: string | null;
      internalIP?: string | null;
      licenseExpirationDate?: any | null;
      machineID?: string | null;
      licenseFile?: {
        __typename: 'LicenseFile';
        content?: string | null;
        filename?: string | null;
      } | null;
    }>;
    downloadLinkResult?: {
      __typename: 'InstallationDownloadLinkResult';
      s3DownloadLink?: string | null;
    } | null;
  } | null;
};

export type Installation_GroundworkInstallation_Fragment = {
  __typename: 'GroundworkInstallation';
  id: string;
  userId: string;
  operation: InstallationOperation;
  product: ProductType;
  progress: number;
  status: InstallationStatus;
  installProgressMessage?: string | null;
  uninstallProgressMessage?: string | null;
  installFailedProgressMessage?: string | null;
  installFailureMessage?: string | null;
  uninstallFailureMessage?: string | null;
  installSuccessDate?: any | null;
  uninstallSuccessDate?: any | null;
  createdAt?: any | null;
  trialExpirationDate?: any | null;
  config: {
    __typename: 'GroundworkInstallationConfig';
    id: string;
    cloudType: Provider;
    GCP?: {
      __typename: 'GroundworkGCPInstallationConfig';
      name: string;
      imageName: string;
      projectId: string;
      zone: string;
    } | null;
    AWS?: {
      __typename: 'GroundworkAWSInstallationConfig';
      name: string;
      imageName: string;
      zone: string;
    } | null;
  };
  result?: {
    __typename: 'InstallationResult';
    id: string;
    publicAppIp?: string | null;
    publicAppUrl?: string | null;
    nodes: Array<{
      __typename: 'InstallationNodeResult';
      id: string;
      name?: string | null;
      externalIP?: string | null;
      internalIP?: string | null;
      licenseExpirationDate?: any | null;
      machineID?: string | null;
      licenseFile?: {
        __typename: 'LicenseFile';
        content?: string | null;
        filename?: string | null;
      } | null;
    }>;
    downloadLinkResult?: {
      __typename: 'InstallationDownloadLinkResult';
      s3DownloadLink?: string | null;
    } | null;
  } | null;
};

export type Installation_SpektraInstallation_Fragment = {
  __typename: 'SpektraInstallation';
  id: string;
  userId: string;
  operation: InstallationOperation;
  product: ProductType;
  progress: number;
  status: InstallationStatus;
  installProgressMessage?: string | null;
  uninstallProgressMessage?: string | null;
  installFailedProgressMessage?: string | null;
  installFailureMessage?: string | null;
  uninstallFailureMessage?: string | null;
  installSuccessDate?: any | null;
  uninstallSuccessDate?: any | null;
  createdAt?: any | null;
  trialExpirationDate?: any | null;
  config: {
    __typename: 'SpektraInstallationConfig';
    id: string;
    cloudType: Provider;
    GCP?: {
      __typename: 'SpektraGCPInstallationConfig';
      name: string;
      imageName: string;
      projectId: string;
      zone: string;
    } | null;
    AWS?: {
      __typename: 'SpektraAWSInstallationConfig';
      name: string;
      imageName: string;
      zone: string;
    } | null;
  };
  result?: {
    __typename: 'InstallationResult';
    id: string;
    publicAppIp?: string | null;
    publicAppUrl?: string | null;
    nodes: Array<{
      __typename: 'InstallationNodeResult';
      id: string;
      name?: string | null;
      externalIP?: string | null;
      internalIP?: string | null;
      licenseExpirationDate?: any | null;
      machineID?: string | null;
      licenseFile?: {
        __typename: 'LicenseFile';
        content?: string | null;
        filename?: string | null;
      } | null;
    }>;
    downloadLinkResult?: {
      __typename: 'InstallationDownloadLinkResult';
      s3DownloadLink?: string | null;
    } | null;
  } | null;
};

export type Installation_UltimaEnterpriseInstallation_Fragment = {
  __typename: 'UltimaEnterpriseInstallation';
  id: string;
  userId: string;
  operation: InstallationOperation;
  product: ProductType;
  progress: number;
  status: InstallationStatus;
  installProgressMessage?: string | null;
  uninstallProgressMessage?: string | null;
  installFailedProgressMessage?: string | null;
  installFailureMessage?: string | null;
  uninstallFailureMessage?: string | null;
  installSuccessDate?: any | null;
  uninstallSuccessDate?: any | null;
  createdAt?: any | null;
  trialExpirationDate?: any | null;
  config: {
    __typename: 'UltimaEnterpriseInstallationConfig';
    id: string;
    cloudType: Provider;
    GCP?: {
      __typename: 'UltimaEnterpriseGCPInstallationConfig';
      clusterName: string;
      driveType: string;
      imageName: string;
      instanceUsername: string;
      machineType: string;
      numDrives: number;
      numNodes: number;
      projectId: string;
      zone: string;
      preemptible?: boolean | null;
    } | null;
    AWS?: {
      __typename: 'UltimaEnterpriseAWSInstallationConfig';
      clusterName: string;
      imageName: string;
      machineType: string;
      numNodes: number;
      zone: string;
    } | null;
  };
  result?: {
    __typename: 'InstallationResult';
    id: string;
    publicAppIp?: string | null;
    publicAppUrl?: string | null;
    nodes: Array<{
      __typename: 'InstallationNodeResult';
      id: string;
      name?: string | null;
      externalIP?: string | null;
      internalIP?: string | null;
      licenseExpirationDate?: any | null;
      machineID?: string | null;
      licenseFile?: {
        __typename: 'LicenseFile';
        content?: string | null;
        filename?: string | null;
      } | null;
    }>;
    downloadLinkResult?: {
      __typename: 'InstallationDownloadLinkResult';
      s3DownloadLink?: string | null;
    } | null;
  } | null;
};

export type InstallationFragment =
  | Installation_GroundworkInstallation_Fragment
  | Installation_SpektraInstallation_Fragment
  | Installation_UltimaEnterpriseInstallation_Fragment;

export type InstallationNodeResultFragment = {
  __typename: 'InstallationNodeResult';
  id: string;
  name?: string | null;
  externalIP?: string | null;
  internalIP?: string | null;
  licenseExpirationDate?: any | null;
  machineID?: string | null;
  licenseFile?: {
    __typename: 'LicenseFile';
    content?: string | null;
    filename?: string | null;
  } | null;
};

export type InstallationResultFragment = {
  __typename: 'InstallationResult';
  id: string;
  publicAppIp?: string | null;
  publicAppUrl?: string | null;
  nodes: Array<{
    __typename: 'InstallationNodeResult';
    id: string;
    name?: string | null;
    externalIP?: string | null;
    internalIP?: string | null;
    licenseExpirationDate?: any | null;
    machineID?: string | null;
    licenseFile?: {
      __typename: 'LicenseFile';
      content?: string | null;
      filename?: string | null;
    } | null;
  }>;
  downloadLinkResult?: {
    __typename: 'InstallationDownloadLinkResult';
    s3DownloadLink?: string | null;
  } | null;
};

export type SpektraInstallationConfigFragment = {
  __typename: 'SpektraInstallationConfig';
  id: string;
  cloudType: Provider;
  GCP?: {
    __typename: 'SpektraGCPInstallationConfig';
    name: string;
    imageName: string;
    projectId: string;
    zone: string;
  } | null;
  AWS?: {
    __typename: 'SpektraAWSInstallationConfig';
    name: string;
    imageName: string;
    zone: string;
  } | null;
};

export type SpektraInstallationFragment = {
  __typename: 'SpektraInstallation';
  id: string;
  userId: string;
  operation: InstallationOperation;
  product: ProductType;
  progress: number;
  status: InstallationStatus;
  installProgressMessage?: string | null;
  uninstallProgressMessage?: string | null;
  installFailedProgressMessage?: string | null;
  installFailureMessage?: string | null;
  uninstallFailureMessage?: string | null;
  installSuccessDate?: any | null;
  uninstallSuccessDate?: any | null;
  createdAt?: any | null;
  trialExpirationDate?: any | null;
  config: {
    __typename: 'SpektraInstallationConfig';
    id: string;
    cloudType: Provider;
    GCP?: {
      __typename: 'SpektraGCPInstallationConfig';
      name: string;
      imageName: string;
      projectId: string;
      zone: string;
    } | null;
    AWS?: {
      __typename: 'SpektraAWSInstallationConfig';
      name: string;
      imageName: string;
      zone: string;
    } | null;
  };
  result?: {
    __typename: 'InstallationResult';
    id: string;
    publicAppIp?: string | null;
    publicAppUrl?: string | null;
    nodes: Array<{
      __typename: 'InstallationNodeResult';
      id: string;
      name?: string | null;
      externalIP?: string | null;
      internalIP?: string | null;
      licenseExpirationDate?: any | null;
      machineID?: string | null;
      licenseFile?: {
        __typename: 'LicenseFile';
        content?: string | null;
        filename?: string | null;
      } | null;
    }>;
    downloadLinkResult?: {
      __typename: 'InstallationDownloadLinkResult';
      s3DownloadLink?: string | null;
    } | null;
  } | null;
};

export type UltimaEnterpriseInstallationConfigFragment = {
  __typename: 'UltimaEnterpriseInstallationConfig';
  id: string;
  cloudType: Provider;
  GCP?: {
    __typename: 'UltimaEnterpriseGCPInstallationConfig';
    clusterName: string;
    driveType: string;
    imageName: string;
    instanceUsername: string;
    machineType: string;
    numDrives: number;
    numNodes: number;
    projectId: string;
    zone: string;
    preemptible?: boolean | null;
  } | null;
  AWS?: {
    __typename: 'UltimaEnterpriseAWSInstallationConfig';
    clusterName: string;
    imageName: string;
    machineType: string;
    numNodes: number;
    zone: string;
  } | null;
};

export type UltimaEnterpriseInstallationFragment = {
  __typename: 'UltimaEnterpriseInstallation';
  id: string;
  userId: string;
  operation: InstallationOperation;
  product: ProductType;
  progress: number;
  status: InstallationStatus;
  installProgressMessage?: string | null;
  uninstallProgressMessage?: string | null;
  installFailedProgressMessage?: string | null;
  installFailureMessage?: string | null;
  uninstallFailureMessage?: string | null;
  installSuccessDate?: any | null;
  uninstallSuccessDate?: any | null;
  createdAt?: any | null;
  trialExpirationDate?: any | null;
  config: {
    __typename: 'UltimaEnterpriseInstallationConfig';
    id: string;
    cloudType: Provider;
    GCP?: {
      __typename: 'UltimaEnterpriseGCPInstallationConfig';
      clusterName: string;
      driveType: string;
      imageName: string;
      instanceUsername: string;
      machineType: string;
      numDrives: number;
      numNodes: number;
      projectId: string;
      zone: string;
      preemptible?: boolean | null;
    } | null;
    AWS?: {
      __typename: 'UltimaEnterpriseAWSInstallationConfig';
      clusterName: string;
      imageName: string;
      machineType: string;
      numNodes: number;
      zone: string;
    } | null;
  };
  result?: {
    __typename: 'InstallationResult';
    id: string;
    publicAppIp?: string | null;
    publicAppUrl?: string | null;
    nodes: Array<{
      __typename: 'InstallationNodeResult';
      id: string;
      name?: string | null;
      externalIP?: string | null;
      internalIP?: string | null;
      licenseExpirationDate?: any | null;
      machineID?: string | null;
      licenseFile?: {
        __typename: 'LicenseFile';
        content?: string | null;
        filename?: string | null;
      } | null;
    }>;
    downloadLinkResult?: {
      __typename: 'InstallationDownloadLinkResult';
      s3DownloadLink?: string | null;
    } | null;
  } | null;
};

export type DeleteSpektraInstallationMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteSpektraInstallationMutation = {
  __typename: 'Mutation';
  deleteSpektraInstallation: string;
};

export type DeleteUltimaEnterpriseInstallationMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteUltimaEnterpriseInstallationMutation = {
  __typename: 'Mutation';
  deleteUltimaEnterpriseInstallation: string;
};

export type InstallGroundworkMutationVariables = Exact<{
  input: InstallGroundworkInput;
}>;

export type InstallGroundworkMutation = {
  __typename: 'Mutation';
  installGroundwork: {
    __typename: 'GroundworkInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'GroundworkInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'GroundworkGCPInstallationConfig';
        name: string;
        imageName: string;
        projectId: string;
        zone: string;
      } | null;
      AWS?: {
        __typename: 'GroundworkAWSInstallationConfig';
        name: string;
        imageName: string;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type InstallSpektraMutationVariables = Exact<{
  input: InstallSpektraInput;
}>;

export type InstallSpektraMutation = {
  __typename: 'Mutation';
  installSpektra: {
    __typename: 'SpektraInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'SpektraInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'SpektraGCPInstallationConfig';
        name: string;
        imageName: string;
        projectId: string;
        zone: string;
      } | null;
      AWS?: {
        __typename: 'SpektraAWSInstallationConfig';
        name: string;
        imageName: string;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type InstallUltimaEnterpriseMutationVariables = Exact<{
  input: InstallUltimaEnterpriseInput;
}>;

export type InstallUltimaEnterpriseMutation = {
  __typename: 'Mutation';
  installUltimaEnterprise: {
    __typename: 'UltimaEnterpriseInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'UltimaEnterpriseInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'UltimaEnterpriseGCPInstallationConfig';
        clusterName: string;
        driveType: string;
        imageName: string;
        instanceUsername: string;
        machineType: string;
        numDrives: number;
        numNodes: number;
        projectId: string;
        zone: string;
        preemptible?: boolean | null;
      } | null;
      AWS?: {
        __typename: 'UltimaEnterpriseAWSInstallationConfig';
        clusterName: string;
        imageName: string;
        machineType: string;
        numNodes: number;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type UninstallGroundworkMutationVariables = Exact<{
  id: Scalars['String'];
  input: UninstallGroundworkInput;
}>;

export type UninstallGroundworkMutation = {
  __typename: 'Mutation';
  uninstallGroundwork: {
    __typename: 'GroundworkInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'GroundworkInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'GroundworkGCPInstallationConfig';
        name: string;
        imageName: string;
        projectId: string;
        zone: string;
      } | null;
      AWS?: {
        __typename: 'GroundworkAWSInstallationConfig';
        name: string;
        imageName: string;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type UninstallSpektraMutationVariables = Exact<{
  id: Scalars['String'];
  onlyRemoveFromDatabase: Scalars['Boolean'];
  input: UninstallSpektraInput;
}>;

export type UninstallSpektraMutation = {
  __typename: 'Mutation';
  uninstallSpektra: {
    __typename: 'SpektraInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'SpektraInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'SpektraGCPInstallationConfig';
        name: string;
        imageName: string;
        projectId: string;
        zone: string;
      } | null;
      AWS?: {
        __typename: 'SpektraAWSInstallationConfig';
        name: string;
        imageName: string;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type UninstallUltimaEnterpriseMutationVariables = Exact<{
  id: Scalars['String'];
  onlyRemoveFromDatabase: Scalars['Boolean'];
  input: UninstallUltimaEnterpriseInput;
}>;

export type UninstallUltimaEnterpriseMutation = {
  __typename: 'Mutation';
  uninstallUltimaEnterprise: {
    __typename: 'UltimaEnterpriseInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'UltimaEnterpriseInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'UltimaEnterpriseGCPInstallationConfig';
        clusterName: string;
        driveType: string;
        imageName: string;
        instanceUsername: string;
        machineType: string;
        numDrives: number;
        numNodes: number;
        projectId: string;
        zone: string;
        preemptible?: boolean | null;
      } | null;
      AWS?: {
        __typename: 'UltimaEnterpriseAWSInstallationConfig';
        clusterName: string;
        imageName: string;
        machineType: string;
        numNodes: number;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type UpdateSpektraInstallationMutationVariables = Exact<{
  id: Scalars['String'];
  input: InstallSpektraInput;
}>;

export type UpdateSpektraInstallationMutation = {
  __typename: 'Mutation';
  updateSpektraInstallation: {
    __typename: 'SpektraInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'SpektraInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'SpektraGCPInstallationConfig';
        name: string;
        imageName: string;
        projectId: string;
        zone: string;
      } | null;
      AWS?: {
        __typename: 'SpektraAWSInstallationConfig';
        name: string;
        imageName: string;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type UpdateUltimaEnterpriseInstallationMutationVariables = Exact<{
  id: Scalars['String'];
  input: InstallUltimaEnterpriseInput;
}>;

export type UpdateUltimaEnterpriseInstallationMutation = {
  __typename: 'Mutation';
  updateUltimaEnterpriseInstallation: {
    __typename: 'UltimaEnterpriseInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'UltimaEnterpriseInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'UltimaEnterpriseGCPInstallationConfig';
        clusterName: string;
        driveType: string;
        imageName: string;
        instanceUsername: string;
        machineType: string;
        numDrives: number;
        numNodes: number;
        projectId: string;
        zone: string;
        preemptible?: boolean | null;
      } | null;
      AWS?: {
        __typename: 'UltimaEnterpriseAWSInstallationConfig';
        clusterName: string;
        imageName: string;
        machineType: string;
        numNodes: number;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type UpdateUltimaEnterpriseInstallationVmbmLicensesMutationVariables = Exact<{
  id: Scalars['String'];
  input: InstallUltimaEnterpriseInput;
  machineID: Scalars['String'];
}>;

export type UpdateUltimaEnterpriseInstallationVmbmLicensesMutation = {
  __typename: 'Mutation';
  updateUltimaEnterpriseInstallationVMBMLicenses: {
    __typename: 'UltimaEnterpriseInstallation';
    id: string;
    userId: string;
    operation: InstallationOperation;
    product: ProductType;
    progress: number;
    status: InstallationStatus;
    installProgressMessage?: string | null;
    uninstallProgressMessage?: string | null;
    installFailedProgressMessage?: string | null;
    installFailureMessage?: string | null;
    uninstallFailureMessage?: string | null;
    installSuccessDate?: any | null;
    uninstallSuccessDate?: any | null;
    createdAt?: any | null;
    trialExpirationDate?: any | null;
    config: {
      __typename: 'UltimaEnterpriseInstallationConfig';
      id: string;
      cloudType: Provider;
      GCP?: {
        __typename: 'UltimaEnterpriseGCPInstallationConfig';
        clusterName: string;
        driveType: string;
        imageName: string;
        instanceUsername: string;
        machineType: string;
        numDrives: number;
        numNodes: number;
        projectId: string;
        zone: string;
        preemptible?: boolean | null;
      } | null;
      AWS?: {
        __typename: 'UltimaEnterpriseAWSInstallationConfig';
        clusterName: string;
        imageName: string;
        machineType: string;
        numNodes: number;
        zone: string;
      } | null;
    };
    result?: {
      __typename: 'InstallationResult';
      id: string;
      publicAppIp?: string | null;
      publicAppUrl?: string | null;
      nodes: Array<{
        __typename: 'InstallationNodeResult';
        id: string;
        name?: string | null;
        externalIP?: string | null;
        internalIP?: string | null;
        licenseExpirationDate?: any | null;
        machineID?: string | null;
        licenseFile?: {
          __typename: 'LicenseFile';
          content?: string | null;
          filename?: string | null;
        } | null;
      }>;
      downloadLinkResult?: {
        __typename: 'InstallationDownloadLinkResult';
        s3DownloadLink?: string | null;
      } | null;
    } | null;
  };
};

export type UpdateUserProfileMutationVariables = Exact<{
  input: UserProfileInput;
}>;

export type UpdateUserProfileMutation = {
  __typename: 'Mutation';
  updateUserProfile: { __typename: 'UserProfile'; id: string };
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename: 'Query';
  getUsers: Array<{
    __typename: 'UserProfileResponse';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    jobTitle?: string | null;
    phone?: string | null;
    company?: string | null;
    industry?: string | null;
  }>;
};

export type IsAdminUserQueryVariables = Exact<{ [key: string]: never }>;

export type IsAdminUserQuery = { __typename: 'Query'; isAdminUser: boolean };

export type AllInstallationsQueryVariables = Exact<{ [key: string]: never }>;

export type AllInstallationsQuery = {
  __typename: 'Query';
  allInstallations: Array<
    | {
        __typename: 'GroundworkInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'GroundworkInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'GroundworkGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'GroundworkAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'SpektraInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'SpektraInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'SpektraGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'SpektraAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'UltimaEnterpriseInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'UltimaEnterpriseInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'UltimaEnterpriseGCPInstallationConfig';
            clusterName: string;
            driveType: string;
            imageName: string;
            instanceUsername: string;
            machineType: string;
            numDrives: number;
            numNodes: number;
            projectId: string;
            zone: string;
            preemptible?: boolean | null;
          } | null;
          AWS?: {
            __typename: 'UltimaEnterpriseAWSInstallationConfig';
            clusterName: string;
            imageName: string;
            machineType: string;
            numNodes: number;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
  >;
};

export type CanInstallGroundworkOnProviderQueryVariables = Exact<{
  provider: Scalars['String'];
}>;

export type CanInstallGroundworkOnProviderQuery = {
  __typename: 'Query';
  canInstallGroundworkOnProvider: boolean;
};

export type CanInstallGroundworkQueryVariables = Exact<{ [key: string]: never }>;

export type CanInstallGroundworkQuery = { __typename: 'Query'; canInstallGroundwork: boolean };

export type CanInstallSpektraOnProviderQueryVariables = Exact<{
  provider: Scalars['String'];
}>;

export type CanInstallSpektraOnProviderQuery = {
  __typename: 'Query';
  canInstallSpektraOnProvider: boolean;
};

export type CanInstallUltimaEnterpriseOnProviderQueryVariables = Exact<{
  provider: Scalars['String'];
}>;

export type CanInstallUltimaEnterpriseOnProviderQuery = {
  __typename: 'Query';
  canInstallUltimaEnterpriseOnProvider: boolean;
};

export type CanInstallUltimaEnterpriseQueryVariables = Exact<{ [key: string]: never }>;

export type CanInstallUltimaEnterpriseQuery = {
  __typename: 'Query';
  canInstallUltimaEnterprise: boolean;
};

export type InstallationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstallationQuery = {
  __typename: 'Query';
  installation:
    | {
        __typename: 'GroundworkInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'GroundworkInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'GroundworkGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'GroundworkAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'SpektraInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'SpektraInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'SpektraGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'SpektraAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'UltimaEnterpriseInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'UltimaEnterpriseInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'UltimaEnterpriseGCPInstallationConfig';
            clusterName: string;
            driveType: string;
            imageName: string;
            instanceUsername: string;
            machineType: string;
            numDrives: number;
            numNodes: number;
            projectId: string;
            zone: string;
            preemptible?: boolean | null;
          } | null;
          AWS?: {
            __typename: 'UltimaEnterpriseAWSInstallationConfig';
            clusterName: string;
            imageName: string;
            machineType: string;
            numNodes: number;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      };
};

export type InstallationsQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationsQuery = {
  __typename: 'Query';
  installations: Array<
    | {
        __typename: 'GroundworkInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'GroundworkInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'GroundworkGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'GroundworkAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'SpektraInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'SpektraInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'SpektraGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'SpektraAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'UltimaEnterpriseInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'UltimaEnterpriseInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'UltimaEnterpriseGCPInstallationConfig';
            clusterName: string;
            driveType: string;
            imageName: string;
            instanceUsername: string;
            machineType: string;
            numDrives: number;
            numNodes: number;
            projectId: string;
            zone: string;
            preemptible?: boolean | null;
          } | null;
          AWS?: {
            __typename: 'UltimaEnterpriseAWSInstallationConfig';
            clusterName: string;
            imageName: string;
            machineType: string;
            numNodes: number;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
  >;
};

export type S3DownloadUrlQueryVariables = Exact<{
  provider: Provider;
  productType: ProductType;
}>;

export type S3DownloadUrlQuery = {
  __typename: 'Query';
  s3DownloadUrl: { __typename: 'InstallationDownloadUrl'; downloadUrl?: string | null };
};

export type InstallProgressSubscriptionVariables = Exact<{ [key: string]: never }>;

export type InstallProgressSubscription = {
  __typename: 'Subscription';
  installProgress:
    | {
        __typename: 'GroundworkInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'GroundworkInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'GroundworkGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'GroundworkAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'SpektraInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'SpektraInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'SpektraGCPInstallationConfig';
            name: string;
            imageName: string;
            projectId: string;
            zone: string;
          } | null;
          AWS?: {
            __typename: 'SpektraAWSInstallationConfig';
            name: string;
            imageName: string;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename: 'UltimaEnterpriseInstallation';
        id: string;
        userId: string;
        operation: InstallationOperation;
        product: ProductType;
        progress: number;
        status: InstallationStatus;
        installProgressMessage?: string | null;
        uninstallProgressMessage?: string | null;
        installFailedProgressMessage?: string | null;
        installFailureMessage?: string | null;
        uninstallFailureMessage?: string | null;
        installSuccessDate?: any | null;
        uninstallSuccessDate?: any | null;
        createdAt?: any | null;
        trialExpirationDate?: any | null;
        config: {
          __typename: 'UltimaEnterpriseInstallationConfig';
          id: string;
          cloudType: Provider;
          GCP?: {
            __typename: 'UltimaEnterpriseGCPInstallationConfig';
            clusterName: string;
            driveType: string;
            imageName: string;
            instanceUsername: string;
            machineType: string;
            numDrives: number;
            numNodes: number;
            projectId: string;
            zone: string;
            preemptible?: boolean | null;
          } | null;
          AWS?: {
            __typename: 'UltimaEnterpriseAWSInstallationConfig';
            clusterName: string;
            imageName: string;
            machineType: string;
            numNodes: number;
            zone: string;
          } | null;
        };
        result?: {
          __typename: 'InstallationResult';
          id: string;
          publicAppIp?: string | null;
          publicAppUrl?: string | null;
          nodes: Array<{
            __typename: 'InstallationNodeResult';
            id: string;
            name?: string | null;
            externalIP?: string | null;
            internalIP?: string | null;
            licenseExpirationDate?: any | null;
            machineID?: string | null;
            licenseFile?: {
              __typename: 'LicenseFile';
              content?: string | null;
              filename?: string | null;
            } | null;
          }>;
          downloadLinkResult?: {
            __typename: 'InstallationDownloadLinkResult';
            s3DownloadLink?: string | null;
          } | null;
        } | null;
      };
};

export const UserProfileResponseFragmentDoc = gql`
  fragment UserProfileResponse on UserProfileResponse {
    id
    firstName
    lastName
    email
    jobTitle
    phone
    company
    industry
  }
`;
export const UltimaEnterpriseInstallationConfigFragmentDoc = gql`
  fragment UltimaEnterpriseInstallationConfig on UltimaEnterpriseInstallationConfig {
    id
    cloudType
    GCP {
      clusterName
      driveType
      imageName
      instanceUsername
      machineType
      numDrives
      numNodes
      projectId
      zone
      preemptible
    }
    AWS {
      clusterName
      imageName
      machineType
      numNodes
      zone
    }
  }
`;
export const InstallationNodeResultFragmentDoc = gql`
  fragment InstallationNodeResult on InstallationNodeResult {
    id
    name
    externalIP
    internalIP
    licenseExpirationDate
    machineID
    licenseFile {
      content
      filename
    }
  }
`;
export const InstallationResultFragmentDoc = gql`
  fragment InstallationResult on InstallationResult {
    id
    nodes {
      ...InstallationNodeResult
    }
    publicAppIp
    publicAppUrl
    downloadLinkResult {
      s3DownloadLink
    }
  }
  ${InstallationNodeResultFragmentDoc}
`;
export const UltimaEnterpriseInstallationFragmentDoc = gql`
  fragment UltimaEnterpriseInstallation on UltimaEnterpriseInstallation {
    id
    userId
    operation
    product
    progress
    status
    installProgressMessage
    uninstallProgressMessage
    installFailedProgressMessage
    installFailureMessage
    uninstallFailureMessage
    installSuccessDate
    uninstallSuccessDate
    createdAt
    trialExpirationDate
    config {
      ...UltimaEnterpriseInstallationConfig
    }
    result {
      ...InstallationResult
    }
  }
  ${UltimaEnterpriseInstallationConfigFragmentDoc}
  ${InstallationResultFragmentDoc}
`;
export const SpektraInstallationConfigFragmentDoc = gql`
  fragment SpektraInstallationConfig on SpektraInstallationConfig {
    id
    cloudType
    GCP {
      name
      imageName
      projectId
      zone
    }
    AWS {
      name
      imageName
      zone
    }
  }
`;
export const SpektraInstallationFragmentDoc = gql`
  fragment SpektraInstallation on SpektraInstallation {
    id
    userId
    operation
    product
    progress
    status
    installProgressMessage
    uninstallProgressMessage
    installFailedProgressMessage
    installFailureMessage
    uninstallFailureMessage
    installSuccessDate
    uninstallSuccessDate
    createdAt
    trialExpirationDate
    config {
      ...SpektraInstallationConfig
    }
    result {
      ...InstallationResult
    }
  }
  ${SpektraInstallationConfigFragmentDoc}
  ${InstallationResultFragmentDoc}
`;
export const GroundworkInstallationConfigFragmentDoc = gql`
  fragment GroundworkInstallationConfig on GroundworkInstallationConfig {
    id
    cloudType
    GCP {
      name
      imageName
      projectId
      zone
    }
    AWS {
      name
      imageName
      zone
    }
  }
`;
export const GroundworkInstallationFragmentDoc = gql`
  fragment GroundworkInstallation on GroundworkInstallation {
    id
    userId
    operation
    product
    progress
    status
    installProgressMessage
    uninstallProgressMessage
    installFailedProgressMessage
    installFailureMessage
    uninstallFailureMessage
    installSuccessDate
    uninstallSuccessDate
    createdAt
    trialExpirationDate
    config {
      ...GroundworkInstallationConfig
    }
    result {
      ...InstallationResult
    }
  }
  ${GroundworkInstallationConfigFragmentDoc}
  ${InstallationResultFragmentDoc}
`;
export const InstallationFragmentDoc = gql`
  fragment Installation on Installation {
    ...UltimaEnterpriseInstallation
    ...SpektraInstallation
    ...GroundworkInstallation
  }
  ${UltimaEnterpriseInstallationFragmentDoc}
  ${SpektraInstallationFragmentDoc}
  ${GroundworkInstallationFragmentDoc}
`;
export const DeleteSpektraInstallationDocument = gql`
  mutation DeleteSpektraInstallation($id: String!) {
    deleteSpektraInstallation(id: $id)
  }
`;
export type DeleteSpektraInstallationMutationFn = Apollo.MutationFunction<
  DeleteSpektraInstallationMutation,
  DeleteSpektraInstallationMutationVariables
>;

/**
 * __useDeleteSpektraInstallationMutation__
 *
 * To run a mutation, you first call `useDeleteSpektraInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpektraInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpektraInstallationMutation, { data, loading, error }] = useDeleteSpektraInstallationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSpektraInstallationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSpektraInstallationMutation,
    DeleteSpektraInstallationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSpektraInstallationMutation,
    DeleteSpektraInstallationMutationVariables
  >(DeleteSpektraInstallationDocument, options);
}
export type DeleteSpektraInstallationMutationHookResult = ReturnType<
  typeof useDeleteSpektraInstallationMutation
>;
export type DeleteSpektraInstallationMutationResult =
  Apollo.MutationResult<DeleteSpektraInstallationMutation>;
export type DeleteSpektraInstallationMutationOptions = Apollo.BaseMutationOptions<
  DeleteSpektraInstallationMutation,
  DeleteSpektraInstallationMutationVariables
>;
export const DeleteUltimaEnterpriseInstallationDocument = gql`
  mutation DeleteUltimaEnterpriseInstallation($id: String!) {
    deleteUltimaEnterpriseInstallation(id: $id)
  }
`;
export type DeleteUltimaEnterpriseInstallationMutationFn = Apollo.MutationFunction<
  DeleteUltimaEnterpriseInstallationMutation,
  DeleteUltimaEnterpriseInstallationMutationVariables
>;

/**
 * __useDeleteUltimaEnterpriseInstallationMutation__
 *
 * To run a mutation, you first call `useDeleteUltimaEnterpriseInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUltimaEnterpriseInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUltimaEnterpriseInstallationMutation, { data, loading, error }] = useDeleteUltimaEnterpriseInstallationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUltimaEnterpriseInstallationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUltimaEnterpriseInstallationMutation,
    DeleteUltimaEnterpriseInstallationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUltimaEnterpriseInstallationMutation,
    DeleteUltimaEnterpriseInstallationMutationVariables
  >(DeleteUltimaEnterpriseInstallationDocument, options);
}
export type DeleteUltimaEnterpriseInstallationMutationHookResult = ReturnType<
  typeof useDeleteUltimaEnterpriseInstallationMutation
>;
export type DeleteUltimaEnterpriseInstallationMutationResult =
  Apollo.MutationResult<DeleteUltimaEnterpriseInstallationMutation>;
export type DeleteUltimaEnterpriseInstallationMutationOptions = Apollo.BaseMutationOptions<
  DeleteUltimaEnterpriseInstallationMutation,
  DeleteUltimaEnterpriseInstallationMutationVariables
>;
export const InstallGroundworkDocument = gql`
  mutation InstallGroundwork($input: InstallGroundworkInput!) {
    installGroundwork(input: $input) {
      ...GroundworkInstallation
    }
  }
  ${GroundworkInstallationFragmentDoc}
`;
export type InstallGroundworkMutationFn = Apollo.MutationFunction<
  InstallGroundworkMutation,
  InstallGroundworkMutationVariables
>;

/**
 * __useInstallGroundworkMutation__
 *
 * To run a mutation, you first call `useInstallGroundworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallGroundworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installGroundworkMutation, { data, loading, error }] = useInstallGroundworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstallGroundworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstallGroundworkMutation,
    InstallGroundworkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InstallGroundworkMutation, InstallGroundworkMutationVariables>(
    InstallGroundworkDocument,
    options
  );
}
export type InstallGroundworkMutationHookResult = ReturnType<typeof useInstallGroundworkMutation>;
export type InstallGroundworkMutationResult = Apollo.MutationResult<InstallGroundworkMutation>;
export type InstallGroundworkMutationOptions = Apollo.BaseMutationOptions<
  InstallGroundworkMutation,
  InstallGroundworkMutationVariables
>;
export const InstallSpektraDocument = gql`
  mutation InstallSpektra($input: InstallSpektraInput!) {
    installSpektra(input: $input) {
      ...SpektraInstallation
    }
  }
  ${SpektraInstallationFragmentDoc}
`;
export type InstallSpektraMutationFn = Apollo.MutationFunction<
  InstallSpektraMutation,
  InstallSpektraMutationVariables
>;

/**
 * __useInstallSpektraMutation__
 *
 * To run a mutation, you first call `useInstallSpektraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallSpektraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installSpektraMutation, { data, loading, error }] = useInstallSpektraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstallSpektraMutation(
  baseOptions?: Apollo.MutationHookOptions<InstallSpektraMutation, InstallSpektraMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InstallSpektraMutation, InstallSpektraMutationVariables>(
    InstallSpektraDocument,
    options
  );
}
export type InstallSpektraMutationHookResult = ReturnType<typeof useInstallSpektraMutation>;
export type InstallSpektraMutationResult = Apollo.MutationResult<InstallSpektraMutation>;
export type InstallSpektraMutationOptions = Apollo.BaseMutationOptions<
  InstallSpektraMutation,
  InstallSpektraMutationVariables
>;
export const InstallUltimaEnterpriseDocument = gql`
  mutation InstallUltimaEnterprise($input: InstallUltimaEnterpriseInput!) {
    installUltimaEnterprise(input: $input) {
      ...UltimaEnterpriseInstallation
    }
  }
  ${UltimaEnterpriseInstallationFragmentDoc}
`;
export type InstallUltimaEnterpriseMutationFn = Apollo.MutationFunction<
  InstallUltimaEnterpriseMutation,
  InstallUltimaEnterpriseMutationVariables
>;

/**
 * __useInstallUltimaEnterpriseMutation__
 *
 * To run a mutation, you first call `useInstallUltimaEnterpriseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallUltimaEnterpriseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installUltimaEnterpriseMutation, { data, loading, error }] = useInstallUltimaEnterpriseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstallUltimaEnterpriseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstallUltimaEnterpriseMutation,
    InstallUltimaEnterpriseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstallUltimaEnterpriseMutation,
    InstallUltimaEnterpriseMutationVariables
  >(InstallUltimaEnterpriseDocument, options);
}
export type InstallUltimaEnterpriseMutationHookResult = ReturnType<
  typeof useInstallUltimaEnterpriseMutation
>;
export type InstallUltimaEnterpriseMutationResult =
  Apollo.MutationResult<InstallUltimaEnterpriseMutation>;
export type InstallUltimaEnterpriseMutationOptions = Apollo.BaseMutationOptions<
  InstallUltimaEnterpriseMutation,
  InstallUltimaEnterpriseMutationVariables
>;
export const UninstallGroundworkDocument = gql`
  mutation UninstallGroundwork($id: String!, $input: UninstallGroundworkInput!) {
    uninstallGroundwork(id: $id, input: $input) {
      ...GroundworkInstallation
    }
  }
  ${GroundworkInstallationFragmentDoc}
`;
export type UninstallGroundworkMutationFn = Apollo.MutationFunction<
  UninstallGroundworkMutation,
  UninstallGroundworkMutationVariables
>;

/**
 * __useUninstallGroundworkMutation__
 *
 * To run a mutation, you first call `useUninstallGroundworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallGroundworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallGroundworkMutation, { data, loading, error }] = useUninstallGroundworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUninstallGroundworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallGroundworkMutation,
    UninstallGroundworkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UninstallGroundworkMutation, UninstallGroundworkMutationVariables>(
    UninstallGroundworkDocument,
    options
  );
}
export type UninstallGroundworkMutationHookResult = ReturnType<
  typeof useUninstallGroundworkMutation
>;
export type UninstallGroundworkMutationResult = Apollo.MutationResult<UninstallGroundworkMutation>;
export type UninstallGroundworkMutationOptions = Apollo.BaseMutationOptions<
  UninstallGroundworkMutation,
  UninstallGroundworkMutationVariables
>;
export const UninstallSpektraDocument = gql`
  mutation UninstallSpektra(
    $id: String!
    $onlyRemoveFromDatabase: Boolean!
    $input: UninstallSpektraInput!
  ) {
    uninstallSpektra(id: $id, onlyRemoveFromDatabase: $onlyRemoveFromDatabase, input: $input) {
      ...SpektraInstallation
    }
  }
  ${SpektraInstallationFragmentDoc}
`;
export type UninstallSpektraMutationFn = Apollo.MutationFunction<
  UninstallSpektraMutation,
  UninstallSpektraMutationVariables
>;

/**
 * __useUninstallSpektraMutation__
 *
 * To run a mutation, you first call `useUninstallSpektraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallSpektraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallSpektraMutation, { data, loading, error }] = useUninstallSpektraMutation({
 *   variables: {
 *      id: // value for 'id'
 *      onlyRemoveFromDatabase: // value for 'onlyRemoveFromDatabase'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUninstallSpektraMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallSpektraMutation,
    UninstallSpektraMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UninstallSpektraMutation, UninstallSpektraMutationVariables>(
    UninstallSpektraDocument,
    options
  );
}
export type UninstallSpektraMutationHookResult = ReturnType<typeof useUninstallSpektraMutation>;
export type UninstallSpektraMutationResult = Apollo.MutationResult<UninstallSpektraMutation>;
export type UninstallSpektraMutationOptions = Apollo.BaseMutationOptions<
  UninstallSpektraMutation,
  UninstallSpektraMutationVariables
>;
export const UninstallUltimaEnterpriseDocument = gql`
  mutation UninstallUltimaEnterprise(
    $id: String!
    $onlyRemoveFromDatabase: Boolean!
    $input: UninstallUltimaEnterpriseInput!
  ) {
    uninstallUltimaEnterprise(
      id: $id
      onlyRemoveFromDatabase: $onlyRemoveFromDatabase
      input: $input
    ) {
      ...UltimaEnterpriseInstallation
    }
  }
  ${UltimaEnterpriseInstallationFragmentDoc}
`;
export type UninstallUltimaEnterpriseMutationFn = Apollo.MutationFunction<
  UninstallUltimaEnterpriseMutation,
  UninstallUltimaEnterpriseMutationVariables
>;

/**
 * __useUninstallUltimaEnterpriseMutation__
 *
 * To run a mutation, you first call `useUninstallUltimaEnterpriseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallUltimaEnterpriseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallUltimaEnterpriseMutation, { data, loading, error }] = useUninstallUltimaEnterpriseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      onlyRemoveFromDatabase: // value for 'onlyRemoveFromDatabase'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUninstallUltimaEnterpriseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallUltimaEnterpriseMutation,
    UninstallUltimaEnterpriseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UninstallUltimaEnterpriseMutation,
    UninstallUltimaEnterpriseMutationVariables
  >(UninstallUltimaEnterpriseDocument, options);
}
export type UninstallUltimaEnterpriseMutationHookResult = ReturnType<
  typeof useUninstallUltimaEnterpriseMutation
>;
export type UninstallUltimaEnterpriseMutationResult =
  Apollo.MutationResult<UninstallUltimaEnterpriseMutation>;
export type UninstallUltimaEnterpriseMutationOptions = Apollo.BaseMutationOptions<
  UninstallUltimaEnterpriseMutation,
  UninstallUltimaEnterpriseMutationVariables
>;
export const UpdateSpektraInstallationDocument = gql`
  mutation UpdateSpektraInstallation($id: String!, $input: InstallSpektraInput!) {
    updateSpektraInstallation(id: $id, input: $input) {
      ...SpektraInstallation
    }
  }
  ${SpektraInstallationFragmentDoc}
`;
export type UpdateSpektraInstallationMutationFn = Apollo.MutationFunction<
  UpdateSpektraInstallationMutation,
  UpdateSpektraInstallationMutationVariables
>;

/**
 * __useUpdateSpektraInstallationMutation__
 *
 * To run a mutation, you first call `useUpdateSpektraInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpektraInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpektraInstallationMutation, { data, loading, error }] = useUpdateSpektraInstallationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpektraInstallationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSpektraInstallationMutation,
    UpdateSpektraInstallationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSpektraInstallationMutation,
    UpdateSpektraInstallationMutationVariables
  >(UpdateSpektraInstallationDocument, options);
}
export type UpdateSpektraInstallationMutationHookResult = ReturnType<
  typeof useUpdateSpektraInstallationMutation
>;
export type UpdateSpektraInstallationMutationResult =
  Apollo.MutationResult<UpdateSpektraInstallationMutation>;
export type UpdateSpektraInstallationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpektraInstallationMutation,
  UpdateSpektraInstallationMutationVariables
>;
export const UpdateUltimaEnterpriseInstallationDocument = gql`
  mutation UpdateUltimaEnterpriseInstallation($id: String!, $input: InstallUltimaEnterpriseInput!) {
    updateUltimaEnterpriseInstallation(id: $id, input: $input) {
      ...UltimaEnterpriseInstallation
    }
  }
  ${UltimaEnterpriseInstallationFragmentDoc}
`;
export type UpdateUltimaEnterpriseInstallationMutationFn = Apollo.MutationFunction<
  UpdateUltimaEnterpriseInstallationMutation,
  UpdateUltimaEnterpriseInstallationMutationVariables
>;

/**
 * __useUpdateUltimaEnterpriseInstallationMutation__
 *
 * To run a mutation, you first call `useUpdateUltimaEnterpriseInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUltimaEnterpriseInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUltimaEnterpriseInstallationMutation, { data, loading, error }] = useUpdateUltimaEnterpriseInstallationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUltimaEnterpriseInstallationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUltimaEnterpriseInstallationMutation,
    UpdateUltimaEnterpriseInstallationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUltimaEnterpriseInstallationMutation,
    UpdateUltimaEnterpriseInstallationMutationVariables
  >(UpdateUltimaEnterpriseInstallationDocument, options);
}
export type UpdateUltimaEnterpriseInstallationMutationHookResult = ReturnType<
  typeof useUpdateUltimaEnterpriseInstallationMutation
>;
export type UpdateUltimaEnterpriseInstallationMutationResult =
  Apollo.MutationResult<UpdateUltimaEnterpriseInstallationMutation>;
export type UpdateUltimaEnterpriseInstallationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUltimaEnterpriseInstallationMutation,
  UpdateUltimaEnterpriseInstallationMutationVariables
>;
export const UpdateUltimaEnterpriseInstallationVmbmLicensesDocument = gql`
  mutation UpdateUltimaEnterpriseInstallationVMBMLicenses(
    $id: String!
    $input: InstallUltimaEnterpriseInput!
    $machineID: String!
  ) {
    updateUltimaEnterpriseInstallationVMBMLicenses(id: $id, input: $input, machineID: $machineID) {
      ...UltimaEnterpriseInstallation
    }
  }
  ${UltimaEnterpriseInstallationFragmentDoc}
`;
export type UpdateUltimaEnterpriseInstallationVmbmLicensesMutationFn = Apollo.MutationFunction<
  UpdateUltimaEnterpriseInstallationVmbmLicensesMutation,
  UpdateUltimaEnterpriseInstallationVmbmLicensesMutationVariables
>;

/**
 * __useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation__
 *
 * To run a mutation, you first call `useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUltimaEnterpriseInstallationVmbmLicensesMutation, { data, loading, error }] = useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      machineID: // value for 'machineID'
 *   },
 * });
 */
export function useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUltimaEnterpriseInstallationVmbmLicensesMutation,
    UpdateUltimaEnterpriseInstallationVmbmLicensesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUltimaEnterpriseInstallationVmbmLicensesMutation,
    UpdateUltimaEnterpriseInstallationVmbmLicensesMutationVariables
  >(UpdateUltimaEnterpriseInstallationVmbmLicensesDocument, options);
}
export type UpdateUltimaEnterpriseInstallationVmbmLicensesMutationHookResult = ReturnType<
  typeof useUpdateUltimaEnterpriseInstallationVmbmLicensesMutation
>;
export type UpdateUltimaEnterpriseInstallationVmbmLicensesMutationResult =
  Apollo.MutationResult<UpdateUltimaEnterpriseInstallationVmbmLicensesMutation>;
export type UpdateUltimaEnterpriseInstallationVmbmLicensesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUltimaEnterpriseInstallationVmbmLicensesMutation,
    UpdateUltimaEnterpriseInstallationVmbmLicensesMutationVariables
  >;
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      id
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
    UpdateUserProfileDocument,
    options
  );
}
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const GetUsersDocument = gql`
  query GetUsers {
    getUsers {
      ...UserProfileResponse
    }
  }
  ${UserProfileResponseFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const IsAdminUserDocument = gql`
  query IsAdminUser {
    isAdminUser
  }
`;

/**
 * __useIsAdminUserQuery__
 *
 * To run a query within a React component, call `useIsAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAdminUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsAdminUserQuery(
  baseOptions?: Apollo.QueryHookOptions<IsAdminUserQuery, IsAdminUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsAdminUserQuery, IsAdminUserQueryVariables>(IsAdminUserDocument, options);
}
export function useIsAdminUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsAdminUserQuery, IsAdminUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsAdminUserQuery, IsAdminUserQueryVariables>(
    IsAdminUserDocument,
    options
  );
}
export type IsAdminUserQueryHookResult = ReturnType<typeof useIsAdminUserQuery>;
export type IsAdminUserLazyQueryHookResult = ReturnType<typeof useIsAdminUserLazyQuery>;
export type IsAdminUserQueryResult = Apollo.QueryResult<
  IsAdminUserQuery,
  IsAdminUserQueryVariables
>;
export const AllInstallationsDocument = gql`
  query AllInstallations {
    allInstallations {
      ...Installation
    }
  }
  ${InstallationFragmentDoc}
`;

/**
 * __useAllInstallationsQuery__
 *
 * To run a query within a React component, call `useAllInstallationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInstallationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInstallationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllInstallationsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllInstallationsQuery, AllInstallationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllInstallationsQuery, AllInstallationsQueryVariables>(
    AllInstallationsDocument,
    options
  );
}
export function useAllInstallationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllInstallationsQuery, AllInstallationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllInstallationsQuery, AllInstallationsQueryVariables>(
    AllInstallationsDocument,
    options
  );
}
export type AllInstallationsQueryHookResult = ReturnType<typeof useAllInstallationsQuery>;
export type AllInstallationsLazyQueryHookResult = ReturnType<typeof useAllInstallationsLazyQuery>;
export type AllInstallationsQueryResult = Apollo.QueryResult<
  AllInstallationsQuery,
  AllInstallationsQueryVariables
>;
export const CanInstallGroundworkOnProviderDocument = gql`
  query CanInstallGroundworkOnProvider($provider: String!) {
    canInstallGroundworkOnProvider(provider: $provider)
  }
`;

/**
 * __useCanInstallGroundworkOnProviderQuery__
 *
 * To run a query within a React component, call `useCanInstallGroundworkOnProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanInstallGroundworkOnProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanInstallGroundworkOnProviderQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useCanInstallGroundworkOnProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanInstallGroundworkOnProviderQuery,
    CanInstallGroundworkOnProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CanInstallGroundworkOnProviderQuery,
    CanInstallGroundworkOnProviderQueryVariables
  >(CanInstallGroundworkOnProviderDocument, options);
}
export function useCanInstallGroundworkOnProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanInstallGroundworkOnProviderQuery,
    CanInstallGroundworkOnProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CanInstallGroundworkOnProviderQuery,
    CanInstallGroundworkOnProviderQueryVariables
  >(CanInstallGroundworkOnProviderDocument, options);
}
export type CanInstallGroundworkOnProviderQueryHookResult = ReturnType<
  typeof useCanInstallGroundworkOnProviderQuery
>;
export type CanInstallGroundworkOnProviderLazyQueryHookResult = ReturnType<
  typeof useCanInstallGroundworkOnProviderLazyQuery
>;
export type CanInstallGroundworkOnProviderQueryResult = Apollo.QueryResult<
  CanInstallGroundworkOnProviderQuery,
  CanInstallGroundworkOnProviderQueryVariables
>;
export const CanInstallGroundworkDocument = gql`
  query CanInstallGroundwork {
    canInstallGroundwork
  }
`;

/**
 * __useCanInstallGroundworkQuery__
 *
 * To run a query within a React component, call `useCanInstallGroundworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanInstallGroundworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanInstallGroundworkQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanInstallGroundworkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CanInstallGroundworkQuery,
    CanInstallGroundworkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanInstallGroundworkQuery, CanInstallGroundworkQueryVariables>(
    CanInstallGroundworkDocument,
    options
  );
}
export function useCanInstallGroundworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanInstallGroundworkQuery,
    CanInstallGroundworkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanInstallGroundworkQuery, CanInstallGroundworkQueryVariables>(
    CanInstallGroundworkDocument,
    options
  );
}
export type CanInstallGroundworkQueryHookResult = ReturnType<typeof useCanInstallGroundworkQuery>;
export type CanInstallGroundworkLazyQueryHookResult = ReturnType<
  typeof useCanInstallGroundworkLazyQuery
>;
export type CanInstallGroundworkQueryResult = Apollo.QueryResult<
  CanInstallGroundworkQuery,
  CanInstallGroundworkQueryVariables
>;
export const CanInstallSpektraOnProviderDocument = gql`
  query CanInstallSpektraOnProvider($provider: String!) {
    canInstallSpektraOnProvider(provider: $provider)
  }
`;

/**
 * __useCanInstallSpektraOnProviderQuery__
 *
 * To run a query within a React component, call `useCanInstallSpektraOnProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanInstallSpektraOnProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanInstallSpektraOnProviderQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useCanInstallSpektraOnProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanInstallSpektraOnProviderQuery,
    CanInstallSpektraOnProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CanInstallSpektraOnProviderQuery,
    CanInstallSpektraOnProviderQueryVariables
  >(CanInstallSpektraOnProviderDocument, options);
}
export function useCanInstallSpektraOnProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanInstallSpektraOnProviderQuery,
    CanInstallSpektraOnProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CanInstallSpektraOnProviderQuery,
    CanInstallSpektraOnProviderQueryVariables
  >(CanInstallSpektraOnProviderDocument, options);
}
export type CanInstallSpektraOnProviderQueryHookResult = ReturnType<
  typeof useCanInstallSpektraOnProviderQuery
>;
export type CanInstallSpektraOnProviderLazyQueryHookResult = ReturnType<
  typeof useCanInstallSpektraOnProviderLazyQuery
>;
export type CanInstallSpektraOnProviderQueryResult = Apollo.QueryResult<
  CanInstallSpektraOnProviderQuery,
  CanInstallSpektraOnProviderQueryVariables
>;
export const CanInstallUltimaEnterpriseOnProviderDocument = gql`
  query CanInstallUltimaEnterpriseOnProvider($provider: String!) {
    canInstallUltimaEnterpriseOnProvider(provider: $provider)
  }
`;

/**
 * __useCanInstallUltimaEnterpriseOnProviderQuery__
 *
 * To run a query within a React component, call `useCanInstallUltimaEnterpriseOnProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanInstallUltimaEnterpriseOnProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanInstallUltimaEnterpriseOnProviderQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useCanInstallUltimaEnterpriseOnProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanInstallUltimaEnterpriseOnProviderQuery,
    CanInstallUltimaEnterpriseOnProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CanInstallUltimaEnterpriseOnProviderQuery,
    CanInstallUltimaEnterpriseOnProviderQueryVariables
  >(CanInstallUltimaEnterpriseOnProviderDocument, options);
}
export function useCanInstallUltimaEnterpriseOnProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanInstallUltimaEnterpriseOnProviderQuery,
    CanInstallUltimaEnterpriseOnProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CanInstallUltimaEnterpriseOnProviderQuery,
    CanInstallUltimaEnterpriseOnProviderQueryVariables
  >(CanInstallUltimaEnterpriseOnProviderDocument, options);
}
export type CanInstallUltimaEnterpriseOnProviderQueryHookResult = ReturnType<
  typeof useCanInstallUltimaEnterpriseOnProviderQuery
>;
export type CanInstallUltimaEnterpriseOnProviderLazyQueryHookResult = ReturnType<
  typeof useCanInstallUltimaEnterpriseOnProviderLazyQuery
>;
export type CanInstallUltimaEnterpriseOnProviderQueryResult = Apollo.QueryResult<
  CanInstallUltimaEnterpriseOnProviderQuery,
  CanInstallUltimaEnterpriseOnProviderQueryVariables
>;
export const CanInstallUltimaEnterpriseDocument = gql`
  query CanInstallUltimaEnterprise {
    canInstallUltimaEnterprise
  }
`;

/**
 * __useCanInstallUltimaEnterpriseQuery__
 *
 * To run a query within a React component, call `useCanInstallUltimaEnterpriseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanInstallUltimaEnterpriseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanInstallUltimaEnterpriseQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanInstallUltimaEnterpriseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CanInstallUltimaEnterpriseQuery,
    CanInstallUltimaEnterpriseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanInstallUltimaEnterpriseQuery, CanInstallUltimaEnterpriseQueryVariables>(
    CanInstallUltimaEnterpriseDocument,
    options
  );
}
export function useCanInstallUltimaEnterpriseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanInstallUltimaEnterpriseQuery,
    CanInstallUltimaEnterpriseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CanInstallUltimaEnterpriseQuery,
    CanInstallUltimaEnterpriseQueryVariables
  >(CanInstallUltimaEnterpriseDocument, options);
}
export type CanInstallUltimaEnterpriseQueryHookResult = ReturnType<
  typeof useCanInstallUltimaEnterpriseQuery
>;
export type CanInstallUltimaEnterpriseLazyQueryHookResult = ReturnType<
  typeof useCanInstallUltimaEnterpriseLazyQuery
>;
export type CanInstallUltimaEnterpriseQueryResult = Apollo.QueryResult<
  CanInstallUltimaEnterpriseQuery,
  CanInstallUltimaEnterpriseQueryVariables
>;
export const InstallationDocument = gql`
  query Installation($id: ID!) {
    installation(id: $id) {
      ...Installation
    }
  }
  ${InstallationFragmentDoc}
`;

/**
 * __useInstallationQuery__
 *
 * To run a query within a React component, call `useInstallationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInstallationQuery(
  baseOptions: Apollo.QueryHookOptions<InstallationQuery, InstallationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstallationQuery, InstallationQueryVariables>(
    InstallationDocument,
    options
  );
}
export function useInstallationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstallationQuery, InstallationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstallationQuery, InstallationQueryVariables>(
    InstallationDocument,
    options
  );
}
export type InstallationQueryHookResult = ReturnType<typeof useInstallationQuery>;
export type InstallationLazyQueryHookResult = ReturnType<typeof useInstallationLazyQuery>;
export type InstallationQueryResult = Apollo.QueryResult<
  InstallationQuery,
  InstallationQueryVariables
>;
export const InstallationsDocument = gql`
  query Installations {
    installations {
      ...Installation
    }
  }
  ${InstallationFragmentDoc}
`;

/**
 * __useInstallationsQuery__
 *
 * To run a query within a React component, call `useInstallationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstallationsQuery(
  baseOptions?: Apollo.QueryHookOptions<InstallationsQuery, InstallationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstallationsQuery, InstallationsQueryVariables>(
    InstallationsDocument,
    options
  );
}
export function useInstallationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstallationsQuery, InstallationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstallationsQuery, InstallationsQueryVariables>(
    InstallationsDocument,
    options
  );
}
export type InstallationsQueryHookResult = ReturnType<typeof useInstallationsQuery>;
export type InstallationsLazyQueryHookResult = ReturnType<typeof useInstallationsLazyQuery>;
export type InstallationsQueryResult = Apollo.QueryResult<
  InstallationsQuery,
  InstallationsQueryVariables
>;
export const S3DownloadUrlDocument = gql`
  query S3DownloadUrl($provider: Provider!, $productType: ProductType!) {
    s3DownloadUrl(provider: $provider, productType: $productType) {
      downloadUrl
    }
  }
`;

/**
 * __useS3DownloadUrlQuery__
 *
 * To run a query within a React component, call `useS3DownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useS3DownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS3DownloadUrlQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *      productType: // value for 'productType'
 *   },
 * });
 */
export function useS3DownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<S3DownloadUrlQuery, S3DownloadUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<S3DownloadUrlQuery, S3DownloadUrlQueryVariables>(
    S3DownloadUrlDocument,
    options
  );
}
export function useS3DownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<S3DownloadUrlQuery, S3DownloadUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<S3DownloadUrlQuery, S3DownloadUrlQueryVariables>(
    S3DownloadUrlDocument,
    options
  );
}
export type S3DownloadUrlQueryHookResult = ReturnType<typeof useS3DownloadUrlQuery>;
export type S3DownloadUrlLazyQueryHookResult = ReturnType<typeof useS3DownloadUrlLazyQuery>;
export type S3DownloadUrlQueryResult = Apollo.QueryResult<
  S3DownloadUrlQuery,
  S3DownloadUrlQueryVariables
>;
export const InstallProgressDocument = gql`
  subscription InstallProgress {
    installProgress {
      ...Installation
    }
  }
  ${InstallationFragmentDoc}
`;

/**
 * __useInstallProgressSubscription__
 *
 * To run a query within a React component, call `useInstallProgressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInstallProgressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallProgressSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInstallProgressSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    InstallProgressSubscription,
    InstallProgressSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<InstallProgressSubscription, InstallProgressSubscriptionVariables>(
    InstallProgressDocument,
    options
  );
}
export type InstallProgressSubscriptionHookResult = ReturnType<
  typeof useInstallProgressSubscription
>;
export type InstallProgressSubscriptionResult =
  Apollo.SubscriptionResult<InstallProgressSubscription>;
