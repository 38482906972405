import { useCallback, useState } from 'react';

/**
 * Hook for managing state of a boolean flag
 *
 * @param initialValue
 * @returns [value, setValueTrueFn, setValueFalseFn]
 */
export const useFlagState = (
  initialValue = false
): [boolean, () => void, () => void, () => void] => {
  const [flag, setFlagValue] = useState(initialValue);

  const setFlag = useCallback((): void => setFlagValue(true), []);
  const clearFlag = useCallback((): void => setFlagValue(false), []);

  return [flag, setFlag, clearFlag, setFlagValue] as ReturnType<typeof useFlagState>;
};
