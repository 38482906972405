import { FC, useCallback, useMemo, useRef } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { CmpPropsWithChildren } from '@diamanticom/picasa-core';

import { AuthClient, getApolloClient, GQLClient } from '$gql/client';

export const GQLProvider: FC<CmpPropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const getToken = useCallback(async () => {
    let token: string | undefined;
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      // Currently if this fails it will trigger the AuthProvider guard to redirect to login
      // TODO handle this more gracefully
      console.error('[Token Failure]', e);
    }

    return token;
  }, [getAccessTokenSilently]);
  const authClientRef = useRef<AuthClient>({ getToken });
  const apolloClient = useMemo<GQLClient>(() => getApolloClient(authClientRef.current), []);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
