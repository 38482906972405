import { FC } from 'react';
import styled from 'styled-components';
import { Card, CmpProps, Table, Typography } from '@diamanticom/picasa-core';
import { createColumnHelper } from '@tanstack/react-table';

import {
  InstallationOperation,
  InstallationStatus,
  InstallationFragment,
  InstallationNodeResult,
} from '$gql';

const Body = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const columnHelper = createColumnHelper<InstallationNodeResult>();

const columns = [
  columnHelper.accessor('name', {
    header: () => <Typography type="pBold">Name</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 80,
  }),
  columnHelper.accessor('externalIP', {
    header: () => <Typography type="pBold">External IP</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 80,
  }),
  columnHelper.accessor('internalIP', {
    header: () => <Typography type="pBold">Internal IP</Typography>,
    cell: (info) => <Typography type="p">{info.getValue()}</Typography>,
    size: 80,
  }),
];

export type InstallationNodesCardProps = CmpProps<{
  installation: InstallationFragment;
}>;

export const InstallationNodesCard: FC<InstallationNodesCardProps> = ({
  className,
  installation,
}) => {
  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header title="Nodes" />
      <Card.Body>
        {!installation.result && (
          <Body>
            <Typography type="h5" as="p">
              {installation.operation === InstallationOperation.Install &&
              installation.status === InstallationStatus.InProgress
                ? 'Waiting for installation to complete'
                : 'Installation failed, no nodes to show'}
            </Typography>
          </Body>
        )}
        {!!installation.result && <Table columns={columns} data={installation.result.nodes} />}
      </Card.Body>
    </Card>
  );
};
