import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { UserProfileInput, useUpdateUserProfileMutation } from '$gql';

export type UpdateProfileFn = (profile: UserProfileInput) => Promise<void>;

export const useUpdateProfile = (): UpdateProfileFn => {
  const { getAccessTokenSilently } = useAuth0();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  return useCallback<UpdateProfileFn>(
    async (profile) => {
      await updateUserProfile({ variables: { input: profile } });

      // Reset the token so that the user will be marked as registered in the token
      await getAccessTokenSilently({ ignoreCache: true });
    },
    [updateUserProfile, getAccessTokenSilently]
  );
};
