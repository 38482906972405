import { useCallback } from 'react';
import styled from 'styled-components';
import { FieldValues, useForm, UseFormProps } from 'react-hook-form';
import {
  Button,
  CmpPropsWithChildren,
  CmpReturn,
  Direction,
  Form,
  SubmitButton,
  Typography,
  Wizard,
} from '@diamanticom/picasa-core';

export const StepContainer = styled.div`
  margin-top: 32px;
  width: 500px;
`;

export const WizardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;
  float: right;
  margin-top: 32px;
`;

export const StepHeader = styled(Typography)`
  white-space: nowrap;
`;

export type StepProps<TFieldValues extends FieldValues = FieldValues> = CmpPropsWithChildren<{
  /**
   * This is not zero indexed, first index === 1
   */
  stepNumber: number;
  totalSteps: number;
  title: string;
  defaultValues: TFieldValues;
  submitDisabled?: boolean;
  submitText?: string;
  /**
   * i === undefined means submit wizard otherwise it represents the new index
   */
  onChange: (data: TFieldValues, i: number | undefined) => void;
}>;

export const Step = <TFieldValues extends FieldValues = FieldValues>({
  className,
  stepNumber,
  totalSteps,
  title,
  defaultValues,
  children,
  submitDisabled,
  submitText,
  onChange,
}: StepProps<TFieldValues>): CmpReturn => {
  const methods = useForm<TFieldValues>({
    defaultValues: defaultValues as UseFormProps<TFieldValues>['defaultValues'],
  });
  const handleSubmit = useCallback(
    (data: TFieldValues) => {
      onChange(data, stepNumber === totalSteps ? undefined : stepNumber + 1);
    },
    [onChange, stepNumber, totalSteps]
  );

  const handleBack = useCallback(() => {
    onChange(methods.getValues(), stepNumber - 1);
  }, [stepNumber, methods, onChange]);

  return (
    <Wizard.Step className={className} index={stepNumber} transition direction={Direction.Left}>
      <StepHeader type="h1">{title}</StepHeader>
      <Form<TFieldValues> methods={methods} onSubmit={handleSubmit}>
        <StepContainer>{children}</StepContainer>
        <WizardFooter>
          {stepNumber > 1 ? (
            <Button type="alternate" leftIcon="arrow" text="Prev" onClick={handleBack} />
          ) : (
            <div />
          )}
          <SubmitButton
            type={stepNumber === totalSteps ? 'secondary' : 'primary'}
            rightIcon={stepNumber === totalSteps ? undefined : 'arrow'}
            direction={Direction.Right}
            text={stepNumber === totalSteps ? submitText || 'Install' : 'Next'}
            waitingText={stepNumber === totalSteps ? 'Starting...' : undefined}
            disabled={submitDisabled}
          />
        </WizardFooter>
      </Form>
    </Wizard.Step>
  );
};
