import React, { ReactNode } from 'react';
import styled from 'styled-components';

import {
  Card,
  CardProps,
  CmpPropsWithChildren,
  ImageIcon,
  ImageIconType,
  Pill,
  Typography,
} from '@diamanticom/picasa-core';

const Body = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const TextGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledText = styled(Typography)`
  color: ${(props): string => props.theme.palette.gray.gray3};

  margin-top: 16px;
`;

const Actions = styled.div`
  padding-top: 16px;
  margin-top: auto;

  display: flex;
  justify-content: space-between;
`;

export type ProductCardProps = CmpPropsWithChildren<{
  title: string;
  trial: string;
  leftAction?: ReactNode;
  rightAction?: ReactNode;
  image: ImageIconType;
}> &
  CardProps;

export const ProductCard: React.FC<ProductCardProps> = ({
  title,
  trial,
  children,
  leftAction,
  rightAction,
  image,
  ...props
}) => {
  return (
    <Card {...props}>
      <Card.Body>
        <Body>
          <Header>
            <ImageIcon image={image} />
            <TextGroup>
              <Typography type="h3" as="h2">
                {title}
              </Typography>
              <Pill text={trial} type="secondary" />
            </TextGroup>
          </Header>
          <StyledText type="body" as="p">
            {children}
          </StyledText>
          <Actions>
            <div>{leftAction}</div>
            <div>{rightAction}</div>
          </Actions>
        </Body>
      </Card.Body>
    </Card>
  );
};
