import { FC } from 'react';
import { Button, CmpProps } from '@diamanticom/picasa-core';

import { ProductCard } from '$cmp/product-card/ProductCard';
import { generatePath, INSTALL } from '$utils/paths';
import { productImageMap, productNameMap, slugProductMap } from '$utils/installation';
import { ProductType } from '$gql';

export type SpektraCardProps = CmpProps;

export const SpektraCard: FC<SpektraCardProps> = ({ className }) => {
  return (
    <ProductCard
      className={className}
      title={productNameMap[ProductType.Spektra]}
      image={productImageMap[ProductType.Spektra]}
      trial="Free Forever"
      rightAction={
        <Button
          text="Install"
          to={generatePath(INSTALL, { product: slugProductMap[ProductType.Spektra] })}
        />
      }
    >
      The complete multi-cluster management platform for hybrid and multi-cloud Kubernetes cluster
      deployments. Spektra makes it easy to deploy, manage and monitor applications across these
      environments through a single pane of glass, enabling advanced data services like application
      and data mobility across deployments.
    </ProductCard>
  );
};
