import styled from 'styled-components';
import {
  CmpProps,
  CmpReturn,
  ImageIcon,
  ImageIconType,
  Typography,
} from '@diamanticom/picasa-core';
import {
  FieldPath,
  FieldValues,
  PathValue,
  UnpackNestedValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { ProductType, Provider } from '$gql';

const Container = styled.div``;

const PlatformButton = styled.div<{ enabled: boolean; top?: boolean; selected: boolean }>`
  height: 96px;
  display: flex;
  align-items: center;
  padding: 16px;
  border: ${(props): string =>
    `${props.selected ? '3px' : '1px'} solid ${
      props.selected ? props.theme.palette.primary.main : props.theme.palette.gray.gray2
    }`};
  border-radius: 8px;
  width: 500px;
  margin-top: ${(props): string => (props.top ? '42px' : '20px')};
  cursor: ${(props): string => (props.enabled ? 'pointer' : 'default')};
  background-color: ${(props): string =>
    props.enabled ? props.theme.palette.common.white : props.theme.palette.gray.gray2};

  position: relative;

  &:hover {
    background-color: ${(props): string | undefined =>
      props.enabled ? props.theme.palette.formElement.background : undefined};
  }
`;

const ComingSoonText = styled(Typography)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const PlatformDescription = styled(Typography)`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

export type Platform = {
  key: Provider;
  image: ImageIconType;
  text: string;
  enabled: boolean;
};

export type PlatformSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<UseControllerProps<TFieldValues, TName>, 'name' | 'rules'> &
  CmpProps<{
    platforms: Array<Platform>;
    defaultValue?: Provider;
    onPlatformChange: (data: string) => void;
    product: string;
  }>;

export const PlatformSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  className,
  platforms,
  name,
  rules,
  defaultValue,
  onPlatformChange,
  product,
}: PlatformSelectProps<TFieldValues, TName>): CmpReturn => {
  const {
    field: { onChange, value },
  } = useController<TFieldValues, TName>({
    name,
    rules,
    defaultValue: (defaultValue ?? platforms[0]?.key) as UnpackNestedValue<
      PathValue<TFieldValues, TName>
    >,
  });

  // const s3Url = (provider: Provider, productType: ProductType) => {
  //   return useS3DownloadUrlQuery({ variables: { provider, productType } });
  // }
  return (
    <Container className={className}>
      {platforms.map(({ key, image, text, enabled }) => {
        return (
          <PlatformButton
            key={image}
            enabled={enabled}
            id="cloudType"
            selected={value === key}
            onClick={
              enabled
                ? async (): Promise<void> => {
                    onPlatformChange(key);
                    onChange(key);
                    if (
                      product === ProductType.UltimaEnterprise &&
                      (key === Provider.Vsphere || key === Provider.Baremetal)
                    ) {
                      return;
                    }
                    if (
                      product === ProductType.Spektra &&
                      (key === Provider.Eks || key === Provider.Gke)
                    ) {
                      return;
                    }
                    if (key === Provider.Baremetal || key === Provider.Vsphere) {
                      window.open(
                        'https://diamanti.com/contact-sales/#talk-to-an-expert',
                        '_blank'
                      );
                    }
                  }
                : undefined
            }
          >
            <ImageIcon image={image} />
            <PlatformDescription>{text}</PlatformDescription>
            {!enabled && <ComingSoonText type="pBold">Coming Soon</ComingSoonText>}
          </PlatformButton>
        );
      })}
    </Container>
  );
};
