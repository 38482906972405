import { isEqual } from 'lodash';
import { generatePath as __generatePath, matchPath, Params } from 'react-router-dom';
import { stringifyQS } from './functions';

export const ROOT = '/';

export const PRODUCTS = ROOT;
export type ProductsParams = void;
export type ProductsQueryParams = void;

export const INSTALL = '/install/:product';
export type InstallParams = { product: string };
export type InstallQueryParams = void;

export const INSTALLATION = '/installation/:id';
export type InstallationParams = { id: string };
export type InstallationQueryParams = void;

export const ADMIN_DASHBOARD = '/admin';
export type AdminDashboardParams = void;
export type AdminDashboardQueryParams = void;

export const UltimaS = '/install/ultimastorage';
export type UltimaSParams = void;
export type UltimaSQueryParams = void;

type ParamMapBase = Record<
  string,
  { routeParams: Record<string, unknown> | void; queryParams: Record<string, unknown> | void }
>;

type ParamMap = {
  [PRODUCTS]: { routeParams: ProductsParams; queryParams: ProductsQueryParams };
  [INSTALL]: { routeParams: InstallParams; queryParams: InstallQueryParams };
  [INSTALLATION]: {
    routeParams: InstallationParams;
    queryParams: InstallationQueryParams;
  };
  [ADMIN_DASHBOARD]: {
    routeParams: AdminDashboardParams;
    queryParams: AdminDashboardQueryParams;
  };
  [UltimaS]: {
    routeParams: UltimaSParams;
    queryParams: UltimaSQueryParams;
  };
};

export const generatePath = <
  TParamMapKey extends keyof TParamMap,
  TParamMap extends ParamMapBase = ParamMap
>(
  pattern: TParamMapKey,
  routeParams: TParamMap[TParamMapKey]['routeParams'] = {},
  queryParams: TParamMap[TParamMapKey]['queryParams'] = {}
): string => {
  const baseRoute = __generatePath(pattern as string, routeParams as Params<string>);
  const routeWithQuery = isEqual(queryParams, {})
    ? baseRoute
    : `${baseRoute}${stringifyQS<TParamMap[TParamMapKey]['queryParams']>(queryParams)}`;
  return routeWithQuery;
};

const __isMatch =
  (pathname: string) =>
  (path: string): boolean => {
    const match = matchPath(pathname, path);
    return !!match;
  };

export const _isMatch = (pathname: string, paths: string | string[]): boolean => {
  if (Array.isArray(paths)) {
    return paths.some(__isMatch(pathname));
  }

  return __isMatch(pathname)(paths);
};
