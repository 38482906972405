import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CmpProps, CmpReturn, Menu, MenuOption, User } from '@diamanticom/picasa-core';
import { useAuth0 } from '@auth0/auth0-react';

const StyledButton = styled.button<{ $active: boolean }>`
  height: 100%;
  width: 100%;

  padding: 0px 14px;

  display: flex;
  align-items: center;

  background: ${(props): string | undefined =>
    props.$active ? props.theme.palette.primary.light : undefined};

  &:hover {
    background: ${(props): string => props.theme.palette.primary.light};
  }
`;

export type AuthUserProps = CmpProps<{ showName?: boolean }>;

export const AuthUser: FC<AuthUserProps> = ({ showName }) => {
  const menuOpenState = useState(false);

  const { logout, user } = useAuth0();

  const options = useMemo<MenuOption[]>(
    () => [
      {
        key: 'logout',
        text: 'Log Out',
        onClick: (): void => {
          logout({ returnTo: window.location.origin });
        },
      },
    ],
    [logout]
  );

  return (
    <Menu options={options} menuOpenState={menuOpenState} placement="right">
      {({ getProps }): CmpReturn => (
        <StyledButton {...getProps()} $active={menuOpenState[0]}>
          <User
            userName={showName ? user?.name : undefined}
            imageSrc={user?.picture || ''}
            inverted
          />
        </StyledButton>
      )}
    </Menu>
  );
};
