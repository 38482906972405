import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, useAuth0, User } from '@auth0/auth0-react';
import { CmpPropsWithChildren } from '@diamanticom/picasa-core';

import { env } from '$utils/env';

export const getCompletedRegistration = (user: User | undefined): boolean => {
  return !!user?.['https://diamanti.com/claims/completedRegistration'];
};

export const getRoles = (user: User | undefined): string[] => {
  return user?.['https://diamanti.com/claims/roles'];
};

/**
 * Guards app from rendering unless user is authenticated
 */
const AuthGuard: FC<CmpPropsWithChildren> = ({ children }) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ prompt: 'login' });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  const completedRegistration = getCompletedRegistration(user);

  useEffect(() => {
    if (!isLoading && !completedRegistration) {
      getAccessTokenSilently({ ignoreCache: true });
    }
  }, [isLoading, completedRegistration, getAccessTokenSilently]);

  if (isLoading || !isAuthenticated) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

/**
 * Auth0 Auth Provider abstraction with auth guard for the entire app
 */
export const AuthProvider: FC<CmpPropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={env.AUTH0_CUSTOM_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      audience={env.AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <AuthGuard>{children}</AuthGuard>
    </Auth0Provider>
  );
};
