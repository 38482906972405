import { FC } from 'react';
import styled from 'styled-components';
import { Card, CmpProps, LabeledValue } from '@diamanticom/picasa-core';

import { InstallationFragment } from '$gql';
import { getConfigLabeledValues } from '$utils/installation';

const Body = styled.div`
  width: 100%;

  padding-top: 8px;

  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
`;

export type InstallationConfigCardProps = CmpProps<{
  installation: InstallationFragment;
}>;

export const InstallationConfigCard: FC<InstallationConfigCardProps> = ({
  className,
  installation,
}) => {
  const labeledValues = getConfigLabeledValues(installation);

  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header title="Config" />
      <Card.Body>
        <Body>
          {labeledValues.map(({ label, value }) => (
            <LabeledValue label={label} value={value} key={label} />
          ))}
        </Body>
      </Card.Body>
    </Card>
  );
};
