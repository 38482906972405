import {
  GroundworkInstallationFragment,
  InstallationFragment,
  InstallationOperation,
  InstallationStatus,
  ProductType,
  Provider,
  SpektraInstallationFragment,
  UltimaEnterpriseInstallationFragment,
} from '$gql';
import { ImageIconType, PillType } from '@diamanticom/picasa-core';

export const isGroundworkInstall = (
  installation: InstallationFragment
): installation is GroundworkInstallationFragment => {
  return installation.__typename === 'GroundworkInstallation';
};

export const isSpektraInstall = (
  installation: InstallationFragment
): installation is SpektraInstallationFragment => {
  return installation.__typename === 'SpektraInstallation';
};

export const isUltimaEnterpriseInstall = (
  installation: InstallationFragment
): installation is UltimaEnterpriseInstallationFragment => {
  return installation.__typename === 'UltimaEnterpriseInstallation';
};

export const getConfigLabeledValues = (
  installation: InstallationFragment
): Array<{ label: string; value: string }> => {
  if (isSpektraInstall(installation)) {
    if (installation.config.GCP) {
      const { name, zone, projectId, imageName } = installation.config.GCP;

      return [
        { label: 'Cluster Name', value: name },
        { label: 'Project ID', value: projectId },
        { label: 'Zone', value: zone },
        { label: 'Image Name', value: imageName },
      ];
    }
    if (installation.config.AWS) {
      const { name, zone, imageName } = installation.config.AWS;

      return [
        { label: 'Cluster Name', value: name },
        { label: 'Zone', value: zone },
        { label: 'Image Name', value: imageName },
      ];
    }
  }

  if (isGroundworkInstall(installation)) {
    if (installation.config.GCP) {
      const { name, zone, projectId, imageName } = installation.config.GCP;

      return [
        { label: 'Cluster Name', value: name },
        { label: 'Project ID', value: projectId },
        { label: 'Zone', value: zone },
        { label: 'Image Name', value: imageName },
      ];
    }
  }

  if (isUltimaEnterpriseInstall(installation)) {
    if (installation.config.GCP) {
      const {
        clusterName,
        numNodes,
        zone,
        projectId,
        machineType,
        numDrives,
        driveType,
        imageName,
      } = installation.config.GCP;

      return [
        { label: 'Cluster Name', value: clusterName },
        { label: 'Project ID', value: projectId },
        { label: 'Number of VMs', value: `${numNodes}` },
        { label: 'Machine Type', value: machineType },
        { label: 'Number of Drives', value: `${numDrives}` },
        { label: 'Drive Type', value: driveType },
        { label: 'Zone', value: zone },
        { label: 'Target Pool Name', value: `${clusterName}-targets` },
        { label: 'Load Balancer Name', value: `${clusterName}-lb` },
        { label: 'Image Name', value: imageName },
      ];
    }
    if (installation.config.AWS) {
      const { clusterName, numNodes, zone, machineType, imageName } = installation.config.AWS;

      return [
        { label: 'Cluster Name', value: clusterName },
        { label: 'Number of VMs', value: `${numNodes}` },
        { label: 'Machine Type', value: machineType },
        { label: 'Zone', value: zone },
        { label: 'Image Name', value: imageName },
      ];
    }
  }

  return [];
};

export const getStatusText = (installation: InstallationFragment): string => {
  if (installation.operation === InstallationOperation.Install) {
    switch (installation.status) {
      case InstallationStatus.InProgress:
        return 'Installing...';
      case InstallationStatus.Done:
        return installation.config.cloudType === Provider.Vsphere ||
          installation.config.cloudType === Provider.Baremetal ||
          installation.config.cloudType === Provider.Eks ||
          installation.config.cloudType === Provider.Gke
          ? 'Enabled'
          : 'Installed';
      case InstallationStatus.FailedInProgress:
        return 'Failure Cleanup...';
      case InstallationStatus.Failed:
        return 'Install Failed';
      default:
        return 'Unknown';
    }
  }

  switch (installation.status) {
    case InstallationStatus.InProgress:
      return 'Uninstalling...';
    case InstallationStatus.Done:
      return 'Uninstalled';
    case InstallationStatus.Failed:
      return 'Uninstall Failed';
    default:
      return 'Unknown';
  }
};

export const getStatusType = (installation: InstallationFragment): PillType => {
  if (installation.status === InstallationStatus.InProgress) return 'info';

  if (
    installation.status === InstallationStatus.Failed ||
    installation.status === InstallationStatus.FailedInProgress
  )
    return 'error';

  if (installation.operation === InstallationOperation.Install) return 'success';

  return 'alternate';
};

export const getProgressMessage = (installation: InstallationFragment): string => {
  console.log('installation progress messages here: ', installation);
  if (installation.operation === InstallationOperation.Install) {
    if (installation.status === InstallationStatus.InProgress) {
      return installation.installProgressMessage || '';
    }
    if (installation.status === InstallationStatus.FailedInProgress) {
      return installation.installFailedProgressMessage
        ? installation.installFailedProgressMessage
        : installation.installFailureMessage || '';
    }
  }
  if (
    installation.operation === InstallationOperation.Uninstall &&
    installation.status === InstallationStatus.InProgress
  ) {
    return installation.uninstallProgressMessage || '';
  }

  return '';
};

const ultimaEnterpriseSlug = 'ultima-enterprise';
const spektraSlug = 'spektra';
const groundworkSlug = 'groundwork';

export const slugProductMap: Record<ProductType, string> = {
  [ProductType.UltimaEnterprise]: ultimaEnterpriseSlug,
  [ProductType.Spektra]: spektraSlug,
  [ProductType.Groundwork]: groundworkSlug,
};

export const productSlugMap: Record<string, ProductType> = {
  [ultimaEnterpriseSlug]: ProductType.UltimaEnterprise,
  [spektraSlug]: ProductType.Spektra,
  [groundworkSlug]: ProductType.Groundwork,
};

export const productImageMap: Record<ProductType, ImageIconType> = {
  [ProductType.UltimaEnterprise]: 'UltimaLogo',
  [ProductType.Spektra]: 'SpektraLogo',
  [ProductType.Groundwork]: 'GroundworkLogo',
};

export const productNameMap: Record<ProductType, string> = {
  [ProductType.UltimaEnterprise]: 'Ultima Enterprise',
  [ProductType.Spektra]: 'Spektra',
  [ProductType.Groundwork]: 'Groundwork',
};

export const productVersionMap: Record<ProductType, Record<Provider, any>> = {
  [ProductType.UltimaEnterprise]: {
    [Provider.Gcp]: 'ultima-enterprise-3-8-2-48',
    [Provider.Aws]: {
      'us-east-1': 'ami-06b621edaf31ab58d',
      'us-east-2': 'ami-00c9ddd55e03156a3',
      'us-west-1': 'ami-0ada4c38c9246dcf1',
      'us-west-2': 'ami-08f31caeb35f1273b',
      'ca-central-1': 'ami-00326097e6eeb4665',
      'eu-central-1': 'ami-06ac9320805f67043',
      'eu-west-1': 'ami-03ef2573155e615c9',
      'eu-west-2': 'ami-05fbde48d6ebc3c12',
      'eu-west-3': 'ami-02d0adf5183a57ae4',
      'eu-north-1': 'ami-02ac8c178c3a1b4f3',
      'ap-south-1': 'ami-0a4fc832d7bebdea5',
      'ap-northeast-1': 'ami-0119ee01ed693ff95',
      'ap-northeast-2': 'ami-04d06c0f6c9e31843',
      'ap-northeast-3': 'ami-0ce1ab4bbce0b06fb',
      'ap-southeast-1': 'ami-05ea3616f48fab51a',
      'ap-southeast-2': 'ami-0303dedc84021f944',
    },
    [Provider.Vsphere]: 'Vsphere',
    [Provider.Baremetal]: 'Baremetal',
    [Provider.Eks]: 'EKS',
    [Provider.Gke]: 'GKE',
  },
  [ProductType.Spektra]: {
    [Provider.Gcp]: 'spektra-k8s-v3-9-0-13-20240311085948',
    [Provider.Aws]: 'ami-0287a8353c4c24c1d',
    [Provider.Vsphere]: 'Vsphere',
    [Provider.Baremetal]: 'Baremetal',
    [Provider.Eks]: 'EKS',
    [Provider.Gke]: 'GKE',
  },
  [ProductType.Groundwork]: {
    [Provider.Gcp]: 'groundwork-8-7-4-20240319092730',
    [Provider.Aws]: 'ami-0adc8a101f45dbb28',
    [Provider.Vsphere]: 'Vsphere',
    [Provider.Baremetal]: 'Baremetal',
    [Provider.Eks]: 'EKS',
    [Provider.Gke]: 'GKE',
  },
};

export const providerImageMap: Record<Provider, ImageIconType> = {
  [Provider.Gcp]: 'GCP',
  [Provider.Aws]: 'AWS',
  [Provider.Vsphere]: 'Vsphere',
  [Provider.Baremetal]: 'Baremetal',
  [Provider.Eks]: 'EKSLogo',
  [Provider.Gke]: 'GKELogo',
};

export const providerNameMap: Record<Provider, string> = {
  [Provider.Gcp]: 'Google Cloud Platform',
  [Provider.Aws]: 'Amazon Web Services',
  [Provider.Vsphere]: 'Vsphere',
  [Provider.Baremetal]: 'Baremetal',
  [Provider.Eks]: 'Elastic Kubernetes Service',
  [Provider.Gke]: 'Google Kubernetes Engine',
};
