import { FC, useCallback } from 'react';
import { BaseSubscriptionOptions } from '@apollo/client';

import {
  InstallationOperation,
  InstallationStatus,
  InstallProgressSubscription,
  InstallProgressSubscriptionVariables,
  useCanInstallUltimaEnterpriseLazyQuery,
  useInstallProgressSubscription,
} from '$gql';

export const InstallationWatcher: FC = () => {
  const [execQuery] = useCanInstallUltimaEnterpriseLazyQuery({ fetchPolicy: 'network-only' });
  const onSubscriptionData = useCallback<
    NonNullable<
      BaseSubscriptionOptions<
        InstallProgressSubscription,
        InstallProgressSubscriptionVariables
      >['onSubscriptionData']
    >
  >(
    (result) => {
      const installation = result.subscriptionData.data?.installProgress;
      if (!installation) return;

      const { operation, status } = installation;

      if (operation === InstallationOperation.Install && status === InstallationStatus.Failed) {
        execQuery();
      }
    },
    [execQuery]
  );
  useInstallProgressSubscription({ onSubscriptionData });

  return null;
};
