import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';

import { env } from '$utils/env';
import { typePolicies } from '$gql/typePolicies';
import { GQLErrorType } from '$utils/enums';

export type GQLClient = ApolloClient<NormalizedCacheObject>;

export type GQLIdFn = (obj: Partial<{ __typename: string; uuid: string }>) => string | undefined;

export type AuthClient = {
  getToken: () => Promise<string | undefined>;
};

export const getApolloClient = (authClient: AuthClient): GQLClient => {
  const apolloCache = new InMemoryCache({
    possibleTypes: {
      Installation: [
        'UltimaEnterpriseInstallation',
        'SpektraInstallation',
        'GroundworkInstallation',
      ],
    },
    typePolicies,
  });

  const httpUrl = new URL('graphql', env.API_HTTP_URL).href;

  const authLink = setContext(async (_, { headers }) => {
    const token = await authClient.getToken();

    if (!token) return headers;

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const httpLink = authLink.concat(
    new HttpLink({
      uri: httpUrl,
    })
  );

  const wsLink = env.API_WS_URL
    ? new GraphQLWsLink(
        createClient({
          lazy: true,
          url: new URL('graphql', env.API_WS_URL).href,
          connectionParams: async () => {
            const token = await authClient.getToken();

            if (!token) return {};

            return {
              Authorization: token ? `Bearer ${token}` : '',
            };
          },
        })
      )
    : undefined;

  const errorLink = onError((o) => {
    const gqlError = (o.graphQLErrors || [])[0] || {};
    const firstErrorText = gqlError.message || '';
    const firstErrorCode = gqlError.extensions?.code || '';

    if (firstErrorCode === GQLErrorType.Unauthenticated) {
      console.error('Unauthenticated');
      return;
    }

    if (firstErrorText) {
      console.error('Server Error:', `[${firstErrorCode}]`, firstErrorText);
    }
  });

  const httpWsLink = wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, httpWsLink]),
    cache: apolloCache,
    connectToDevTools: true,
  });

  return apolloClient;
};
