declare global {
  interface Window {
    env: {
      API_HTTP_URL: string;
      API_WS_URL: string;
      AUTH0_DOMAIN: string;
      AUTH0_CUSTOM_DOMAIN: string;
      AUTH0_CLIENT_ID: string;
      AUTH0_AUDIENCE: string;
    };
  }
}

export const { env } = window;
