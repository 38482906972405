import { FC, useState } from 'react';
import { CmpReturn, Input, Label, Select, Typography } from '@diamanticom/picasa-core';
import { Step, StepProps } from '$cmp/install-modal/step/Step';
import { ZoneOption, ZONES } from '$utils/lists/zones';
import { FormGroup } from '$cmp/install-modal/common';

export type GcpGroundworkConfigForm = {
  name: string;
  groundworkPassword: string;
  groundworkConfirmPassword: string;
  zone: ZoneOption;
};

export const gcpGroundworkConfigFormDefault: GcpGroundworkConfigForm = {
  name: '',
  groundworkPassword: '',
  groundworkConfirmPassword: '',
  zone: ZONES[0],
};

export type GcpGroundworkConfigStepProps = Omit<
  StepProps<GcpGroundworkConfigForm>,
  'title' | 'children'
>;

export const GcpGroundworkConfigStep: FC<GcpGroundworkConfigStepProps> = (props) => {
  const [password, setPassword] = useState<string>('');
  return (
    <Step {...props} title="Configuration">
      <FormGroup>
        <Typography type="h4" as="h2">
          Kubernetes
        </Typography>
        <Label
          text="Name"
          required
          helperText="This name will be used as a prefix for all created resources"
        >
          {(id: string): CmpReturn => (
            <Input
              id={id}
              name="name"
              placeholder="Enter name"
              options={{
                required: 'Name is required',
                pattern: {
                  value: /^[a-z][a-z0-9-]{1,50}[a-z0-9]$/,
                  message:
                    'Name is invalid.  Name must contain lowercase alphanumeric characters, 3-50 characters long, starting with alphabetic characers, ending with alphanumeric and can include the "-" character',
                },
              }}
            />
          )}
        </Label>
        <Label
          text={`Password for "admin" user`}
          required
          helperText="This is the password for the initial admin account in Groundwork"
        >
          {(id: string): CmpReturn => (
            <Input
              id={id}
              type="password"
              name="groundworkPassword"
              placeholder="Enter password"
              options={{
                required: 'Password is required',
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
                  message:
                    'Password must be a minimum of eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character: @$!%*?&',
                },
                onChange: (event): void => {
                  setPassword(event.target.value);
                },
              }}
            />
          )}
        </Label>
        <Label text="Confirm Password" required>
          {(id: string): CmpReturn => (
            <Input
              id={id}
              type="password"
              name="groundworkConfirmPassword"
              placeholder="Enter Confirm Password"
              options={{
                required: 'Confirm password is required',
                validate: (val): string | boolean | undefined => {
                  try {
                    if (val === password) {
                      return true;
                    }
                    return 'Password do not match';
                  } catch (e) {
                    return 'Password do not match';
                  }
                },
              }}
            />
          )}
        </Label>
      </FormGroup>
      <FormGroup style={{ paddingTop: '25px' }}>
        <Typography type="h4" as="h2">
          Infrastructure
        </Typography>
        <Label text="GCP Zone" required>
          {(id: string): CmpReturn => (
            <Select
              id={id}
              name="zone"
              placeholder="Select zone"
              rules={{ required: 'Zone is required' }}
              options={ZONES}
            />
          )}
        </Label>
      </FormGroup>
    </Step>
  );
};
