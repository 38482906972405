import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  CmpProps,
  Form,
  Page,
  BareSelect,
  Typography,
} from '@diamanticom/picasa-core';

import { useGetUsersQuery, useAllInstallationsQuery, ProductType } from '$gql';
import { BareInstallationTable } from '$cmp/installation-table/BareInstallationTable';
import { SupportButton } from '$cmp/contact/support-button/SupportButton';
import { downloadObjectAsJson } from '$utils/functions';
import { useAuth0 } from '@auth0/auth0-react';
import { FormGroup } from '$cmp/install-modal/common';

export type SelectOption = { label: string; value: string };
const CardGrid = styled.div`
  flex: 1;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(300px, auto) minmax(300px, 1fr);
`;

const ProductsNullState = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductsNullStateInner = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
`;

const NullTitle = styled(Typography)`
  text-align: center;
`;

const InfoHead = styled(Typography)`
  text-align: center;
  display: block;
  padding: 0.5rem;
`;

const UserFlexDivParent = styled.div`
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;
const UserFlexDiv = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  flex: 1;
`;

const BareselectParent = styled.div`
  width: 350px;
`;

const FlexDiv = styled.div`
  padding: 1rem;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  select {
    padding: 1rem;
  }
`;

const ALL_USERS = 'All Users';
const ALL_COMPANIES = 'All Companies';
export type AdminDashboardPageProps = CmpProps;
export const AdminDashboardPage: FC<AdminDashboardPageProps> = ({ className }) => {
  const allInstallationsQuery = useAllInstallationsQuery({ fetchPolicy: 'network-only' });
  const getUsersQuery = useGetUsersQuery({ fetchPolicy: 'network-only' });
  const installations = allInstallationsQuery.data?.allInstallations;
  const allUsers = getUsersQuery.data?.getUsers;
  const { user } = useAuth0();
  const [selectedInstallations, setSelectedInstallations] = useState(installations);
  const [selectedUser, setSelectedUser] = useState(ALL_USERS);
  const [selectedCompany, setSelectedCompany] = useState(ALL_COMPANIES);
  const [selectedUserDetails, setSelectedUserDetails] = useState<any>(null);
  const [allCompaniesOptions, setAllCompaniesOptions] = useState<SelectOption[]>([
    {
      label: ALL_COMPANIES,
      value: ALL_COMPANIES,
    },
  ]);

  const [allUsersOptions, setAllUsersOptions] = useState<SelectOption[]>([
    {
      label: ALL_USERS,
      value: ALL_USERS,
    },
  ]);

  const downloadAllReports = async (): Promise<void> => {
    const userInstallationResult: any[] = [
      {
        userProfile: selectedUserDetails,
        totalUsers: selectedUser === ALL_USERS ? allUsers?.length : 1,
        totalInstallations: selectedInstallations?.length,
        Spektra: selectedInstallations?.filter((item) => item.product === ProductType.Spektra)
          .length,
        UltimaEnterprise: selectedInstallations?.filter(
          (item) => item.product === ProductType.UltimaEnterprise
        ).length,
        Groundwork: selectedInstallations?.filter((item) => item.product === ProductType.Groundwork)
          .length,
      },
    ];
    allUsers?.forEach((userTemp) => {
      const filteredInstallations = selectedInstallations?.filter(
        (each) => each.userId === userTemp.id
      );
      userInstallationResult.push({
        ...user,
        installations: filteredInstallations,
      });
    });
    downloadObjectAsJson(userInstallationResult, `installations_${selectedUser}`);
  };

  useEffect(() => {
    if (installations && selectedCompany && selectedUser) {
      filterUsers(selectedCompany, selectedUser);
    }
  }, [installations, selectedUser]);

  useEffect(() => {
    if (installations && selectedCompany) {
      if (selectedCompany !== ALL_COMPANIES) {
        const companyUsers =
          allUsers?.filter((eachUser) => eachUser.company === selectedCompany) || [];
        setAllUsersOptions([
          {
            label: ALL_USERS,
            value: ALL_USERS,
          },
          ...companyUsers.map((each) => ({
            label: each.email,
            value: each.email,
          })),
        ]);
      } else {
        setAllUsersOptions([
          {
            label: ALL_USERS,
            value: ALL_USERS,
          },
          ...(allUsers || []).map((each) => ({
            label: each.email,
            value: each.email,
          })),
        ]);
      }
      setSelectedUser(ALL_USERS);
      filterUsers(selectedCompany, ALL_USERS);
    }
  }, [selectedCompany]);

  const filterUsers = (companyName: string, userEmail: string): void => {
    if (installations) {
      if (userEmail === ALL_USERS && companyName === ALL_COMPANIES) {
        setSelectedInstallations([...installations]);
        setSelectedUserDetails(null);
      } else if (userEmail === ALL_USERS) {
        const companyUsers = allUsers
          ?.filter((item) => item.company === companyName)
          ?.map((userTemp) => userTemp.id);
        const temp = installations?.filter((each) => companyUsers?.includes(each.userId));
        setSelectedInstallations([...temp]);
        setSelectedUserDetails(null);
      } else {
        const userTemp = allUsers?.filter((item) => item.email === selectedUser)[0];
        const temp = installations?.filter((each) => each.userId === userTemp?.id);
        setSelectedInstallations([...temp]);
        setSelectedUserDetails(userTemp);
      }
    }
  };

  useEffect(() => {
    if (allUsers && allUsers?.length > 0) {
      setAllUsersOptions([
        {
          label: ALL_USERS,
          value: ALL_USERS,
        },
        ...allUsers.map((each) => ({
          label: each.email,
          value: each.email,
        })),
      ]);

      const companies = allUsers?.map((item) => item.company);
      const temp: string[] = [];
      companies?.forEach((each) => {
        if (each && !temp.includes(each)) {
          temp.push(each);
        }
      });
      const temp2 = [
        {
          label: ALL_COMPANIES,
          value: ALL_COMPANIES,
        },
        ...temp.map((each) => ({
          label: each,
          value: each,
        })),
      ];
      setAllCompaniesOptions(temp2);
    }
  }, [allUsers]);

  return (
    <Page className={className} title="Admin Dashboard" headerRight={<SupportButton />}>
      <CardGrid>
        <Card style={{ gridColumn: 'span 3', overflow: 'visible' }}>
          <FlexDiv>
            <Form onSubmit={downloadAllReports}>
              <FormGroup style={{ width: '750px', zIndex: 999, display: 'flex', flexFlow: 'row' }}>
                <BareselectParent style={{ paddingRight: '0.5rem' }}>
                  <BareSelect
                    id="companySelectMenuAdminDashboard"
                    name="company"
                    placeholder={ALL_COMPANIES}
                    value={{ label: selectedCompany, value: selectedCompany }}
                    onChange={(values: any): void => setSelectedCompany(values.value)}
                    options={allCompaniesOptions}
                  />
                </BareselectParent>
                <BareselectParent>
                  <BareSelect
                    id="userSelectMenuAdminDashboard"
                    name="user"
                    placeholder={ALL_USERS}
                    value={{ label: selectedUser, value: selectedUser }}
                    onChange={(values: any): void => setSelectedUser(values.value)}
                    options={allUsersOptions}
                  />
                </BareselectParent>
              </FormGroup>
            </Form>
            <Button onClick={downloadAllReports} text="Download" style={{ width: '200px' }} />
          </FlexDiv>
          {selectedUserDetails && (
            <div>
              <UserFlexDivParent>
                <UserFlexDiv style={{ flex: 2 }}>
                  <div>
                    <InfoHead type="h5">Name:</InfoHead>
                  </div>
                  <div>
                    <InfoHead type="h5Bold">{`${selectedUserDetails?.firstName || '-'} ${
                      selectedUserDetails?.lastName || '-'
                    }`}</InfoHead>
                  </div>
                </UserFlexDiv>
                <UserFlexDiv>
                  <InfoHead type="h5">Company:</InfoHead>
                  <InfoHead type="h5Bold">{`${selectedUserDetails?.company || '-'}`}</InfoHead>
                </UserFlexDiv>
                <UserFlexDiv>
                  <InfoHead type="h5">Email:</InfoHead>
                  <InfoHead type="h5Bold">{`${selectedUserDetails?.email}`}</InfoHead>
                </UserFlexDiv>
                <UserFlexDiv>
                  <InfoHead type="h5">Job Title:</InfoHead>
                  <InfoHead type="h5Bold">{`${selectedUserDetails?.jobTitle || '-'}`}</InfoHead>
                </UserFlexDiv>
                <UserFlexDiv>
                  <InfoHead type="h5">Phone:</InfoHead>
                  <InfoHead type="h5Bold">{`${selectedUserDetails?.phone || '-'}`}</InfoHead>
                </UserFlexDiv>
              </UserFlexDivParent>
            </div>
          )}
          <Card.Header
            title={`All Installations (${
              selectedInstallations?.length || 0
            })  Ultima Enterprise: (${
              selectedInstallations?.filter((item) => item.product === ProductType.UltimaEnterprise)
                .length || 0
            }) Spektra: (${
              selectedInstallations?.filter((item) => item.product === ProductType.Spektra)
                .length || 0
            }) Groundwork: (${
              selectedInstallations?.filter((item) => item.product === ProductType.Groundwork)
                .length || 0
            })`}
          />
          <Card.Body>
            {selectedInstallations && !!selectedInstallations.length && (
              <BareInstallationTable data={selectedInstallations} />
            )}
            {selectedInstallations && !selectedInstallations.length && (
              <ProductsNullState>
                <ProductsNullStateInner>
                  <NullTitle type="h4">No products installed!</NullTitle>
                </ProductsNullStateInner>
              </ProductsNullState>
            )}
          </Card.Body>
        </Card>
      </CardGrid>
    </Page>
  );
};
