import { FC } from 'react';
import styled from 'styled-components';
import { Card, CmpProps, LabeledValue } from '@diamanticom/picasa-core';

import { InstallationFragment, Provider } from '$gql';
import { getConfigLabeledValues } from '$utils/installation';
import {
  CONFIG_CHECKLIST_VSPHERE,
  CONFIG_CHECKLIST_BAREMETAL,
  CONFIG_CHECKLIST_EKS,
  CONFIG_CHECKLIST_GKE,
} from '$utils/lists/installationRequirements';

const Body = styled.div`
  width: 100%;

  padding-top: 8px;

  display: grid;
  gap: 24px;
`;

const ListElems = styled.div`
  ul {
    list-style-type: disc;
  }
  li {
    margin-top: 1rem;
    margin-left: 1rem;
  }
`;
export type InstallationConfigChecklistCardProps = CmpProps<{
  installation: InstallationFragment;
  provider: Provider;
}>;

export const InstallationConfigChecklistCard: FC<InstallationConfigChecklistCardProps> = ({
  className,
  installation,
  provider,
}) => {
  const labeledValues = getConfigLabeledValues(installation);

  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header
        title={
          provider === Provider.Eks || Provider.Gke ? 'Prerequisites' : 'Configuration Checklist'
        }
      />
      <Card.Body>
        <Body>
          <ListElems>
            {provider === Provider.Vsphere && (
              <ul>
                {CONFIG_CHECKLIST_VSPHERE.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            )}
            {provider === Provider.Baremetal && (
              <ul>
                {CONFIG_CHECKLIST_BAREMETAL.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            )}
            {provider === Provider.Eks && (
              <ul>
                {CONFIG_CHECKLIST_EKS.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            )}
            {provider === Provider.Gke && (
              <ul>
                {CONFIG_CHECKLIST_GKE.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            )}
          </ListElems>
          {labeledValues.map(({ label, value }) => (
            <LabeledValue label={label} value={value} key={label} />
          ))}
        </Body>
      </Card.Body>
    </Card>
  );
};
