import { FC } from 'react';
import styled from 'styled-components';
import { Button, Card, CmpProps, Typography } from '@diamanticom/picasa-core';
import ReactApexChart from 'react-apexcharts';
import {
  InstallationFragment,
  ProductType,
  Provider,
  useS3DownloadUrlLazyQuery,
  useUpdateSpektraInstallationMutation,
  useUpdateUltimaEnterpriseInstallationMutation,
} from '$gql';
import { getTrialCounts } from '$utils/functions';

const Body = styled.div`
  position: relative;
  display: flex;
  bottom: 30px;
  flex-direction: column;
`;

const RadialChartContainer = styled.div`
  overflow: hidden;
  max-height: 200px;
`;

const CenteredBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RemainingTextDiv = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
`;
const ButtonContainer = styled.div`
  width: 100%;
  span {
    font-size: 13px !important;
  }
`;
const RadialChart = styled(ReactApexChart).attrs<{
  remaining: number;
  series: number[];
}>(({ series, remaining, theme }) => ({
  series,
  type: 'radialBar',
  height: 400,
  options: {
    colors: ['#20E647'], // TODO:
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: `${theme.palette.primary.faded}`,
          startAngle: -90,
          endAngle: 90,
        },
        dataLabels: {
          name: {
            show: true,
            color: `${theme.palette.common.black}`,
            fontSize: `${theme.typography.pBold.fontSize}`,
            fontFamily: `${theme.typography.pBold.fontFamily}`,
            fontWeight: `${theme.typography.pBold.fontWeight}`,
          },
          value: {
            fontSize: `${theme.typography.h3.fontSize}`,
            fontWeight: `${theme.typography.h3.fontWeight}`,
            offsetY: -38,
            fontFamily: `${theme.typography.h3.fontFamily}`,
            show: true,
            formatter: (): string => {
              return `${remaining}`;
            },
          },
        },
      },
    },
    fill: {
      type: 'solid',
      colors: ['#3692C4'], // TODO:
    },
    stroke: {
      lineCap: 'butt',
    },
    labels: ['Days'],
  },
}))``;

export type InstallationTrialCardProps = CmpProps<{
  installation: InstallationFragment;
}>;

export const InstallationTrialCard: FC<InstallationTrialCardProps> = ({
  className,
  installation,
}) => {
  const { total, remaining } = getTrialCounts(
    installation.createdAt,
    installation.trialExpirationDate
  );
  const expired = new Date(installation.trialExpirationDate) < new Date();
  const percentage = (remaining / total) * 100;
  const series = [percentage];
  const [execQuery] = useS3DownloadUrlLazyQuery();
  const [updateUltimaEnterpriseInstallation] = useUpdateUltimaEnterpriseInstallationMutation();
  const [updateSpektraInstallation] = useUpdateSpektraInstallationMutation();

  const handleGenerateNewLink = async (
    installationFragment: InstallationFragment
  ): Promise<void> => {
    const query = await execQuery({
      variables: {
        provider: installation.config.cloudType,
        productType: installationFragment.product,
      },
    });

    let s3DownloadLink;
    switch (installation.config.cloudType) {
      case Provider.Baremetal:
        s3DownloadLink = 'new-bm-link';
        break;
      case Provider.Eks:
        s3DownloadLink = 'https://diamanti-software.s3.amazonaws.com/spektra-3.6.0-15.tgz';
        break;
      case Provider.Gke:
        s3DownloadLink = 'https://diamanti-software.s3.amazonaws.com/spektra-3.6.0-15.tgz';
        break;
      case Provider.Vsphere:
        s3DownloadLink = 'https://diamanti-software.s3.amazonaws.com/dvx-3.4.2-108.ova';
        break;
      default:
        break;
    }

    if (
      installation.config.cloudType === Provider.Vsphere ||
      installation.config.cloudType === Provider.Eks ||
      installation.config.cloudType === Provider.Gke
    ) {
      window.open(query.data?.s3DownloadUrl.downloadUrl as string, '_blank');
    }

    if (installation.config.cloudType === Provider.Vsphere || Provider.Baremetal) {
      await updateUltimaEnterpriseInstallation({
        variables: {
          id: installationFragment.id,
          input: {
            cloudType: installation.config.cloudType,
            [installation.config.cloudType]: {
              // s3DownloadLink: query.data?.s3DownloadUrl.downloadUrl as string,
              s3DownloadLink,
            },
          },
        },
      });
    }
    if (installation.config.cloudType === Provider.Eks || Provider.Gke) {
      await updateSpektraInstallation({
        variables: {
          id: installationFragment.id,
          input: {
            cloudType: installation.config.cloudType,
            [installation.config.cloudType]: {
              s3DownloadLink,
            },
          },
        },
      });
    }
  };

  const handleLink = (): void => {
    if (expired) {
      handleGenerateNewLink(installation);
    } else {
      window.open(installation.result?.downloadLinkResult?.s3DownloadLink as string, '_blank');
    }
  };

  const handleBMLink = (link?: string): void => {
    if (expired) {
      handleGenerateNewLink(installation);
    } else {
      window.open(`https://diamanti-software.s3.amazonaws.com/${link}`, '_blank');
    }
  };
  const handleEksGkeLink = (link?: string): void => {
    if (expired) {
      handleGenerateNewLink(installation);
    } else {
      window.open(`https://diamanti-software.s3.amazonaws.com/${link}`, '_blank');
    }
  };

  return (
    <Card className={className} style={{ gridColumn: 'span 2' }}>
      <Card.Header
        title={
          // eslint-disable-next-line no-nested-ternary
          installation.product === ProductType.Spektra
            ? 'License Type'
            : installation.config.cloudType === Provider.Vsphere ||
              installation.config.cloudType === Provider.Baremetal ||
              installation.config.cloudType === Provider.Gke ||
              installation.config.cloudType === Provider.Eks
            ? 'Days remaining to download link'
            : 'Days remaining in trial'
        }
      />
      <Card.Body>
        {expired && installation.config.cloudType === Provider.Vsphere && (
          <CenteredBody>
            <Button
              style={{ width: '100%' }}
              type="alternate"
              text="Generate New Link for dvx-3.4.2-108.ova"
              onClick={handleLink}
            />
          </CenteredBody>
        )}
        {expired && installation.config.cloudType === Provider.Baremetal && (
          <CenteredBody>
            <Button
              style={{ width: '100%' }}
              type="alternate"
              text="Generate New Link for files"
              onClick={(): void => handleBMLink('')}
            />
          </CenteredBody>
        )}
        {expired &&
          (installation.config.cloudType === Provider.Gke ||
            installation.config.cloudType === Provider.Eks) && (
            <CenteredBody>
              <Button
                style={{ width: '100%' }}
                type="alternate"
                text="Generate New Link for files"
                onClick={(): void => handleEksGkeLink('')}
              />
            </CenteredBody>
          )}
        {!expired && installation.config.cloudType === Provider.Vsphere && (
          <Body style={{ bottom: '50px' }}>
            <RadialChartContainer>
              <RadialChart remaining={remaining} series={series} />
            </RadialChartContainer>
            <Button type="alternate" text="Download dvx-3.4.2-108.ova" onClick={handleLink} />
          </Body>
        )}
        {!expired &&
          (installation.config.cloudType === Provider.Eks ||
            installation.config.cloudType === Provider.Gke) && (
            <Body style={{ bottom: '50px' }}>
              <RadialChartContainer>
                <RadialChart remaining={remaining} series={series} />
              </RadialChartContainer>
              <Button type="alternate" text="Download spektra-3.6.0-15.tgz" onClick={handleLink} />
            </Body>
          )}
        {!expired && installation.config.cloudType === Provider.Baremetal && (
          <Body style={{ bottom: '25px' }}>
            <RemainingTextDiv>
              <Typography type="h2">{remaining}</Typography>
            </RemainingTextDiv>
            <ButtonContainer>
              <Button
                style={{ marginTop: '1.0rem', width: '100%' }}
                type="alternate"
                text="Download diamanti_centos8.2_x86_64_8.2.0-1.iso"
                onClick={(): void => handleBMLink('diamanti_centos8.2_x86_64_8.2.0-1.iso')}
              />
              <Button
                style={{ marginTop: '0.5rem', width: '100%' }}
                type="alternate"
                text="Download diamanti-cx-3.4.2-108.x86_64.rpm"
                onClick={(): void => handleBMLink('diamanti-cx-3.4.2-108.x86_64.rpm')}
              />
              <Button
                style={{ marginTop: '0.5rem', width: '100%' }}
                type="alternate"
                text="Download diamanti-cx-docker-core-3.4.2-108.x86_64.rpm"
                onClick={(): void => handleBMLink('diamanti-cx-docker-core-3.4.2-108.x86_64.rpm')}
              />
            </ButtonContainer>
          </Body>
        )}
        {installation.product !== ProductType.Spektra &&
          installation.config.cloudType !== Provider.Vsphere &&
          installation.config.cloudType !== Provider.Baremetal && (
            <Body>
              <RadialChart remaining={remaining} series={series} />
            </Body>
          )}

        {installation.product === ProductType.Spektra && (
          <CenteredBody>
            <Typography type="h2">Perpetual</Typography>
          </CenteredBody>
        )}
      </Card.Body>
    </Card>
  );
};
