import { FC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { CmpProps, ImageIcon, Page } from '@diamanticom/picasa-core';

import { InstallationParams } from '$utils/paths';
import { Provider, useInstallationQuery } from '$gql';

import { InstallationStatusCard } from '$cmp/installation/installation-status-card/InstallationStatusCard';
import { InstallationTrialCard } from '$cmp/installation/installation-trial-card/InstallationTrialCard';
import { InstallationPlatformCard } from '$cmp/installation/installation-platform-card/InstallationPlatformCard';
import { InstallationNodesCard } from '$cmp/installation/installation-nodes-card/InstallationNodesCard';
import { InstallationConfigCard } from '$cmp/installation/installation-config-card/InstallationConfigCard';
import { InstallationResourcesCard } from '$cmp/installation/installation-resources-card/InstallationResourcesCard';
import { SupportButton } from '$cmp/contact/support-button/SupportButton';
import { productImageMap, productNameMap } from '$utils/installation';
import { InstallationConfigChecklistCard } from '$cmp/installation/installation-config-checklist-card/InstallationConfigChecklistCard';
import { InstallationMinVMCard } from '$cmp/installation/installation-min-vm-card/InstallationMinVMCard';
import { InstallationMinBMCard } from '$cmp/installation/installation-min-bm-card/InstallationMinBMCard';

const CardGrid = styled.div`
  flex: 1;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 250px 1fr;
`;

export type InstallationPageProps = CmpProps;

export const InstallationPage: FC<InstallationPageProps> = ({ className }) => {
  const { id } = useParams<InstallationParams>();

  const query = useInstallationQuery({ variables: { id: id as string } });
  const installation = query.data?.installation;

  if (!installation) return null;

  return (
    <Page
      className={className}
      title={productNameMap[installation.product]}
      headerLeft={<ImageIcon image={productImageMap[installation.product]} />}
      headerRight={<SupportButton />}
    >
      <CardGrid>
        <InstallationStatusCard installation={installation} />
        <InstallationTrialCard installation={installation} />
        <InstallationPlatformCard installation={installation} />
        {installation.config.cloudType !== Provider.Vsphere &&
          installation.config.cloudType !== Provider.Baremetal &&
          installation.config.cloudType !== Provider.Eks &&
          installation.config.cloudType !== Provider.Gke && (
            <InstallationNodesCard installation={installation} />
          )}
        {(installation.config.cloudType === Provider.Vsphere ||
          installation.config.cloudType === Provider.Baremetal ||
          installation.config.cloudType === Provider.Eks ||
          installation.config.cloudType === Provider.Gke) && (
          <InstallationConfigChecklistCard
            installation={installation}
            provider={installation.config.cloudType}
          />
        )}
        {installation.config.cloudType !== Provider.Vsphere &&
          installation.config.cloudType !== Provider.Baremetal &&
          installation.config.cloudType !== Provider.Eks &&
          installation.config.cloudType !== Provider.Gke && (
            <InstallationConfigCard installation={installation} />
          )}
        {installation.config.cloudType === Provider.Vsphere && (
          <InstallationMinVMCard installation={installation} />
        )}
        {installation.config.cloudType === Provider.Baremetal && (
          <InstallationMinBMCard installation={installation} />
        )}
        <InstallationResourcesCard installation={installation} />
      </CardGrid>
    </Page>
  );
};
