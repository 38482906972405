export type SalesVerticalOption = { label: string; value: string };
export const SALES_VERTICALS: SalesVerticalOption[] = [
  { label: 'Banking and Financial Services', value: 'Banking and Financial Services' },
  {
    label: 'Computer and Electronic Product Manufacturing',
    value: 'Computer and Electronic Product Manufacturing',
  },
  { label: 'Construction and Real Estate', value: 'Construction and Real Estate' },
  { label: 'Educational Services', value: 'Educational Services' },
  { label: 'Health Care and Social Assistance', value: 'Health Care and Social Assistance' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Media and Entertainment', value: 'Media and Entertainment' },
  { label: 'Mining and Natural Resources', value: 'Mining and Natural Resources' },
  {
    label: 'Professional, Scientific and Technical Services',
    value: 'Professional, Scientific and Technical Services',
  },
  { label: 'Public Administration', value: 'Public Administration' },
  { label: 'Retail Trade', value: 'Retail Trade' },
  { label: 'Service industry', value: 'Service industry' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Transportation and Warehousing', value: 'Transportation and Warehousing' },
  { label: 'Travel and Tourism', value: 'Travel and Tourism' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Wholesale Trade', value: 'Wholesale Trade' },
];
